import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { fiveMinutes } from 'queries/constants';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListForAllWorkspaces, ListForWorkspace, type DataStream } from 'services/DataStreamDefinitionService';
import { List } from 'services/DataStreamService';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';

type ListReturnType = Awaited<ReturnType<typeof List>>;

/**
 * useQuery returning all datastreams
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all datastreams in a tenant
 */
export const useDataStreamDefinitions = <T = ListReturnType>(
    options?: Omit<UseQueryOptions<ListReturnType, unknown, T, string[]>, 'cacheTime' | 'staleTime'>
) => {
    return useQuery(dataStreamDefinitionQueryKeys.all, List, {
        ...options,
        // Full list of data stream definitions is very large and expensive to load.
        // It also changes quite rarely. So we should cache indefinitely unless/until
        // we know if has changed and needs to be invalidated.
        cacheTime: Number.POSITIVE_INFINITY,
        staleTime: Number.POSITIVE_INFINITY
    });
};

/**
 * Only the data stream definitions for data streams that can be used in the specified workspace,
 * i.e. are relevant for plugins that are linked to the workspace.
 *
 * If the workspace is not supplied, the data stream workspace context is used (this defaults to the current
 * workspace if there is no declared override).
 */
export const useDataStreamDefinitionsForWorkspace = <T = DataStream[]>({
    workspace,
    queryOptions
}: {
    workspace?: string;
    queryOptions?: UseQueryOptions<DataStream[], unknown, T>;
} = {}) => {
    const { workspace: dataStreamWorkspace } = useDataStreamWorkspaceContext();
    const targetWorkspace = workspace ?? dataStreamWorkspace;

    return useQuery<DataStream[], unknown, T>(
        dataStreamDefinitionQueryKeys.forWorkspace(targetWorkspace, { includeTemplates: true }),
        async () => {
            const allDataStreams =
                targetWorkspace !== NO_ACTIVE_WORKSPACE
                    ? await ListForWorkspace(targetWorkspace, true)
                    : await ListForAllWorkspaces(true);
            return Object.values(allDataStreams).flat();
        },
        {
            cacheTime: fiveMinutes * 2,
            staleTime: fiveMinutes,
            ...queryOptions
        }
    );
};
