import { ShapeName, date, type FormattedStreamValue } from '@squaredup/data-streams';
import { ScaleLinear } from 'd3-scale';
import { tickCount } from 'dashboard-engine/util/tickFormatter';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { BarChartBottomAxisTicks } from './BarChartBottomAxisTicks';
import { BarChartBars, barGraphDefaultBottomMargin, maxTickCharacterLength } from './Config';
import { categoriesToTicks } from './dataUtils';

interface BarCustomBottomAxisProps {
    width: number;
    height: number;
    bars: BarChartBars[];
    isHorizontal: boolean;
    isGrouping: boolean;
    xAxisColumnShape: ShapeName;
    xScale: ScaleLinear<number, number>;
    yScale: ScaleLinear<number, number>;
    categories: FormattedStreamValue[];
    bottomMargin: number;
    setBottomMargin: Dispatch<SetStateAction<number>>;
    setBorderSize: Dispatch<SetStateAction<number>>;
    xAxisNumericTickFormatter: (v: number) => number;
    hasSeriesColumn: boolean;
    marginLeft: number;
}

/**
 * Estimates the pixel size for the given tick label
 * @param value String
 * @returns Pixel size
 */
export const estimateTickSize = (value: string | number | null) =>
    Math.min(maxTickCharacterLength, `${value}`.length) * 5.5;

const calculateMargin = (rotateTicks: boolean, labels: (string | number | null)[]) => {
    // If labels aren't rotated, just use a default margin
    if (!rotateTicks) {
        return barGraphDefaultBottomMargin;
    }

    // Get the longest label, and estimate it's pixel size
    const longestLabelSize = Math.max(...labels.map(estimateTickSize));

    // Add some additional space so it's not right against the label
    const margin = longestLabelSize + 10;

    // Apply a minimum margin in the case of small labels
    return Math.max(40, margin);
};

const getCategoryAsString = (c: FormattedStreamValue) => {
    return typeof c.value === 'string' ? c.value : c.formatted;
};

/**
 * @returns a custom bottom axis for all the bar chart variants
 */
export const BarChartCustomBottomAxis = ({
    bars,
    xScale,
    yScale,
    height,
    categories,
    isHorizontal,
    isGrouping,
    xAxisColumnShape,
    width: graphWidth,
    height: graphHeight,
    marginLeft,
    bottomMargin,
    setBottomMargin,
    setBorderSize,
    xAxisNumericTickFormatter,
    hasSeriesColumn
}: BarCustomBottomAxisProps) => {
    // We need to flip which scale collects the ticks depending on graph orientation
    const { ticks } = isHorizontal ? xScale : yScale;

    let tickValues = ticks(tickCount(graphWidth));

    if (isHorizontal) {
        // Ensure that bottom axis numerics are formatted with their specific unit
        tickValues = tickValues.map((t) => xAxisNumericTickFormatter(t));
    }

    const categoryTicks =
        xAxisColumnShape === date.name
            ? categoriesToTicks(categories.map(getCategoryAsString))
            : categories.map(getCategoryAsString);

    // Get bar dimensions
    const barWidth = getBarWidth(bars, isGrouping);
    const barHeight = getBarHeight(bars, isGrouping);

    // Get the labels used on this axis
    const labels = isHorizontal ? tickValues : categoryTicks;

    // Estimate space needed for these labels
    const tickSpace = labels.map(estimateTickSize).reduce((sum, a) => sum + a, 0) + tickValues.length * 25;

    // If we don't think there's enough space, we'll rotate the ticks
    const rotateTicks = tickSpace >= graphWidth - marginLeft;

    // Calculate dynamic margin based on these labels and rotation
    const newBottomMargin = calculateMargin(rotateTicks, labels);

    // Ideally we wouldn't do this but this is the only place we have access to
    // the current state of the bars via Nivo; useEffect ensures render safety as a result
    useEffect(() => {
        if (newBottomMargin !== bottomMargin) {
            setBottomMargin(newBottomMargin);
        }
    }, [newBottomMargin, bottomMargin, setBottomMargin]);

    const isStacked = hasSeriesColumn && !isGrouping;
    const isSingleStacked =
        categoryTicks.length >= bars.filter((x) => (isHorizontal ? x.width !== 0 : x.height !== 0)).length;

    const totalBars = isGrouping ? bars.length : categoryTicks.length;
    const borderWidth = isHorizontal
        ? (graphHeight - barHeight * totalBars) / (totalBars + 1)
        : (graphWidth - barWidth * totalBars) / (totalBars + 1);

    const borderSize = isStacked && !isSingleStacked ? 0 : borderWidth;
    setBorderSize(borderSize);

    const tickPositions = calculateTickCategoryPosition({
        tickValues,
        graphWidth,
        bars,
        barWidth,
        isHorizontal,
        categories: categoryTicks
    });

    return (
        // We add 26 to provide enough padding away from the graph
        // TODO: this is shared with line graph/other vizs, create an reusuable export
        <g transform={`translate(0, ${height + 26})`}>
            {tickPositions?.map((t) => (
                <BarChartBottomAxisTicks tick={t.tick} key={t.key} xPosition={t.xPosition} rotateTicks={rotateTicks} />
            ))}
        </g>
    );
};

/**
 * Determine width of each 'block' of bars
 * @param bars All bars
 * @param isGrouping If chart is grouped
 * @returns
 */
const getBarWidth = (bars: BarChartBars[], isGrouping: boolean) => {
    if (isGrouping) {
        const barWidths = bars.reduce((acc: Record<string, number>, bar) => {
            const key = bar.data.indexValue;
            if (!acc[key]) {
                acc[key] = bar.width;
            } else {
                acc[key] += bar.width;
            }
            return acc;
        }, {});
        return Math.max(...Object.values(barWidths));
    } else {
        return bars[0]?.width; // assume all bars are same width when not grouping
    }
};

const getBarHeight = (bars: BarChartBars[], isGrouping: boolean) => {
    if (isGrouping) {
        const barHeights = bars.reduce((acc: Record<string, number>, bar) => {
            const key = bar.data.indexValue;
            if (!acc[key]) {
                acc[key] = bar.height;
            } else {
                acc[key] += bar.height;
            }
            return acc;
        }, {});
        return Math.max(...Object.values(barHeights));
    } else {
        return bars[0]?.height; // assume all bars are same width when not grouping
    }
};

export interface BarChartTickElements {
    tickValues: number[];
    graphWidth: number;
    barWidth: number;
    isHorizontal: boolean;
    categories?: (string | null)[];
    bars: BarChartBars[];
}

/**
 * @returns an array of objects containing a tick/category and its calculated xPosition
 */
export const calculateTickCategoryPosition = ({
    tickValues,
    graphWidth,
    bars,
    barWidth,
    isHorizontal,
    categories
}: BarChartTickElements) => {
    if (isHorizontal) {
        // When horizontal place ticks at even intervals across the graph
        const tickInterval = graphWidth / (tickValues.length - 1);
        return tickValues.map((tick, idx) => {
            // We subtract 0.4*idx to account for the width of the tick itself
            const xPosition = idx * tickInterval - idx * 0.4;
            return { tick, xPosition, key: `${tick}_${xPosition}_${idx}` };
        });
    } else {
        const xBarStartPositions = bars.map((b) => b.x);
        return categories?.reduce(
            (positions, category, i) => {
                let xPosition;
                if (categories.length === 1) {
                    // If there's only one category place it centrally
                    xPosition = graphWidth / 2;
                } else {
                    xPosition = xBarStartPositions[i] + barWidth / 2;
                }

                // Skip ticks which are too close to the previous tick
                if (positions.length > 0 && xPosition - positions[positions.length - 1].xPosition < 20) {
                    return positions;
                }

                positions.push({ tick: category, xPosition, key: `${category}_${xPosition}_${i}` });

                return positions;
            },
            [] as { tick: string | null; xPosition: number; key: string }[]
        );
    }
};
