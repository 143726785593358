import { isFeatureEnabled, type Tier } from '@squaredup/tenants';
import type { Workspace } from 'services/WorkspaceService';

interface UseDataSourceNavigationProps {
    tier?: Tier;
    workspaces?: Workspace[];
    datasourceConfigCount?: number;
}

export const useDataSourceNavigation = ({ tier, workspaces, datasourceConfigCount }: UseDataSourceNavigationProps) => {
    const handleAddDataSource = () => {
        // Early return if critical data is missing
        if (!tier || typeof datasourceConfigCount !== 'number') {
            return;
        }

        // Open plugin modal if no workspaces or workspaces is undefined
        if (!workspaces || workspaces.length === 0) {
            return { shouldOpenPluginModal: true };
        }

        const isWorkspacesFeatureEnabled = isFeatureEnabled(tier, 'workspaces');
        const isAccessControlFeatureEnabled = isFeatureEnabled(tier, 'accessControl');

        // If workspaces feature is enabled, open plugin modal
        if (isWorkspacesFeatureEnabled || isAccessControlFeatureEnabled) {
            return { shouldOpenPluginModal: true };
        }

        // Redirect only if there's exactly one workspace
        if (workspaces.length === 1) {
            const currentWorkspaceId = workspaces[0]?.id;
            if (currentWorkspaceId) {
                return {
                    shouldRedirect: true,
                    redirectPath: `/datasources/${currentWorkspaceId}`
                };
            }
        }

        // Default to opening plugin modal for multiple workspaces
        return { shouldOpenPluginModal: true };
    };

    return { handleAddDataSource };
};
