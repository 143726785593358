import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import { QueryClient, QueryKey, useQuery, useQueryClient } from 'react-query';
import { Get, ListForWorkspace } from 'services/DataStreamDefinitionService';

/**
 * Read a querys cached data.
 * @returns The cached data.
 *
 * If there is no cached data but the query is fetching (has an ongoing request), get a promise of the data.
 *
 * If there is no cached data and no ongoing query, returns undefined.
 */
const getQueryDataOrFetch = async <T>(queryClient: QueryClient, queryKey: QueryKey): Promise<T | undefined> => {
    const queryData = queryClient.getQueryData(queryKey);

    if (queryData == null && queryClient.isFetching(queryKey)) {
        // Only fetch if a fetch is ongoing so we join the promise for the
        // existing request rather than kicking off a new request
        return queryClient.getQueryCache().find<T>(queryKey)?.fetch();
    }

    return undefined;
};

/**
 * @param dataStreamId string
 * @returns useQuery props for getting the data stream definition
 */
export const useDataStreamDefinition = (dataStreamId?: string) => {
    const queryClient = useQueryClient();
    const { workspace: workspaceId } = useDataStreamWorkspaceContext();

    return useQuery(
        dataStreamDefinitionQueryKeys.byId(dataStreamId!),
        async () => {
            // Before we make a request for this specific data stream, check whether we
            // already have it in the cache of all data streams for this workspace
            const queryData = await getQueryDataOrFetch<ReturnType<typeof ListForWorkspace>>(
                queryClient,
                dataStreamDefinitionQueryKeys.forWorkspace(workspaceId, { includeTemplates: true })
            );
            const existingDefinition = queryData?.find((d) => d.id === dataStreamId);

            if (existingDefinition != null) {
                return existingDefinition;
            }

            return Get(dataStreamId!);
        },
        {
            enabled: Boolean(dataStreamId),
            cacheTime: Number.POSITIVE_INFINITY,
            staleTime: Number.POSITIVE_INFINITY
        }
    );
};
