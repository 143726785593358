const TENANT = 'tenant';
const TENANT_USERS_QUERY_KEY = 'tenant-users';
const APPLICATIONSUBDOMAIN = 'applicationSubdomain';
const SUBSCRIPTION = 'tenantSubscription';

export const tenantQueryKeys = {
    tenant: [TENANT],
    tenantUsers: [TENANT, TENANT_USERS_QUERY_KEY],
    applicationSubdomain: [APPLICATIONSUBDOMAIN],
    subscription: [TENANT, SUBSCRIPTION],
    tenantProperties: [TENANT, 'properties']
};
