import { Shape, getShape, getValueShapeOf, toShapeName, getColumnShape, type ResolvedShapeConfig } from '@squaredup/data-streams';
import { metadataEditorShapesByName } from './MetadataEditorShapes';
import { DataStreamMetadataState, MetadataOverrides, isNonPatternColumn } from './MetadataEditorState';

export interface DataStreamMetadataForm {
    [columnName: string]: {
        shape?: string | null;
        displayName?: string;
        formatExpression?: string;
        shapeConfig?: Record<string, unknown>;
    };
}

/**
 * Convert a column name to the corresponding field name on the metadata form
 * @remarks
 * toFormData is a lot simpler when we can assume that all field names are strings
 * that won't trigger react-hook-form to look for nested properties - otherwise we
 * have to build an object in the shape that it expects which is annoying.
 */
export const toFormFieldName = (columnName: string) =>
    columnName.replaceAll('.', '->').replaceAll(/\[(.*?)\]/giu, ':$1:');

/**
 * Convert a metadata form field name to the corresponding column name in the metadata editor state
 */
export const fromFormFieldName = (columnName: string) =>
    columnName.replaceAll('->', '.').replaceAll(/:(.*?):/giu, '[$1]');

export const toFormData = (state: DataStreamMetadataState): DataStreamMetadataForm => {
    return Object.fromEntries(
        [...state.columnMap.values()].filter(isNonPatternColumn).map((c): [string, DataStreamMetadataForm[string]] => {
            const shape = getColumnShape(c.metadataOverrides?.shape) ?? c.shape;
            const valueShape = getValueShapeOf(shape.name);

            const defaultFormShapeConfigValues = {
                ...metadataEditorShapesByName.get(shape.name)?.defaultFormValues,
                ...(valueShape && metadataEditorShapesByName.get(valueShape.name)?.defaultFormValues)
            };

            const merge =
                metadataEditorShapesByName.get(shape.name)?.mergeFormValues ??
                (valueShape && metadataEditorShapesByName.get(valueShape.name)?.mergeFormValues) ??
                ((...configs) => Object.assign({}, ...configs));

            return [
                toFormFieldName(c.columnName),
                {
                    displayName: c.metadata?.displayName,
                    formatExpression: c.metadata?.formatExpression,
                    ...c.metadataOverrides,
                    shape: shape.name,
                    shapeConfig: merge(defaultFormShapeConfigValues, shape.config)
                }
            ];
        })
    ) as DataStreamMetadataForm;
};

const getParsedShapeConfig = (
    shape: Shape,
    shapeConfig: Record<string, unknown> | undefined
): Record<string, unknown> | undefined => {
    const parseResult = shape.parseShapeConfig(shapeConfig as ResolvedShapeConfig);

    const hasShapeConfigOverrideValues =
        parseResult.succeeded && Object.values(parseResult.value).filter((v) => v != null).length > 0;

    if (hasShapeConfigOverrideValues) {
        return parseResult.value;
    }

    return undefined;
};

export const getShapeOverride = (
    overrides: DataStreamMetadataForm[string]
): { shape: MetadataOverrides['shape'] } | Record<string, never> => {
    const hasShapeOverride = 'shape' in overrides;
    const { shape: shapeName, shapeConfig } = overrides;

    if (!shapeName) {
        return hasShapeOverride ? { shape: undefined } : {};
    }

    const shape = getShape(toShapeName(shapeName));

    if (shape == null) {
        return {};
    }

    const valueShape = getValueShapeOf(shape);

    const shapePrefixRemoved = shapeName.replace(/^shape_/iu, '');

    const shapeConfigOverride = {
        ...(valueShape && getParsedShapeConfig(valueShape, shapeConfig)),
        ...getParsedShapeConfig(shape, shapeConfig)
    };

    if (Object.values(shapeConfigOverride).filter((v) => v != null).length > 0) {
        return { shape: [shapePrefixRemoved, shapeConfigOverride] };
    }

    return { shape: shapePrefixRemoved };
};

/**
 * Convert form data to a form that can be saved to the tile config.
 */
export const toMetadataOverrides = (formData: DataStreamMetadataForm): [string, MetadataOverrides][] => {
    const overrides = Object.entries(formData).map(([formFieldName, value]): [string, MetadataOverrides] => {
        const { shape, shapeConfig, ...rest } = value;

        const shapeOverride = getShapeOverride(value);

        return [formFieldName, { ...rest, ...shapeOverride }];
    });

    return overrides;
};
