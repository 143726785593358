import graphNodes from '../datasources/graph/graphNodes/graphNodes';
import { SimpleTile } from 'dashboard-engine/basetiles/types';
import Text from '../visualisations/Text/Text';

const graphNodesText: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: graphNodes,
        config: {
            type: 'db'
        }
    },
    visualisation: {
        type: Text
    }
};

export default graphNodesText;
