import { Button } from '@/components/Button';
import Text from '@/components/Text';
import { faPencil, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ShapeName,
    StreamDataColumn,
    StreamPrimitive,
    date,
    findConverter,
    getColumnShape,
    getShape,
    getValueShapeOf,
    isNone
} from '@squaredup/data-streams';
import { isDefined } from '@squaredup/utilities';
import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';
import { BehindFlag } from 'components/BehindFlag';
import { Chevron } from 'components/Chevron';
import { TruncatedText } from 'components/TruncatedText';
import Field from 'components/forms/field/Field';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import Tooltip from 'components/tooltip/Tooltip';
import { renderCellValue } from 'dashboard-engine/visualisations/DataStreamTable/RenderCell';
import stringify from 'fast-json-stable-stringify';
import { useFlag } from 'lib/useFlag';
import { orderBy, toString, upperFirst } from 'lodash';
import { ControlledApplicationTable, useApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import { Dispatch, FC, ReactNode, useMemo, useState } from 'react';
import EasyEdit from 'react-easy-edit';
import { Controller } from 'react-hook-form';
import { GroupBase } from 'react-select';
import EasyEditOverflowWrapper from 'ui/tile/EasyEditOverflowWrapper';
import { FormatExpressionEditor } from './FormatExpressionEditor';
import { ComparisonForm, MetadataEditorComparisonModal } from './MetadataEditorComparisonModal';
import { toFormFieldName } from './MetadataEditorForm';
import { DataStreamMetadataAction, columnCanBeRemoved } from './MetadataEditorReducer';
import { hasShapeConfigFields, metadataEditorShapes } from './MetadataEditorShapes';
import {
    CloneStateColumn,
    ComparisonStateColumn,
    DataStreamMetadataState,
    DataStreamMetadataStateColumn,
    StateColumn,
    isNonPatternColumn
} from './MetadataEditorState';
import { findClones, findComparisons } from './MetadataEditorTileState';
import { ShapeConfigField } from './ShapeConfigField';

export type MetadataTableRow = {
    name: string;
    hasMetadata: boolean;
    isCloneable: boolean;
    isComparable: boolean;
    isBeingCloned: boolean;
    isComparison: boolean;
    failedConversion: boolean;
    isOverridden: boolean;
    displayName: {
        value: string;
        field: ReactNode;
    };
    sourceColumn?: {
        name: string;
        displayName: string;
    };
    shape: {
        value: string;
        field: ReactNode;
    };
    sampleValue: {
        value: StreamPrimitive;
        field: ReactNode;
    };
    sampleFormattedValue: {
        value: string;
        field: ReactNode;
    };
};

const getShapeOptionGroups = (shapeName?: ShapeName): GroupBase<AutocompleteOption>[] => {
    const valueShapeName = shapeName ? getValueShapeOf(shapeName)?.name : shapeName;

    const optionGroups: GroupBase<AutocompleteOption>[] = metadataEditorShapes.map((group) => ({
        label: group.groupName,
        options: group.shapes
            .filter((s) => {
                if (valueShapeName == null) {
                    return false;
                }

                const targetValueShapeName = s.isPrimitive || !s.isTotal ? s.name : s.valueShape.name;

                const requiresConversion = valueShapeName !== targetValueShapeName;
                const hasConverter = findConverter(valueShapeName, targetValueShapeName) != null;

                return !requiresConversion || hasConverter;
            })
            .map((s) => ({
                label: upperFirst(s.displayName),
                value: s.name
            }))
    }));

    const selectedShapeIsValid =
        shapeName == null || optionGroups.some((g) => g.options.some((o) => o.value === shapeName));

    if (!selectedShapeIsValid) {
        const shape = getShape(shapeName);

        if (shape != null) {
            // Selected shape is not one you can normally pick in the metadata editor
            // (e.g. a legacy shape like number.0), so add a disabled option to avoid
            // the internal shape name being displayed to the user
            optionGroups.push({
                label: 'Unsupported',
                options: [
                    { isDisabled: true, label: shape.displayName, labelText: shape.displayName, value: shape.name }
                ]
            });
        }
    }

    return optionGroups;
};

const ShapeDropdown: FC<{
    columnName: string;
    sourceShapeName?: ShapeName;
    isOverridden: boolean;
    disabled?: boolean;
}> = ({ columnName, sourceShapeName, isOverridden, disabled }) => {
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Field.Input
                type='autocomplete'
                isDisabled={disabled}
                name={`${toFormFieldName(columnName)}.shape`}
                options={getShapeOptionGroups(sourceShapeName)}
                isMulti={false}
                selectOptionsAs='valueString'
                menuPosition='fixed'
                menuShouldBlockScroll={true}
                isClearable={isOverridden}
            />
        </div>
    );
};

const EditableDisplayName: FC<{ columnName: string; isOverridden: boolean }> = ({ columnName, isOverridden }) => {
    return (
        <Text.Body>
            <Controller
                name={`${toFormFieldName(columnName)}.displayName`}
                render={({ field }) => {
                    return (
                        <div className='flex items-center'>
                            <div className='inline-block' onClick={(e) => e.stopPropagation()}>
                                <EasyEdit
                                    type='text'
                                    value={field.value ?? ''}
                                    placeholder={''}
                                    onSave={(v: string) => field.onChange(v.trim())}
                                    saveOnBlur={true}
                                    displayComponent={
                                        <div className='grid items-center justify-start grid-flow-col grid-cols-[auto_auto_1rem] gap-x-1 group/displayname'>
                                            <EasyEditOverflowWrapper
                                                value={field.value ?? ''}
                                                placeholder='Column display name'
                                                className='pr-1'
                                                tooltipProps={{ className: '[display:inherit]' }}
                                            />
                                            {isOverridden && (
                                                <Text.SmallBody className='not-italic text-textSecondary'>
                                                    (edited)
                                                </Text.SmallBody>
                                            )}
                                            <Tooltip title='Rename column'>
                                                <FontAwesomeIcon
                                                    className='invisible group-hover/displayname:visible'
                                                    icon={faPencil}
                                                />
                                            </Tooltip>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    );
                }}
            ></Controller>
        </Text.Body>
    );
};

export const getColumnsInDisplayOrder = (
    state: DataStreamMetadataState,
    rootColumns?: (StateColumn | CloneStateColumn | ComparisonStateColumn)[]
): DataStreamMetadataStateColumn[] => {
    rootColumns ??= [...state.columnMap.values()].filter(
        (c): c is StateColumn => !c.isPattern && !c.isClone && !c.isComparison
    );

    /**
     * If we don't have defined metadata, the order of the overrides will change the
     * displayIndex of the columns, which we don't want.
     *
     * By not sorting we keep the existing order which was set when the state was created.
     * This avoids the UI jumping around unexpectedly.
     */
    const order = state.hasDefinedMetadata
        ? (c: StateColumn | CloneStateColumn | ComparisonStateColumn) =>
              c.metadata?.displayIndex ?? Number.MAX_SAFE_INTEGER
        : () => Number.MAX_SAFE_INTEGER;

    const sortedColumns = orderBy(rootColumns, order);

    return sortedColumns.flatMap((c) => [
        c,
        ...getColumnsInDisplayOrder(state, [
            ...findClones(state, c.columnName),
            ...findComparisons(state, c.columnName)
        ])
    ]);
};

export const FormatExpressionField: FC<{ columnName: string; columns: StreamDataColumn[] }> = ({
    columnName,
    columns
}) => {
    return (
        <div className='max-w-4xl'>
            <Field.Label label='Format Expression'>
                <Controller
                    name={`${toFormFieldName(columnName)}.formatExpression`}
                    render={({ field: { value, onChange } }) => (
                        <FormatExpressionEditor
                            key={`${toFormFieldName(columnName)}.formatExpression_field`}
                            name={`${toFormFieldName(columnName)}.formatExpression_field`}
                            value={value || ''}
                            onChange={onChange}
                            columns={columns}
                        />
                    )}
                />
            </Field.Label>
        </div>
    );
};

/**
 * Override how an object is serialised to JSON to avoid circular references
 */
const makeSerialisable = (object: { value: string; field: ReactNode }): { value: string; field: ReactNode } => {
    const serialisable = { ...object, toJSON: () => object.value };

    return serialisable;
};

const getCommonRowProps = (
    columnName: string,
    column: StateColumn | CloneStateColumn | ComparisonStateColumn
): Omit<MetadataTableRow, 'displayName'> => {
    const shapeName: ShapeName | undefined =
        getColumnShape(column.metadataOverrides?.shape)?.name ?? column.metadata?.shapeName;

    const FormattedValue = shapeName ? (
        <div className='w-min max-w-72' data-testid='formattedValue'>
            {renderCellValue(shapeName, column.sampleValue, { align: 'left' })}
        </div>
    ) : (
        <></>
    );

    const disableShapeDropdown =
        column.isComparison &&
        column.metadataOverrides &&
        'comparisonType' in column.metadataOverrides &&
        column.metadataOverrides.comparisonType === 'percentage';

    return {
        name: columnName,
        hasMetadata: column.metadata != null,
        isCloneable: column.isCloneable,
        isComparable: column.isComparable,
        isBeingCloned: column.isClone && column.isBeingCloned,
        isComparison: column.isComparison,
        failedConversion: isNone(column.sampleValue.value),
        isOverridden:
            // We don't want to show the 'edited' indicator if the only override is an expand (clone)
            Object.keys(column.metadataOverrides ?? {}).filter((k) => k !== 'expand' && k !== 'name').length > 0,
        shape: makeSerialisable({
            value: shapeName ?? '',
            field: (
                <ShapeDropdown
                    columnName={columnName}
                    sourceShapeName={shapeName}
                    isOverridden={Boolean(column.metadataOverrides?.shape)}
                    disabled={disableShapeDropdown}
                />
            )
        }),
        sampleValue: makeSerialisable({
            value: toString(column.sampleValue.raw),
            field: <TruncatedText title={toString(column.sampleValue.raw)}></TruncatedText>
        }),
        sampleFormattedValue: makeSerialisable({
            value: column.sampleValue.formatted,
            field: FormattedValue
        })
    };
};

export const toTableData = (state: DataStreamMetadataState): MetadataTableRow[] => {
    return getColumnsInDisplayOrder(state)
        .filter(isNonPatternColumn)
        .filter((c) => !c.isClone || c.isEditable)
        .map((column): MetadataTableRow => {
            const columnDisplayName = column.metadataOverrides?.displayName ?? column.metadata?.displayName;
            const commonRowProps = getCommonRowProps(column.columnName, column);

            if (column.isClone) {
                const cloneSource = state.columnMap.get(column.sourceColumnName);
                const cloneSourceColumn = cloneSource?.isPattern ? undefined : cloneSource;

                const cloneSourceDisplayName =
                    cloneSourceColumn?.metadataOverrides?.displayName ??
                    cloneSourceColumn?.metadata?.displayName ??
                    column.sourceColumnName;

                if (column.isBeingCloned) {
                    return {
                        name: column.columnName,
                        hasMetadata: false,
                        isCloneable: false,
                        isComparable: false,
                        isBeingCloned: column.isClone && column.isBeingCloned,
                        isComparison: false,
                        failedConversion: false,
                        isOverridden: false,
                        displayName: makeSerialisable({
                            value: `Copying ${cloneSourceDisplayName}...`,
                            field: (
                                <TruncatedText title={`Copying ${cloneSourceDisplayName}...`}>
                                    Copying {cloneSourceDisplayName}...
                                </TruncatedText>
                            )
                        }),
                        shape: { value: '', field: '' },
                        sampleValue: { value: '', field: '' },
                        sampleFormattedValue: { value: '', field: '' }
                    };
                }

                const defaultDisplayName = `Copy of ${cloneSourceDisplayName}`;
                return {
                    ...commonRowProps,
                    displayName: makeSerialisable({
                        value: columnDisplayName ?? column.columnName,
                        field: (
                            <>
                                {column.metadataOverrides?.displayName !== defaultDisplayName && (
                                    <TruncatedText title={defaultDisplayName}>
                                        <Text.SmallBody className='text-secondary'>{defaultDisplayName}</Text.SmallBody>
                                    </TruncatedText>
                                )}
                                <EditableDisplayName
                                    columnName={column.columnName}
                                    isOverridden={commonRowProps.isOverridden}
                                />
                            </>
                        )
                    })
                };
            }

            if (column.isComparison) {
                const comparisonSource = state.columnMap.get(column.sourceColumnName);
                const comparisonSourceColumn = comparisonSource?.isPattern ? undefined : comparisonSource;

                const comparisonSourceDisplayName =
                    comparisonSourceColumn?.metadataOverrides?.displayName ??
                    comparisonSourceColumn?.metadata?.displayName ??
                    column.sourceColumnName;

                const defaultDisplayName = `${comparisonSourceDisplayName} Comparison`;
                return {
                    ...commonRowProps,
                    sourceColumn: {
                        name: column.sourceColumnName,
                        displayName: comparisonSourceDisplayName
                    },
                    displayName: makeSerialisable({
                        value: columnDisplayName ?? column.columnName,
                        field: (
                            <>
                                {column.metadataOverrides?.displayName !== defaultDisplayName && (
                                    <TruncatedText title={defaultDisplayName}>
                                        <Text.SmallBody className='text-secondary'>{defaultDisplayName}</Text.SmallBody>
                                    </TruncatedText>
                                )}
                                <EditableDisplayName
                                    columnName={column.columnName}
                                    isOverridden={commonRowProps.isOverridden}
                                />
                            </>
                        )
                    })
                };
            }

            return {
                ...commonRowProps,
                displayName: makeSerialisable({
                    value: columnDisplayName ?? column.columnName,
                    field: (
                        <EditableDisplayName
                            columnName={column.columnName}
                            isOverridden={commonRowProps.isOverridden}
                        />
                    )
                })
            };
        });
};

const columnHelper = createColumnHelper<MetadataTableRow>();
const tableColumns: ColumnDef<MetadataTableRow, string>[] = [
    columnHelper.display({
        id: 'rowExpandToggle',
        cell: ({ row }) => {
            if (!row.getCanExpand()) {
                return <></>;
            }

            return (
                <div className='flex flex-row justify-between'>
                    <Button
                        variant='tertiary'
                        onClick={(e) => {
                            e.stopPropagation();
                            row.getToggleExpandedHandler()();
                        }}
                        className='flex cursor-pointer'
                    >
                        <Chevron direction={row.getIsExpanded() ? 'up' : 'down'} className='flex' />
                    </Button>
                </div>
            );
        },
        size: 1, // in % of table width
        minSize: 2, // in rem
        maxSize: 2,
        meta: { className: 'pr-0' }
    }),
    columnHelper.accessor((row) => row.displayName.value, {
        id: 'displayName',
        header: 'Name',
        cell: ({ row }) => row.original.displayName.field,
        size: 32
    }),
    columnHelper.accessor((row) => row.shape.value, {
        header: 'Type',
        id: 'shape',
        cell: ({ row }) => {
            return row.original.shape.field;
        },
        size: 22
    }),
    columnHelper.accessor((row) => row.sampleValue.value, {
        header: 'Value',
        id: 'sampleValue',
        cell: ({ row }) => {
            return row.original.sampleValue.field;
        },
        size: 22,
        maxSize: 18
    }),
    columnHelper.accessor((row) => row.sampleFormattedValue.value, {
        header: 'Formatted',
        id: 'sampleFormattedValue',
        cell: ({ row }) => {
            if (row.original.failedConversion) {
                const shape = getShape(row.original.shape.value as ShapeName);
                const shapeMessage = shape != null ? ` to ${shape.displayName}` : '';

                const actionMessage =
                    shape === date
                        ? '\n\nSet Input Format to match the raw values or choose a different shape.'
                        : '\n\nTry a different shape.';
                return (
                    <div className='flex items-center gap-4'>
                        <Tooltip title={`Failed to convert${shapeMessage}.${actionMessage}`}>
                            <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                className='cursor-pointer text-statusWarningPrimary'
                            />
                        </Tooltip>
                        {row.original.sampleFormattedValue.field}
                    </div>
                );
            }
            return row.original.sampleFormattedValue.field;
        },
        size: 22,
        maxSize: 18
    })
];

export const MetadataEditorTable: FC<{
    state: DataStreamMetadataState;
    dispatch: Dispatch<DataStreamMetadataAction>;
}> = ({ state, dispatch }) => {
    const isFormatExpressionUiEnabled = useFlag('metadataEditingFormatExpressionsUi');
    const [existingComparison, setExistingComparison] = useState<ComparisonForm | undefined>();
    const [selectedComparisonColumn, setSelectedComparisonColumn] = useState<string | undefined>();

    const actions = useMemo(
        (): Action[] => {
            return [
                {
                    icon: 'pencil',
                    visible: (row: MetadataTableRow) => row.isComparison,
                    dataTestId: 'editComparisonButton',
                    tooltip: 'Edit comparison',
                    action: (row: MetadataTableRow) => {
                        const comparisonColumn = state.columnMap.get(row.name) as ComparisonStateColumn;
                        if (
                            !(
                                comparisonColumn.metadataOverrides &&
                                'comparisonType' in comparisonColumn.metadataOverrides
                            )
                        ) {
                            return;
                        }
                        const metadata = comparisonColumn.metadataOverrides;
                        setExistingComparison({
                            comparisonName: row.name,
                            comparisonType: metadata.comparisonType ?? 'absolute',
                            sourceColumn: comparisonColumn.sourceColumnName,
                            compareTo: metadata.compareTo ?? '',
                            shape: comparisonColumn.shape
                        });
                    },
                    loading: () => {
                        return false;
                    }
                },
                {
                    icon: 'arrow-trend-up',
                    visible: (row: MetadataTableRow) => row.isComparable,
                    dataTestId: 'addComparisonButton',
                    tooltip: 'Add comparison',
                    action: (row: MetadataTableRow) => {
                        setSelectedComparisonColumn(row.name);
                    },
                    loading: () => {
                        return false;
                    }
                },
                {
                    icon: 'clone',
                    visible: (row: MetadataTableRow) => row.isCloneable,
                    tooltip: 'Clone column',
                    action: (row: MetadataTableRow) => {
                        return dispatch({ type: 'cloneColumn', sourceColumnName: row.name });
                    },
                    loading: (row: MetadataTableRow) => {
                        const foundColumn = state.columnMap.get(row.name);

                        if (!foundColumn || foundColumn.isPattern) {
                            return false;
                        }

                        const isBeingCloned = findClones(state, foundColumn.columnName).some((c) => c.isBeingCloned);

                        return isBeingCloned;
                    }
                },
                {
                    icon: 'trash',
                    visible: ({ name }: MetadataTableRow) => {
                        return columnCanBeRemoved(state, name);
                    },
                    tooltip: 'Delete column',
                    action: (column: MetadataTableRow) => {
                        dispatch({ type: 'removeColumn', columnName: column.name });
                    }
                }
            ];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, stringify(state)]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tableData = useMemo(() => toTableData(state), [stringify(state)]);

    const nonPatternColumns = [...state.columnMap.values()].filter(isNonPatternColumn);
    const columns = nonPatternColumns.map((c) => c.metadata).filter(isDefined);

    const getRowCanExpand = (row: Row<MetadataTableRow>) =>
        row.original.hasMetadata && (isFormatExpressionUiEnabled || hasShapeConfigFields(row.original.shape.value));

    const renderExpandedRow = ({ row }: { row: Row<MetadataTableRow> }) => {
        /**
         * If someone changes the shape so we no longer have shape config fields while the
         * row is expanded, we need to avoid showing an empty row expansion
         */
        if (!row.getCanExpand()) {
            return <></>;
        }

        return (
            <div className='flex flex-col w-full gap-6 p-4 bg-backgroundPrimary'>
                <BehindFlag flagName='metadataEditingFormatExpressionsUi'>
                    <FormatExpressionField
                        key={`${row.original.name}_FormatExpression`}
                        columnName={row.original.name}
                        columns={columns}
                    />
                </BehindFlag>

                <ShapeConfigField
                    key={`${row.original.name}_ShapeConfig`}
                    columnName={row.original.name}
                    shapeName={row.original.shape.value}
                />
            </div>
        );
    };

    const tableProps = useApplicationTable({
        classNames: { actionColumn: 'from-tileBackground', tableHeader: 'z-10' },
        config: { actions, renderExpandedRow, getRowCanExpand },
        columns: tableColumns,
        data: tableData,
        getRowId: (row) => row.name,
        hiddenActions: 'collapse',
        layoutVersion: 'v2',
        defaultColumn: {
            minSize: 10 // in rem
        }
    });

    /**
     * Track which rows we've auto-expanded so the user can collapse them again if they want to.
     */
    const [autoExpandedRows, setAutoExpandedRows] = useState<string[]>([]);
    const rowIdsToExpand = tableProps.table
        .getRowModel()
        .rows.filter((r) => r.original.failedConversion && !autoExpandedRows.includes(r.id))
        .map((r) => r.original.name);

    if (rowIdsToExpand.length > 0) {
        rowIdsToExpand.forEach((rowId) => {
            const row = tableProps.table.getRow(rowId);

            if (!row.getIsExpanded()) {
                row.toggleExpanded();
            }
        });

        setAutoExpandedRows((r) => [...r, ...rowIdsToExpand]);
    }

    /**
     * Auto-expand rows when the shape changes so the user can see the shape config fields
     */
    const [columnShapes, setColumnShapes] = useState<string[]>(tableData.map((d) => d.shape.value));

    if (columnShapes.length !== tableData.length) {
        setColumnShapes(tableData.map((d) => d.shape.value));
    } else {
        const changedShape = tableData.find((s, i) => s.shape.value !== columnShapes[i]);

        if (changedShape?.shape.value) {
            setColumnShapes(tableData.map((d) => d.shape.value));

            if (hasShapeConfigFields(changedShape.shape.value) && changedShape.isOverridden) {
                tableProps.table.setExpanded((e) => (typeof e === 'boolean' ? e : { ...e, [changedShape.name]: true }));
            }
        }
    }

    return (
        <>
            <ControlledApplicationTable {...tableProps} />
            {(existingComparison || selectedComparisonColumn) && (
                <MetadataEditorComparisonModal
                    existingComparison={existingComparison}
                    selectedComparisonColumn={selectedComparisonColumn}
                    columns={nonPatternColumns}
                    dispatch={dispatch}
                    onClose={() => {
                        setExistingComparison(undefined);
                        setSelectedComparisonColumn(undefined);
                    }}
                />
            )}
        </>
    );
};
