import * as TWEEN from '@tweenjs/tween.js';
import stringify from 'fast-json-stable-stringify';
import { sortBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNodesInitialized, useReactFlow } from 'reactflow';
import { Layout } from 'webcola';
import {
    useGetNetworkEdges,
    useGetNetworkNodes,
    useLayoutType,
    useSetNetworkNodes
} from '../context/NetworkMapStoreContext';
import { LayoutTypes, PositionedPinnableNode } from '../layout/types';

const animationDuration = 350;
const frameMs = 16.7;
const targetLength = 40;
const layouts = new Map([
    [
        LayoutTypes.hierarchyVertical,
        new Layout()
            .flowLayout('y', targetLength * 2.5)
            .jaccardLinkLengths(targetLength * 3.25, 1)
            .groupCompactness(0.25)
            .avoidOverlaps(true)
            .start()
            .stop()
    ],
    [
        LayoutTypes.hierarchyHorizontal,
        new Layout()
            .flowLayout('x', targetLength * 4)
            .jaccardLinkLengths(targetLength * 3.25, 1)
            .groupCompactness(0.25)
            .avoidOverlaps(true)
            .start()
            .stop()
    ],
    [
        LayoutTypes.network,
        new Layout()
            .linkDistance(targetLength)
            .symmetricDiffLinkLengths(targetLength * 0.175, 1)
            .groupCompactness(2)
            .avoidOverlaps(true)
            .start()
            .stop()
    ]
]);

export const useAnimateLayout = () => {
    const isNodesInitialized = useNodesInitialized();
    const { fitView } = useReactFlow();
    const setNodes = useSetNetworkNodes();
    const getNodes = useGetNetworkNodes();
    const getEdges = useGetNetworkEdges();
    const layoutType = useLayoutType();
    const previousLayout = useRef(layoutType);
    const previousStateString = useRef('');
    const [isReactFlowReady, setIsReactFlowReady] = useState(isNodesInitialized);

    const nodeStateString = stringify(
        sortBy(
            getNodes().filter(({ hidden }) => !hidden),
            'id'
        ).map(({ id, data }) => [id, data.expanded, data.pinned])
    );

    useEffect(() => {
        // We only need to set isReactFlowReady once to use fitView so we handle it in the useEffect
        // (useNodesInitialized changes every time new nodes are added, but once ReactFlow is initialized
        // you can use the zoom function)
        if (isNodesInitialized) {
            setIsReactFlowReady(true);
        }
    }, [isNodesInitialized]);

    useEffect(() => {
        const layout = layouts.get(layoutType);
        const isDifferentLayoutType = previousLayout.current !== layoutType;
        const isDifferentStateString = previousStateString.current !== nodeStateString;

        if (!layout || !isReactFlowReady) {
            return;
        }

        // Track the current layoutType
        if (isDifferentLayoutType) {
            previousLayout.current = layoutType;
        }

        // Track the current stateString
        if (isDifferentStateString) {
            previousStateString.current = nodeStateString;
        }

        let animationFrame = 0;

        const nodesForAnimation = getNodes().filter(({ hidden }) => !hidden) as PositionedPinnableNode[];
        const positionedNodes = nodesForAnimation.map((node) => {
            const KPICount = node.data.kpiCount;
            const width = KPICount ? node.width + 200 : node.width + 140;
            const height = KPICount ? node.height + KPICount * 30 + 100 : node.height + 140;

            return {
                id: node.id,
                width: Math.max(width, 120),
                height: Math.max(height, 120),
                fixed: node.data.fixedPosition ? 1 : 0,
                x: isDifferentLayoutType ? width / 2 : node.position.x,
                y: isDifferentLayoutType ? height / 2 : node.position.y,
                type: node.type
            };
        });

        const edges = getEdges()
            .filter(
                ({ source, target }) =>
                    positionedNodes.find(({ id }) => source === id) && positionedNodes.find(({ id }) => target === id)
            )
            .map((edge) => {
                const source = positionedNodes.find(({ id }) => edge.source === id)!;
                const target = positionedNodes.find(({ id }) => edge.target === id)!;

                return {
                    ...edge,
                    source,
                    target
                };
            });

        const tweenNodePositionsMap = new Map(positionedNodes.map(({ id, x, y }) => [id, { x, y }]));

        const animate = () => {
            layout.stop();

            const layoutNodePositionsMap = new Map(
                layout.nodes().map(({ id, x, y, width, height }: any) => [
                    id,
                    {
                        x: x - width / 2,
                        y: y - height / 2
                    }
                ])
            );

            tweenNodePositionsMap.forEach((position, id) => {
                const targetPosition = layoutNodePositionsMap.get(id);

                if (targetPosition) {
                    TWEEN.add(
                        new TWEEN.Tween(position)
                            .to(targetPosition, animationDuration)
                            .easing(TWEEN.Easing.Circular.Out)
                            .start()
                    );
                }
            });

            animateFrame();
        };

        const animateFrame = (time = 0) => {
            TWEEN.update();

            setNodes((currentNodes) =>
                currentNodes.map((node) => ({
                    ...node,
                    ...(tweenNodePositionsMap.has(node.id) && {
                        position: tweenNodePositionsMap.get(node.id)
                    })
                }))
            );

            if (time > animationDuration * 1.5) {
                TWEEN.removeAll();
                cancelAnimationFrame(animationFrame);
            } else {
                animationFrame = requestAnimationFrame(() => animateFrame(time + frameMs));
            }

            fitView({ padding: 0.2 });
        };

        layout
            .nodes(positionedNodes)
            .links(edges)
            .groups([])
            .avoidOverlaps(true)
            .handleDisconnected(false)
            .start(150, 150, 150, undefined, false);

        animate();

        return () => cancelAnimationFrame(animationFrame);
    }, [layoutType, nodeStateString, isReactFlowReady, getNodes, setNodes, getEdges]); // eslint-disable-line react-hooks/exhaustive-deps
};
