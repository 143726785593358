import { DataRequestPurpose } from '@squaredup/constants';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import { UseDataStreamQueryOptions, useDataStream } from './useDataStream';
import { UseDataStreamConfigResult, useDataStreamConfigToRequest } from './useDataStreamConfigToRequest';

export function useDataStreamConfig(
    config: DataStreamBaseTileConfig,
    queryOptions?: UseDataStreamQueryOptions,
    purpose?: DataRequestPurpose
): UseDataStreamConfigResult {
    const {
        dataStreamRequest,
        isConfigured,
        isConfiguredStrict,
        streamDefinitionLoading,
        streamDefinitionError,
        errorMessage
    } = useDataStreamConfigToRequest(config, queryOptions, purpose);

    const { isGlobal, workspace } = useDataStreamWorkspaceContext();
    const result = useDataStream(dataStreamRequest, isGlobal ? null : workspace, queryOptions);

    return {
        ...result,
        queryKey:
            dataStreamRequest == null
                ? undefined
                : streamDataKeys.forRequest(dataStreamRequest, queryOptions?.extraKeys),
        isConfigured,
        isConfiguredStrict,
        isLoading: streamDefinitionLoading || result.isLoading,
        error: streamDefinitionError ?? errorMessage ?? result.error
    };
}
