import type { DashboardType } from '@squaredup/dashboards';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import { Button } from 'components/button/Button';
import Field from 'components/forms/field/Field';
import { useLinkedPluginConfigs } from 'components/hooks/useLinkedPluginConfigs';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import { ResultMessage } from 'components/ui/state/ResultMessage';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { datasourceQueryKeys } from 'queries/queryKeys/datasourceKeys';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { CopyTo } from 'services/DashboardService';
import { SCOPES } from 'services/WorkspaceService';
import { getErrorMessage } from './utils';

type DashboardCopyToModalProps = {
    dashboard: DashboardType | undefined;
    workspace: string;
    close: () => void;
};

export const DashboardCopyToModal: React.FC<DashboardCopyToModalProps> = ({ dashboard, workspace, close }) => {
    const queryClient = useQueryClient();
    const [isCopying, setIsCopying] = useState(false);
    const [error, setError] = useState<Record<string, any> | undefined>(undefined);
    const [success, setSuccess] = useState<Record<string, any> | undefined>(undefined);
    const navigate = useNavigate();

    const { data: linkedPlugins, isLoading: isLoadingLinkedPluginConfigs } = useLinkedPluginConfigs(workspace);
    const { data: workspaces, isLoading: isLoadingWorkspaces } = useWorkspaces();

    const { canWrite, isLoading: isLoadingPermissions } = useWorkspacePermissions();

    const isLoading = isLoadingWorkspaces || isLoadingPermissions || isLoadingLinkedPluginConfigs;

    const handleMove = useMutation(async (data: Record<string, Record<string, string>>) => {
        if (success) {
            close();
            navigate(`/dashboard/${success.newDashboardId}`);
        } else {
            const sourceWorkspaceId = workspace;
            const targetWorkspaceId = data.targetWorkspace.value;
            const targetWorkspaceName = data.targetWorkspace.label;

            if (dashboard === undefined) {
                throw new Error('Dashboard unrecognised. Please reload the page or navigate to a different dashboard.');
            }

            setError(undefined);
            setIsCopying(true);

            const response = await CopyTo(dashboard.id, sourceWorkspaceId, targetWorkspaceId);

            if (response.migrationResult.status === 201) {
                const newDashboardId = response.migrationResult.targetDashboard.dashboardId;
                setIsCopying(false);
                setSuccess({ newDashboardId, targetWorkspaceName });

                // Ensure navigation is kept up to date
                queryClient.invalidateQueries(workspaceQueryKeys.all);
                queryClient.invalidateQueries(dashboardQueryKeys.all);
                queryClient.invalidateQueries(datasourceQueryKeys.all);
                queryClient.invalidateQueries(datasourceConfigQueryKeys.all);

                // Migration may have added scopes and data sources to target workspace.
                queryClient.invalidateQueries([SCOPES, targetWorkspaceId]);
                queryClient.invalidateQueries(scopeQueryKeys.workspace(targetWorkspaceId));
                queryClient.invalidateQueries(
                    dataStreamDefinitionQueryKeys.forWorkspace(targetWorkspaceId, { includeTemplates: undefined })
                );
            } else {
                let pluginList;

                if (response.migrationResult.failureCode === 'PluginLinkPermission') {
                    pluginList = response.migrationResult.linksFailed.plugins.map(
                        (configId: string) =>
                            linkedPlugins?.find((linkedPlugin) => linkedPlugin.id === configId)?.displayName
                    );
                }

                const pluginDisplayName = pluginList?.[0];

                if (pluginList?.length === 1) {
                    pluginList = undefined;
                }

                setIsCopying(false);
                setError({
                    targetWorkspaceName,
                    data: response.migrationResult,
                    pluginDisplayName,
                    pluginList
                });
            }
        }
    });

    const methods = useForm({
        shouldUnregister: true,
        mode: 'all'
    });

    const {
        handleSubmit,
        formState: { isValid, errors, isSubmitting }
    } = methods;

    return (
        <Modal title='Copy dashboard' close={close} fullWidth maxWidth='max-w-3xl'>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit((data) => handleMove.mutateAsync(data))}>
                    <hr className='border-dividerPrimary' />
                    <div className='px-8 py-4'>
                        {isLoading ? (
                            <div className='flex justify-center my-2'>
                                <div>
                                    <LoadingSpinner />
                                </div>
                            </div>
                        ) : (
                            <Field.Input
                                name='targetWorkspace'
                                type='autocomplete'
                                label='Destination workspace'
                                placeholder='Search or select a workspace'
                                options={workspaces
                                    ?.filter((w) => canWrite(w) && w.id !== workspace)
                                    ?.map((w) => ({ label: w.displayName, value: w.id }))}
                                isMulti={false}
                                validation={{ required: true }}
                                isDisabled={isLoading || isSubmitting || success}
                                noOptionsMessage={() => 'No workspaces available.'}
                            />
                        )}
                        {error && (
                            <ResultMessage
                                state='error'
                                title={`Unable to copy this dashboard to "${error.targetWorkspaceName}" workspace`}
                                body={getErrorMessage(error)}
                                className='mt-8'
                                list={error.pluginList}
                            />
                        )}
                        {success && (
                            <ResultMessage
                                state='success'
                                title={`Successfully copied "${dashboard?.displayName}" dashboard to "${success.targetWorkspaceName}" workspace`}
                                className='mt-8'
                            />
                        )}
                    </div>
                    <ModalButtons>
                        <Button type='button' variant='tertiary' onClick={() => close()}>
                            {success ? 'Close' : 'Cancel'}
                        </Button>
                        <Button
                            type='submit'
                            disabled={isLoading || isSubmitting || !isValid || Object.keys(errors).length > 0}
                            data-testid='submit-workspaces'
                        >
                            {isCopying ? (
                                <LoadingSpinner size={18} />
                            ) : success ? (
                                'Go to dashboard'
                            ) : error ? (
                                'Try again'
                            ) : (
                                'Copy'
                            )}
                        </Button>
                    </ModalButtons>
                </form>
            </FormProvider>
        </Modal>
    );
};
