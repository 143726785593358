import { Serialised } from '@squaredup/ids';
import { isV1AlertingRule } from '@squaredup/monitoring';
import { ColumnDef } from '@tanstack/react-table';
import Modal from 'components/Modal';
import { TruncatedText } from 'components/TruncatedText';
import type { ProjectedChannel } from 'dynamo-wrapper';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import React from 'react';
import { Workspace } from 'services/WorkspaceService';

type NotificationsInspectModalArgs = {
    channel: Serialised<ProjectedChannel>;
    workspaces: Workspace[];
    close: () => void;
};

interface WorkspaceStats {
    displayName: string;
    ruleCount: number;
}

const NotificationsWorkspacesApplicationTable = ApplicationTable<WorkspaceStats, string | number>();

export const NotificationsWorkspacesModal: React.FC<NotificationsInspectModalArgs> = ({
    channel,
    close,
    workspaces
}) => {
    const workspacesUsingChannel = workspaces
        .reduce((acc: WorkspaceStats[], workspace) => {
            const rules = workspace.data.alertingRules ?? [];

            const usingChannel = rules.filter((r) =>
                isV1AlertingRule(r) ? r.channelId === channel.id : r.channels.some((rc) => rc.id === channel.id)
            );

            return acc.concat([{ displayName: workspace.displayName, ruleCount: usingChannel.length }]);
        }, [])
        .filter((stats) => stats.ruleCount > 0);

    const columns: ColumnDef<WorkspaceStats>[] = [
        {
            accessorKey: 'ruleCount',
            id: 'rules',
            header: 'Rules',
            cell: ({ row }) => row.original.ruleCount,
            size: 100
        },
        {
            accessorKey: 'displayName',
            id: 'workspace',
            header: 'Workspace',
            cell: ({ row }) => {
                const displayName = row.original.displayName;
                return <TruncatedText title={displayName}>{displayName}</TruncatedText>;
            },
            size: 280
        }
    ];

    return (
        <Modal
            title={`Notification rules using ${channel.displayName}`}
            close={close}
            fullWidth
            maxWidth='max-w-3xl'
            headerBorder
            maxHeight='h-fit'
        >
            <div className='flex flex-col min-h-0 px-6 py-5 max-h-72'>
                <NotificationsWorkspacesApplicationTable
                    config={{
                        noDataMessage: 'There are no workspaces using this channel.'
                    }}
                    columns={columns}
                    data={workspacesUsingChannel}
                />
            </div>
        </Modal>
    );
};
