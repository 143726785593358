import { AllowIdStrings, Serialised } from '@squaredup/ids';
import type {
    DataStreamDefinitionEntity,
    ProjectedDataStreamDefinitionEntity,
    SettableDataStreamProperties
} from 'dynamo-wrapper';
import API from './API';
import { handleError, handleResponse } from './util';

/**
 * A data stream entity as received by the client from the API.
 */
export type DataStream = Serialised<ProjectedDataStreamDefinitionEntity>;

/*
    react-query key for custom Data Stream definitions
 */
export const CUSTOM_DATA_STREAM_DEFINITIONS = 'customDataStreamDefinitions';

/**
 * Check if the stream can match objects
 * @param def Data stream definition
 */
export const isMatchable = (stream: DataStream): boolean => !stream.definition.options?.noMatch;

export const Get = async (id: string) => {
    return API.get<Serialised<DataStreamDefinitionEntity>>(`/datastreams/${id}`)
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Get all data streams defined in this tenant
 * @returns
 */
export const ListCustomDataStreams = async (): Promise<DataStream[]> => {
    return API.get<DataStream[]>('/datastreams/custom').then(handleResponse).catch(handleError);
};

/**
 * Get all data streams visible to this tenant (defined in the tenant and defined in
 * plugins which have been added to this tenant)
 * @returns
 */
export const List = async (): Promise<DataStream[]> => {
    return API.get<DataStream[]>('/datastreams').then(handleResponse).catch(handleError);
};

/**
 * Get all the data streams that are relevant to a workspace (e.g. those for plugins that are
 * linked to the workspace)
 */
export const ListForWorkspace = async (
    workspaceId: string | undefined | null,
    includeTemplates?: boolean | undefined | null
): Promise<DataStream[]> =>
    API.get<DataStream[]>('/datastreams', {
        params: {
            workspaceId,
            includeTemplates
        }
    })
        .then(handleResponse)
        .catch(handleError);

/**
 * Get all the data streams that apply to any workspace we have access to.
 * Useful for global scenarios like search -> drilldown.
 */
export const ListForAllWorkspaces = async (includeTemplates?: boolean | undefined | null): Promise<DataStream[]> =>
    API.get<DataStream[]>('/datastreams', {
        params: {
            accessControlType: 'directOrAnyWorkspaceLinks',
            includeTemplates
        }
    })
        .then(handleResponse)
        .catch(handleError);

export const Create = async (props: AllowIdStrings<SettableDataStreamProperties>) => {
    const created = await API.post<Serialised<DataStreamDefinitionEntity>>('/datastreams', props)
        .then(handleResponse)
        .catch(handleError);

    return created.id;
};

export const Update = async (id: string, props: Partial<AllowIdStrings<SettableDataStreamProperties>>) => {
    await API.put<Serialised<DataStreamDefinitionEntity>>(`/datastreams/${id}`, props)
        .then(handleResponse)
        .catch(handleError);
    return id;
};

export const Delete = async (id: string) => {
    const success = await API.delete(`/datastreams/${id}`)
        .then(handleResponse)
        .then(() => true)
        .catch(handleError)
        .catch(() => false);
    return success;
};
