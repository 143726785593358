import { FC } from 'react';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';

interface LinkImportModalArgs {
    dataSourceName: string;
    onClose: () => void;
    onSubmit: () => void;
}

export const LinkImportModal: FC<LinkImportModalArgs> = ({ dataSourceName, onClose, onSubmit }) => {
    return (
        <Modal
            title={`Do you want to install dashboards for '${dataSourceName}'?`}
            description={'Pre-built dashboards are added to the list of dashboards in this workspace'}
        >
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={onClose}>
                    Proceed without installing
                </Button>

                <Button type='button' onClick={onSubmit}>
                    Add
                </Button>
            </ModalButtons>
        </Modal>
    );
};
