import type { UserTenantProperties } from 'dynamo-wrapper';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { useQuery, type UseQueryOptions } from 'react-query';
import { GetTenantUserProperties } from 'services/UserService';

export const useTenantProperties = <T = UserTenantProperties>(
    options: Omit<UseQueryOptions<UserTenantProperties, unknown, T, string[]>, 'cacheTime' | 'staleTime'>
) => {
    return useQuery(tenantQueryKeys.tenantProperties, ({ signal }) => GetTenantUserProperties(signal), {
        ...options,
        cacheTime: Number.POSITIVE_INFINITY,
        staleTime: Number.POSITIVE_INFINITY
    });
};
