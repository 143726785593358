import { SimpleTile } from 'dashboard-engine/basetiles/types';
import graphNetwork from '../datasources/graph/graphNetwork/graphNetwork';

const tile: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: graphNetwork
    },
    transforms: [],
    visualisation: {
        type: 'network'
    }
};

export default tile;
