import type { SimpleTile } from 'dashboard-engine/basetiles/types';
import custom from '../tiles/custom';
import dataStream from '../tiles/data-stream';
import graphAsNetwork from '../tiles/graph-as-network';
import graphNodeAsBlocks from '../tiles/graph-node-as-blocks';
import graphNodeAsTable from '../tiles/graph-node-as-table';
import graphNodeText from '../tiles/graph-node-text';
import graphNodesAsBlocks from '../tiles/graph-nodes-as-blocks';
import graphNodesText from '../tiles/graph-nodes-text';
import iframe from '../tiles/iframe';
import image from '../tiles/image';
import newTile from '../tiles/new';
import script from '../tiles/script';
import text from '../tiles/text';
import workspacesAsNetwork from '../tiles/workspaces-as-network';
import GenericRepo from './GenericRepo';

export interface Tile {
    tile: string;
    displayName: string;
    icon?: string | Array<string>;
    isHidden?: boolean;
    defaultTileConfig?:
        | SimpleTile
        | {
              baseTile: 'data-stream-base-tile';
          };
}

const tileRepo = new GenericRepo<Tile>({
    'graph-nodes-text': {
        tile: 'graph-nodes-text',
        displayName: 'Graph Nodes Text',
        isHidden: true,
        defaultTileConfig: graphNodesText
    },
    'graph-node-text': {
        tile: 'graph-node-text',
        displayName: 'Graph Node Text',
        isHidden: true,
        defaultTileConfig: graphNodeText
    },
    'graph-node-as-blocks': {
        tile: 'graph-node-as-blocks',
        displayName: 'Graph Node As Blocks',
        icon: 'th',
        defaultTileConfig: graphNodeAsBlocks
    },
    'graph-node-as-table': {
        tile: 'graph-node-as-table',
        displayName: 'Graph Node As Table',
        icon: ['far', 'table'],
        defaultTileConfig: graphNodeAsTable
    },
    'graph-nodes-as-blocks': {
        tile: 'graph-nodes-as-blocks',
        displayName: 'Graph Nodes As Blocks',
        icon: 'shapes',
        defaultTileConfig: graphNodesAsBlocks
    },
    'graph-as-network': {
        tile: 'graph-as-network',
        displayName: 'Graph As Network',
        icon: ['far', 'chart-network'],
        defaultTileConfig: graphAsNetwork
    },
    'workspaces-as-network': {
        tile: 'workspaces-as-network',
        displayName: 'Workspaces As Network',
        defaultTileConfig: workspacesAsNetwork
    },
    iframe: {
        tile: 'iframe',
        displayName: 'Embed',
        icon: 'browser',
        defaultTileConfig: iframe
    },
    image: {
        tile: 'image',
        displayName: 'Image',
        icon: 'image',
        defaultTileConfig: image
    },
    text: {
        tile: 'text',
        displayName: 'Text',
        icon: 'align-left',
        defaultTileConfig: text
    },
    new: {
        tile: 'new',
        displayName: 'New',
        isHidden: true,
        defaultTileConfig: newTile
    },
    custom: {
        tile: 'custom',
        displayName: 'custom tile',
        isHidden: true,
        defaultTileConfig: custom
    },
    'data-stream': {
        tile: 'data-stream',
        displayName: 'Data Stream',
        icon: 'rocket-launch',
        defaultTileConfig: dataStream
    },
    script: {
        tile: 'script',
        displayName: 'Script',
        icon: 'code',
        defaultTileConfig: script
    }
});

export default tileRepo;
