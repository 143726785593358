import type { SimpleTileConfig } from '@squaredup/dashboards';
import { SimpleTile } from 'dashboard-engine/basetiles/types';
import noOp from '../datasources/misc/noOp/noOp';
import { IframeConfig } from '../visualisations/IFrame/Config';
import IFrame from '../visualisations/IFrame/IFrame';

export type IframeTileConfig = SimpleTileConfig & {
    _type: 'tile/iframe';
    visualisation: {
        config: IframeConfig;
    };
};

const iframe: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: noOp
    },
    transforms: [],
    visualisation: {
        type: IFrame,
        config: {
            src: '',
            scale: 1,
            title: 'Embed',
            openOnClick: false
        } as IframeConfig
    }
};

export default iframe;
