import { SqUpPluginDisplayName } from '@squaredup/ids';
import OnePassword from 'icons/plugin-icons/1password.svg?react';
import AppDynamics from 'icons/plugin-icons/appdynamics.svg?react';
import AWS from 'icons/plugin-icons/aws.svg?react';
import AzureDevops from 'icons/plugin-icons/azure-devops.svg?react';
import Azure from 'icons/plugin-icons/azure.svg?react';
import CircleCI from 'icons/plugin-icons/circleci.svg?react';
import CiscoDNA from 'icons/plugin-icons/cisco-dna.svg?react';
import Cloudflare from 'icons/plugin-icons/cloudflare.svg?react';
import Confluence from 'icons/plugin-icons/confluence.svg?react';
import CSV from 'icons/plugin-icons/csv.svg?react';
import CustomPowershell from 'icons/plugin-icons/custom-powershell.svg?react';
import CustomUrl from 'icons/plugin-icons/custom-url.svg?react';
import Datadog from 'icons/plugin-icons/datadog.svg?react';
import DefaultPlugin from 'icons/plugin-icons/default-plugin-icon.svg?react';
import Disney from 'icons/plugin-icons/disney.svg?react';
import DynatraceManaged from 'icons/plugin-icons/dynatrace-managed.svg?react';
import Dynatrace from 'icons/plugin-icons/dynatrace.svg?react';
import Elasticsearch from 'icons/plugin-icons/elasticsearch.svg?react';
import Foglight from 'icons/plugin-icons/foglight.svg?react';
import GCP from 'icons/plugin-icons/gcp.svg?react';
import Geodata from 'icons/plugin-icons/geodata.svg?react';
import GitHub from 'icons/plugin-icons/github.svg?react';
import GitLab from 'icons/plugin-icons/gitlab.svg?react';
import GoogleAnalytics from 'icons/plugin-icons/google-analytics.svg?react';
import Grafana from 'icons/plugin-icons/grafana.svg?react';
import GripMatix from 'icons/plugin-icons/gripmatix.svg?react';
import IsDown from 'icons/plugin-icons/isdown.svg?react';
import Jenkins from 'icons/plugin-icons/jenkins.svg?react';
import Artifactory from 'icons/plugin-icons/jfrog-artifactory.svg?react';
import JiraService from 'icons/plugin-icons/jira-service.svg?react';
import Jira from 'icons/plugin-icons/jira.svg?react';
import Metrist from 'icons/plugin-icons/metrist.svg?react';
import Microsoft365 from 'icons/plugin-icons/microsoft-365.svg?react';
import MSSQL from 'icons/plugin-icons/mssql.svg?react';
import MySQL from 'icons/plugin-icons/mysql.svg?react';
import Nagios from 'icons/plugin-icons/nagios.svg?react';
import NewRelic from 'icons/plugin-icons/new-relic.svg?react';
import OctopusEnergy from 'icons/plugin-icons/octopus-energy.svg?react';
import ODBCOnPremise from 'icons/plugin-icons/odbc-onpremise.svg?react';
import OpenWeatherApp from 'icons/plugin-icons/openweatherapp.svg?react';
import PagerDuty from 'icons/plugin-icons/pagerduty.svg?react';
import Pendo from 'icons/plugin-icons/pendo.svg?react';
import Pingdom from 'icons/plugin-icons/pingdom.svg?react';
import Plex from 'icons/plugin-icons/plex.svg?react';
import PostgreSql from 'icons/plugin-icons/posgresql.svg?react';
import Prometheus from 'icons/plugin-icons/prometheus.svg?react';
import Salesforce from 'icons/plugin-icons/salesforce.svg?react';
import SampleData from 'icons/plugin-icons/sample-data.svg?react';
import SCOM from 'icons/plugin-icons/scom.svg?react';
import ServiceNow from 'icons/plugin-icons/servicenow.svg?react';
import Snyk from 'icons/plugin-icons/snyk.svg?react';
import SolarWindsOrion from 'icons/plugin-icons/solarwindsorion.svg?react';
import Splunk from 'icons/plugin-icons/splunk.svg?react';
import SquaredUp from 'icons/plugin-icons/squaredup.svg?react';
import StatusPage from 'icons/plugin-icons/statuspage.svg?react';
import ThousandEyes from 'icons/plugin-icons/thousandeyes.svg?react';
import UpTimeRobot from 'icons/plugin-icons/uptimerobot.svg?react';
import VmWareCenter from 'icons/plugin-icons/vmwarevcenter.svg?react';
import WebAPI from 'icons/plugin-icons/webapi.svg?react';
import Zabbix from 'icons/plugin-icons/zabbix.svg?react';
import Zendesk from 'icons/plugin-icons/zendesk.svg?react';
import React from 'react';

function getIcon(name?: string) {
    switch (name) {
        case '1Password':
            return OnePassword;
        case 'AppDynamics':
        case 'AppDynamics On-Premise':
            return AppDynamics;
        case 'Artifactory':
        case 'Artifactory On-Premise':
            return Artifactory;
        case 'AWS':
            return AWS;
        case 'Azure':
            return Azure;
        case 'Azure DevOps':
        case 'Azure DevOps On-Premise':
            return AzureDevops;
        case 'CircleCI':
            return CircleCI;
        case 'Cisco DNA':
            return CiscoDNA;
        case 'Cloudflare':
            return Cloudflare;
        case 'Confluence':
            return Confluence;
        case 'CSV':
        case 'CSV On-Premise':
            return CSV;
        case 'Custom PowerShell':
        case 'PowerShell':
        case 'PowerShell On Premise':
        case 'PowerShell On-Premise':
        case 'PowerShell On Prem':
            return CustomPowershell;
        case 'Custom URL':
            return CustomUrl;
        case 'Datadog':
            return Datadog;
        case 'Disney':
            return Disney;
        case 'Dynatrace':
        case 'Dynatrace On-Premise':
            return Dynatrace;
        case 'Dynatrace Managed':
            return DynatraceManaged;
        case 'Elasticsearch':
        case 'Elasticsearch On-Premise':
            return Elasticsearch;
        case 'Quest Foglight':
            return Foglight;
        case 'Google Analytics (GA4)':
        case 'Google Analytics (UA)':
            return GoogleAnalytics;
        case 'Google Cloud':
        case 'Google Cloud Platform':
            return GCP;
        case 'Geodata':
            return Geodata;
        case 'GitHub':
            return GitHub;
        case 'GitLab':
            return GitLab;
        case 'Grafana':
        case 'Grafana On-Premise':
            return Grafana;
        case 'GripMatix Logon Simulator for Citrix':
        case 'GripMatix Logon Simulator for Citrix On-Premise':
            return GripMatix;
        case 'Jira':
            return Jira;
        case 'Jira Service Management':
            return JiraService;
        case 'Jenkins':
            return Jenkins;
        case 'Metrist':
            return Metrist;
        case 'Microsoft 365':
            return Microsoft365;
        case 'MSSQL':
        case 'MSSQL On-Premise':
            return MSSQL;
        case 'MySQL':
        case 'MySQL On-Premise':
            return MySQL;
        case 'Nagios XI':
            return Nagios;
        case 'New Relic':
            return NewRelic;
        case 'ODBC On Premise':
            return ODBCOnPremise;
        case 'OpenWeatherMap':
            return OpenWeatherApp;
        case 'PagerDuty':
            return PagerDuty;
        case 'Pingdom':
            return Pingdom;
        case 'Pendo':
            return Pendo;
        case 'Plex':
            return Plex;
        case 'PostgreSQL':
        case 'PostgreSQL On-Premise':
            return PostgreSql;
        case 'Prometheus':
        case 'Prometheus On-Premise':
            return Prometheus;
        case 'Salesforce':
            return Salesforce;
        case 'Sample Data':
            return SampleData;
        case 'SCOM':
        case 'SCOM Managed Instance':
        case 'SCOM Managed Instance On-Premise':
            return SCOM;
        case 'ServiceNow':
            return ServiceNow;
        case 'Snyk':
            return Snyk;
        case 'SolarWinds Orion':
            return SolarWindsOrion;
        case 'Splunk Enterprise':
            return Splunk;
        case 'SquaredUp':
        case SqUpPluginDisplayName:
        case 'Usage':
            return SquaredUp;
        case 'Statuspage':
            return StatusPage;
        case 'ThousandEyes':
            return ThousandEyes;
        case 'UptimeRobot':
            return UpTimeRobot;
        case 'VMware vCenter':
            return VmWareCenter;
        case 'Web API':
        case 'Web API On-Premise':
            return WebAPI;
        case 'Zabbix':
            return Zabbix;
        case 'Zendesk Support':
            return Zendesk;
        case 'Octopus Energy':
            return OctopusEnergy;
        case 'IsDown':
            return IsDown;
        default:
            return DefaultPlugin;
    }
}

interface PluginIconProps {
    pluginName?: string;
    [key: string]: any;
}

/**
 * Strip any CI/CD suffixes for tenants, e.g. early release builds
 * @param name Plugin name
 * @returns Sanitised name, without suffix
 */
export const sanitizePluginName = (name?: string) => name?.replace(/-(dev|ci)-(.*)/gmu, '');

const PluginIcon: React.FC<PluginIconProps> = ({ pluginName, ...props }) => {
    const name = sanitizePluginName(pluginName);
    const Component = getIcon(name);

    return <Component data-icon={name} role='img' {...props} />;
};

export default PluginIcon;
