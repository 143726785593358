import { Divider } from '@/components/Divider';
import { cn } from '@/lib/cn';
import {
    IconDefinition,
    faBrowsers,
    faChartLine,
    faChartSimple,
    faCheck,
    faTable,
    faWarning
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisualisationHint } from '@squaredup/data-streams';
import Tooltip from 'components/tooltip/Tooltip';
import { matchVisualisations } from 'dashboard-engine/dataStreams/VisualisationMatching';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { visualisationOptionsRepo } from 'dashboard-engine/repositories/visualisationsRepo';
import { typeSupportsVisualisationOptions } from 'dashboard-engine/types/Visualisation';
import trackEvent from 'lib/analytics';
import { blocks } from 'lib/fontawesome/blocks';
import { donut } from 'lib/fontawesome/donut';
import { gauge } from 'lib/fontawesome/gauge';
import { scalar } from 'lib/fontawesome/scalar';
import { useMemo } from 'react';
import { useDatasetContext } from '../contexts/DatasetContext';
import { useTileEditorContext } from '../contexts/TileEditorContext';
import { DataStreamTileEditorVisualizationConfigPanel } from './DataStreamTileEditorVisualizationConfigPanel';
import { useAutomaticallySetBestVisualization } from './hooks/useAutomaticallySetBestVisualization';

const visualisationOptions: {
    name: VisualisationHint;
    icon: IconDefinition;
    iconWidth?: number;
    tooltip: string;
}[] = [
    { name: 'data-stream-table', icon: faTable, tooltip: 'Table' },
    { name: 'data-stream-scalar', icon: scalar, iconWidth: 34, tooltip: 'Scalar' },
    { name: 'data-stream-line-graph', icon: faChartLine, tooltip: 'Line' },
    { name: 'data-stream-bar-chart', icon: faChartSimple, tooltip: 'Bar' },
    { name: 'data-stream-blocks', icon: blocks, tooltip: 'Blocks' },
    { name: 'data-stream-donut-chart', icon: donut, tooltip: 'Donut' },
    { name: 'data-stream-iframe', icon: faBrowsers, tooltip: 'Embed' },
    { name: 'data-stream-gauge', icon: gauge, iconWidth: 28, tooltip: 'Gauge' }
];

const defaultIconWidth = 24;

/**
 * @returns Visualization && Monitoring configuration panel
 */
export const DataStreamTileEditorVisualizationPanel = () => {
    const { tileConfig, setTileConfig } = useTileEditorContext();
    const { data, isFetched, isConfigured, error } = useDataStreamConfig(tileConfig);
    const { setActivePreviewTab } = useDatasetContext();

    const currentVisualization = tileConfig.visualisation?.type;
    const visualizationConfig =
        tileConfig.visualisation?.type && tileConfig?.visualisation?.config?.[tileConfig.visualisation?.type];

    const handleVisualizationSelection = useMemo(
        () => (visualizationName: VisualisationHint) => {
            setTileConfig((currentConfig) => ({
                ...currentConfig,
                visualisation: {
                    ...currentConfig.visualisation,
                    type: visualizationName
                }
            }));
        },
        [setTileConfig]
    );

    const onSelectVisualization = (name: string) => {
        trackEvent('Visualization Selected', { name });
        setActivePreviewTab(0);
        return handleVisualizationSelection(name as VisualisationHint);
    };

    const validVisualizations = useMemo(
        () => matchVisualisations(data, visualizationConfig),
        [data, visualizationConfig]
    );

    useAutomaticallySetBestVisualization(
        isFetched && isConfigured && !currentVisualization,
        validVisualizations,
        handleVisualizationSelection
    );

    const availableVisualizations = visualisationOptions.map((visualization) => ({
        ...visualization,
        valid: visualisationOptionsRepo
            .get(visualization.name)
            .matchesData(data, tileConfig?.visualisation?.config?.[visualization.name]).success
    }));

    return (
        <div className='h-full min-h-0'>
            <div className='flex flex-col h-full min-h-0 py-5'>
                <div
                    className='grid flex-shrink-0 grid-cols-[repeat(auto-fit,minmax(66px,_1fr))] gap-2 justify-items-center mb-4 px-5'
                    role='list'
                >
                    {availableVisualizations.map((visualization) => (
                        <button
                            key={visualization.name}
                            disabled={!isConfigured}
                            onClick={() => onSelectVisualization(visualization.name)}
                            data-visualization={visualization.name}
                            data-isSelectedVisualization={currentVisualization === visualization.name}
                            className='w-full'
                        >
                            <Tooltip title={visualization.tooltip} disabled={!isConfigured}>
                                <div
                                    className={cn(
                                        'border rounded-md h-[54px] border-dividerPrimary relative hover:bg-secondaryButtonBackgroundHover hover:text-textPrimary',
                                        {
                                            'text-textDisabled pointer-events-none': !isConfigured,
                                            'border-primaryButtonBackground':
                                                currentVisualization === visualization.name
                                        }
                                    )}
                                >
                                    <div className='flex items-center justify-center h-full'>
                                        <FontAwesomeIcon
                                            icon={visualization.icon}
                                            fontSize={visualization.iconWidth || defaultIconWidth}
                                        />
                                    </div>
                                    {isConfigured && (
                                        <div className='absolute bottom-2 right-2 text-[10px] leading-none'>
                                            <FontAwesomeIcon icon={visualization.valid ? faCheck : faWarning} />
                                        </div>
                                    )}
                                </div>
                            </Tooltip>
                        </button>
                    ))}
                </div>

                {tileConfig.visualisation?.type && !error && data && (
                    <>
                        <Divider />
                        <div
                            className='flex-1 min-h-0 overflow-auto text-sm 10 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
                            id='visualizationConfigPanel'
                        >
                            {typeSupportsVisualisationOptions(tileConfig.visualisation.type) && (
                                <DataStreamTileEditorVisualizationConfigPanel key={tileConfig.visualisation.type} />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
