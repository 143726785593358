import DropdownMenu from '@/components/DropdownMenu';
import { faCircleNotch, faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTileContext } from 'contexts/TileContext';
import { useForceRefetch } from 'dashboard-engine/hooks/useForceRefetch';
import type { FC } from 'react';

export const ForceRefetch: FC = () => {
    const { config } = useTileContext();
    const { forceRefetch, isRefetching } = useForceRefetch(config);

    return (
        <DropdownMenu.Item
            icon={<FontAwesomeIcon icon={isRefetching ? faCircleNotch : faRefresh} spin={isRefetching} fixedWidth />}
            onSelect={() => forceRefetch()}
        >
            Force refresh
        </DropdownMenu.Item>
    );
};
