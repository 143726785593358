import DropdownMenu from '@/components/DropdownMenu';
import { faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardType } from '@squaredup/dashboards';
import { useDashboardForceRefetch } from 'dashboard-engine/hooks/useDashboardForceRefetch';

interface RefetchDropdownOptionProps {
    dashboard?: DashboardType;
}

export const RefetchDropdownOption: React.FC<RefetchDropdownOptionProps> = ({ dashboard }) => {
    const triggerRefetch = useDashboardForceRefetch(dashboard);

    return (
        <DropdownMenu.Item
            onSelect={triggerRefetch}
            icon={<FontAwesomeIcon icon={faRefresh} fixedWidth />}
        >
            Force refresh
        </DropdownMenu.Item>
    );
};
