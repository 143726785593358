import type { SimpleTileConfig, TileConfig } from '@squaredup/dashboards';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDashboardContext } from 'contexts/DashboardContext';
import { TileTypes } from 'dashboard-engine/constants';
import { IframeTileConfig } from 'dashboard-engine/tiles/iframe';
import { ImageTileConfig } from 'dashboard-engine/tiles/image';
import { TextTileConfig } from 'dashboard-engine/tiles/text';
import { DataStreamTileEditor } from './dataStream/TileEditor/DataStreamTileEditor';
import { IframeTileEditor } from './iframe/IframeTileEditor';
import { ImageTileEditor } from './image/ImageTileEditor';
import { JsonTileEditor } from './json/JsonTileEditor';
import { TextTileEditor } from './text/TextTileEditor';

export const TileEditor: React.FC<{
    config: TileConfig;
    tileType?: string;
    tileId: string;
    onClose: () => void;
}> = ({ config, tileType, tileId, onClose }) => {
    switch (tileType) {
        case TileTypes.image:
            return (
                <ImageTileEditor
                    config={config as ImageTileConfig}
                    tileId={tileId}
                    onClose={onClose}
                />
            );
        case TileTypes.text:
            return <TextTileEditor config={config as TextTileConfig} onClose={onClose} />;
        case TileTypes.embed:
            return <IframeTileEditor config={config as IframeTileConfig} onClose={onClose} />;
        case TileTypes.dataStream:
            return <DataStreamTileEditor config={config as DataStreamBaseTileConfig} onClose={onClose} />;
        default: // Show a JSON editor for unrecognised/undefined tile types
            return <JsonTileEditor config={config as SimpleTileConfig} onClose={onClose} />;
    }
};
