import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { TimeframeEnumValue } from '@squaredup/timeframes';

export const useFullscreenTileConfig = (
    config: DataStreamBaseTileConfig,
    timeframeOverride?: TimeframeEnumValue,
    /** Force all SQL datasets to use provided timeframe  */
    forceTimeframeOverride?: boolean
) => {
    const isSql = Boolean(config?.dataStream?.dataSourceConfig?.sql?.length);

    return {
        ...config,
        ...(timeframeOverride && {
            timeframe: timeframeOverride
        }),
        ...(isSql &&
            timeframeOverride && {
                dataStream: {
                    ...config.dataStream,
                    dataSourceConfig: {
                        ...config.dataStream?.dataSourceConfig,
                        tables: config.dataStream?.dataSourceConfig?.tables?.map((tableConfig) => ({
                            ...tableConfig,
                            config: {
                                ...tableConfig?.config,
                                ...((forceTimeframeOverride || !tableConfig?.config?.timeframe) && {
                                    timeframe: timeframeOverride
                                })
                            }
                        }))
                    }
                }
            })
    };
};
