import { orderBy } from 'lodash';
import type { FC } from 'react';
import { DataStreamListItem } from '../../components/DataStreamListItem';
import type { TileEditorDataStream } from '../../state/TileEditorState';

export type ConfigurationPresetListProps = {
    selectedDataStream: TileEditorDataStream | undefined;
    dataStreams: TileEditorDataStream[];
    onChange: (dataStream: TileEditorDataStream) => void;
};

export const ConfigurationPresetList: FC<ConfigurationPresetListProps> = ({
    selectedDataStream,
    dataStreams,
    onChange
}) => {
    if (selectedDataStream?.template == null && selectedDataStream?.definition?.presetOf == null) {
        return <></>;
    }

    const parentConfigurableDataStreamName =
        selectedDataStream.template != null
            ? selectedDataStream.definition.name
            : selectedDataStream?.definition?.presetOf;

    const presets = orderBy(
        dataStreams.filter(
            (ds) =>
                ds.definition.presetOf === parentConfigurableDataStreamName &&
                ds.pluginId === selectedDataStream.pluginId
        ),
        'displayName'
    );

    return (
        <div className='flex flex-col justify-items-stretch' role='listbox'>
            {presets.map((p) => {
                return (
                    <DataStreamListItem
                        key={p.id}
                        className='grid items-center grid-cols-2'
                        isSelected={p.id === selectedDataStream.id}
                        onClick={() => onChange(p)}
                        data-testid='dataStreamSelectPreset'
                    >
                        <span className='pr-2 font-semibold truncate'>{p.displayName}</span>
                        <span className='truncate text-textSecondary'>{p.description}</span>
                    </DataStreamListItem>
                );
            })}
        </div>
    );
};
