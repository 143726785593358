import { cn } from '@/lib/cn';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DashboardVariable } from '@squaredup/dashboards';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import { TruncatedText } from 'components/TruncatedText';
import DropdownMenu from 'components/dropdownMenu';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { useDashboardContext } from 'contexts/DashboardContext';
import trackEvent from 'lib/analytics';
import { cloneDeep, debounce, sortBy, uniqBy } from 'lodash';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { perspectiveQueryKeys } from 'queries/queryKeys/perspectiveKeys';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { DeleteVariable } from 'services/VariableService';
import { SearchBar } from 'ui/editor/dataStream/TileEditor/SearchBar';
import { useHandleSave } from '../hooks/useHandleSave';
import { DashboardVariableCreateEditModal } from './DashboardVariableCreateEditModal';
import { VariableDropdownObjects } from './VariableDropdownObjects';
import { getDropdownLabel, useSetCurrentVariable, useVariableObjects } from './utils/variableDropdown';

interface DashboardVariableDropdownProps {
    variable: DashboardVariable;
    disabled?: boolean;
    canEdit?: boolean;
    theme?: 'light' | 'dark';
}

export const DashboardVariableDropdown: React.FC<DashboardVariableDropdownProps> = ({
    variable,
    disabled,
    canEdit,
    theme
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(
        variable.selectedObjects.length === 0 && !variable.selectedAll && !disabled
    );
    const [searchTerm, setSearchTerm] = useState('');
    const [previousDisabled, setPreviousDisabled] = useState(disabled);
    const { dashboard, variables, setVariables } = useDashboardContext();
    const { mutate: updatedDashboard } = useHandleSave();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const handleSetVariable = useSetCurrentVariable(variable.id);
    const queryClient = useQueryClient();
    const menuContainer = useDOMElement('dialogContent');
    const debouncedSearch = useMemo(
        () =>
            debounce((search: string) => {
                // We intentionally don't include the search param here as it likely includes sensitive object names
                trackEvent('dashboardVariableSearch', {});
                return setSearchTerm(search);
            }, 500),
        [setSearchTerm]
    );

    // Open dropdown if it was initially disabled, but now is not
    if (!disabled && previousDisabled) {
        setPreviousDisabled(false);
        setIsDropdownOpen(variable.selectedObjects.length === 0 && !variable.selectedAll && !disabled);
    }

    // store objects that were selected before opening the dropdown and after closing it
    const [dropdownSelectedObjects, setDropdownSelectedObjects] = useState(sortBy(variable.selectedObjects, 'name'));
    const variableObjectsQuery = useVariableObjects(variable.scopeId, searchTerm, !disabled);

    // place selected objects first in the list, removing duplicates
    const variableObjectsValues = useMemo(() => {
        return uniqBy([...dropdownSelectedObjects, ...variableObjectsQuery.objects], 'id');
    }, [variableObjectsQuery.objects, dropdownSelectedObjects]);

    const variableObjects = {
        ...variableObjectsQuery,
        objects: variableObjectsValues
    };

    const allowMultipleSelection = variable.allowMultipleSelection || variable.default === 'all';
    const selectedObjectIds = variable.selectedObjects.map(({ id }) => id);
    const highlightTrigger = !disabled && !variable.selectedAll && variable.selectedObjects.length === 0;

    const hasObjects = !searchTerm ? !variableObjects.isLoadingObjects && (variableObjects.count ?? 0) > 0 : true;

    const setAllSelected = (selected: boolean) => {
        setSearchTerm('');
        handleSetVariable({
            ...variable,
            selectedAll: selected,
            selectedObjects: []
        });
    };

    const handleClearSelection = () => {
        handleSetVariable({
            ...variable,
            selectedAll: false,
            selectedObjects: []
        });
    };

    const handleDeleteVariable = async () => {
        await DeleteVariable(variable.id);

        const updatedVariables = variables?.filter((currentVariable) => currentVariable.id !== variable.id) ?? [];

        queryClient.setQueryData(dashboardQueryKeys.variables(dashboard.id), updatedVariables);
        queryClient.invalidateQueries(perspectiveQueryKeys.all);

        setVariables?.(updatedVariables);

        const newDashboard = cloneDeep(dashboard);
        newDashboard.content.contents.forEach((value) => {
            if ('variables' in value.config) {
                delete value.config.variables;
            }
            value.config.dataStream?.dataSourceConfig?.tables?.forEach((table) => {
                delete table?.config?.variables;
            });
        });

        updatedDashboard({
            ...newDashboard,
            variables: updatedVariables.length === 0 ? undefined : updatedVariables.map((v) => v.id)
        });

        setIsDeleteModalOpen(false);
    };

    const onToggle = (object: { id: string; name: string }) => {
        let newObjects = [...variable.selectedObjects];

        if (selectedObjectIds.includes(object.id)) {
            newObjects = newObjects.filter((o) => o.id !== object.id);
        } else {
            newObjects = [...newObjects, object];
        }

        handleSetVariable({
            ...variable,
            selectedObjects: newObjects
        });
    };

    const onSelect = (object: { id: string; name: string }) => {
        handleSetVariable({
            ...variable,
            selectedAll: false,
            selectedObjects: [object]
        });
    };

    const handleOpenChange = (isOpen: boolean) => {
        setIsDropdownOpen(isOpen);

        if (!isOpen) {
            setDropdownSelectedObjects(sortBy(variable.selectedObjects, 'name'));
            setSearchTerm('');
        }
    };

    return (
        <div className='flex items-center'>
            <DropdownMenu open={isDropdownOpen} onOpenChange={handleOpenChange} modal={false}>
                <DropdownMenu.Button
                    className={cn('max-w-[200px] [&>*]:flex [&>*]:overflow-hidden', {
                        'bg-primaryButtonBackground ring-primaryButtonBackground data-[state="open"]:bg-primaryButtonBackground text-primaryButtonText hover:bg-primaryButtonBackgroundHover focus:bg-primaryButtonBackgroundHover focus:ring-primaryButtonBackgroundHover border-primaryButtonBackground hover:border-primaryButtonBackgroundHover data-[state="open"]:border-primaryButtonBackgroundHover':
                            highlightTrigger
                    })}
                    disabled={disabled}
                    useFilterStyle
                    dropdownOpen={isDropdownOpen}
                    icon={<FontAwesomeIcon icon={faBarsFilter} fixedWidth={true} />}
                    data-testid='dashboardVariableDropdown'
                    showChevron
                    chevronDefaultClass={highlightTrigger ? 'text-primaryButtonText' : ''}
                    chevronActiveClass={highlightTrigger ? 'text-primaryButtonText' : ''}
                >
                    <TruncatedText title={getDropdownLabel(variable, disabled)} tooltipAsChild={true} />
                </DropdownMenu.Button>
                <DropdownMenu.Menu
                    align='start'
                    zIndex={14}
                    className='w-[500px] max-w-[100vw]'
                    container={menuContainer ?? undefined}
                    theme={theme}
                >
                    {hasObjects && (
                        <>
                            <SearchBar
                                placeholder='Search objects...'
                                initialValue={searchTerm}
                                onChange={debouncedSearch}
                                className='mb-3'
                                testId='dashboardVariableSearch'
                            />
                            {allowMultipleSelection && (
                                <>
                                    <DropdownMenu.Separator />
                                    <DropdownMenu.CheckboxItem
                                        checked={variable.selectedAll}
                                        onCheckedChange={() => setAllSelected(!variable.selectedAll)}
                                        testId='dashboardVariableSelectAll'
                                    >
                                        Select all
                                    </DropdownMenu.CheckboxItem>
                                    <DropdownMenu.Separator />
                                </>
                            )}
                        </>
                    )}

                    <DropdownMenu.Group>
                        <VariableDropdownObjects
                            variableObjects={variableObjects}
                            resetScrollKey={searchTerm}
                            renderObjectRow={(object) =>
                                allowMultipleSelection ? (
                                    <DropdownMenu.CheckboxItem
                                        key={object.id}
                                        className='pr-xs'
                                        checked={selectedObjectIds.includes(object.id) || variable.selectedAll}
                                        onCheckedChange={() => onToggle(object)}
                                        disabled={variable.selectedAll}
                                        testId='dashboardVariableObject'
                                    >
                                        <TruncatedText title={object.name} className='overflow-hidden' />
                                    </DropdownMenu.CheckboxItem>
                                ) : (
                                    <DropdownMenu.CheckItem
                                        key={object.id}
                                        checked={selectedObjectIds.includes(object.id)}
                                        onSelect={() => onSelect(object)}
                                        testId='dashboardVariableObject'
                                    >
                                        <TruncatedText title={object.name} />
                                    </DropdownMenu.CheckItem>
                                )
                            }
                        />
                    </DropdownMenu.Group>
                    <DropdownMenu.Separator />
                    <DropdownMenu.SecondaryItem onSelect={handleClearSelection}>
                        <span className='text-sm text-textSecondary'>Clear selection</span>
                    </DropdownMenu.SecondaryItem>
                    {canEdit && (
                        <>
                            <DropdownMenu.SecondaryItem onSelect={() => setIsEditModalOpen(true)}>
                                <span className='text-sm text-textSecondary'>Edit variable</span>
                            </DropdownMenu.SecondaryItem>
                            <DropdownMenu.SecondaryItem onSelect={() => setIsDeleteModalOpen(true)}>
                                <span className='text-sm text-statusErrorPrimary'>Delete variable</span>
                            </DropdownMenu.SecondaryItem>
                        </>
                    )}
                </DropdownMenu.Menu>
            </DropdownMenu>

            {isEditModalOpen && (
                <DashboardVariableCreateEditModal
                    existingVariable={variable}
                    onClose={() => setIsEditModalOpen(false)}
                />
            )}

            {isDeleteModalOpen && (
                <ConfirmationPrompt
                    title={'Deleting variable'}
                    prompt='Are you sure you want to delete this variable?'
                    onClose={() => setIsDeleteModalOpen(false)}
                    onConfirm={() => handleDeleteVariable()}
                    confirmButtonText='Delete'
                    confirmButtonVariant='destructive'
                />
            )}
        </div>
    );
};
