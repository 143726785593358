import { TooltipButton } from '@/components/Button';
import { cn } from '@/lib/cn';
import { faMagnifyingGlassChart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { useTileContext } from 'contexts/TileContext';
import { useNavigateToExplore } from 'dashboard-engine/hooks/useNavigateToExplore';

export const ExploreButton = () => {
    const { config } = useTileContext();
    const { isGlobal } = useDataStreamWorkspaceContext();
    const { editing } = useDashboardContext();

    const navigateToExplore = useNavigateToExplore(config, isGlobal);

    return (
        <TooltipButton
            variant='tertiary'
            icon={<FontAwesomeIcon icon={faMagnifyingGlassChart} fixedWidth />}
            title='Explore data'
            onClick={() => navigateToExplore()}
            className={cn(!editing && 'hidden group-hover/tile:inline-flex')}
        />
    );
};
