import { cn } from '@/lib/cn';
import { faDown, faUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    DataStreamScalarConfig,
    findColumn,
    findColumns,
    FormattedStreamValue,
    formatValueAsColumn,
    getResolvedShapeConfig,
    getScalarFormattedValue,
    isNoData,
    required
} from '@squaredup/data-streams';
import { HealthState } from '@squaredup/monitoring';
import { TruncatedText } from 'components/TruncatedText';
import { healthStateTextColors } from 'constants/state';
import useFitText from 'lib/useFitText';
import { useMemo, useState } from 'react';
import { DataStreamVisualisation } from '../../types/Visualisation';

type ScalarDisplayProps = {
    text: string;
    comparison?: {
        value: number;
        formatted: string;
    } | null;
    label?: string;
    labelPosition?: DataStreamScalarConfig['labelPosition'];
    health?: HealthState;
    comparisonShowsHealth?: boolean;
    manualSize?: number;
};

const ScalarDisplay = ({
    text,
    label,
    comparison,
    comparisonShowsHealth,
    labelPosition,
    health,
    manualSize
}: ScalarDisplayProps) => {
    const [visible, setVisible] = useState(false);

    // Use max font size 2000 for a maximum of 20x (2000%) scaling
    const { fontSize, ref } = useFitText({
        maxFontSize: 2000,
        onFinish: () => {
            setVisible(true);
        }
    });

    const position = labelPosition || 'bottom';
    const healthColor = typeof health === 'string' && healthStateTextColors[health];

    return (
        <div
            className={cn(
                'invisible text-center font-inter font-semibold size-full grid place-items-center whitespace-nowrap overflow-hidden',
                !comparison && healthColor,
                visible && 'visible'
            )}
        >
            <div
                className={cn('flex justify-center items-center gap-[5%] size-[90%]', {
                    'flex-row': position === 'right',
                    'flex-row-reverse': position === 'left',
                    'flex-col': position === 'bottom',
                    'flex-col-reverse': position === 'top'
                })}
                ref={ref}
                style={{
                    fontSize: manualSize ? `${manualSize}px` : fontSize
                }}
            >
                <div className='flex justify-center items-center'>
                    <span
                        className={cn(!comparisonShowsHealth && healthColor, {
                            'mr-[6%]': Boolean(comparison)
                        })}
                    >
                        {text}
                    </span>
                    {comparison && (
                        <>
                            {/* These elements are siblings so margins can be calculated based on the parent width */}
                            {comparison.value !== 0 && (
                                <span
                                    className={cn(
                                        'flex justify-center text-textSecondary text-[50%] mr-[3%]',
                                        comparisonShowsHealth && healthColor
                                    )}
                                >
                                    <FontAwesomeIcon
                                        icon={comparison.value < 0 ? faDown : faUp}
                                        className='text-[50%]'
                                    />
                                </span>
                            )}

                            <span className={cn('text-textSecondary text-[50%]', comparisonShowsHealth && healthColor)}>
                                {comparison.formatted}
                            </span>
                        </>
                    )}
                </div>

                {label && (
                    <div
                        style={{ fontSize: 'max(25%, 12.5px)' }}
                        className={cn('font-sans', {
                            'w-full': !manualSize && (position === 'top' || position === 'bottom'),
                            'max-w-[50%]': position === 'left' || position === 'right',
                            'max-w-full': position === 'top' || position === 'bottom'
                        })}
                    >
                        <TruncatedText className='truncate text-textSecondary' title={label}>
                            {label}
                        </TruncatedText>
                    </div>
                )}
            </div>
        </div>
    );
};

const getDisplayableValue = (value: FormattedStreamValue, formatted: boolean): string | null => {
    return formatted ? value.formatted : value.raw?.toLocaleString() ?? null;
};

const DataStreamScalar: DataStreamVisualisation<DataStreamScalarConfig> = ({
    data,
    config,
    monitorConfig,
    healthState,
    onClick
}) => {
    const value = useMemo(() => {
        if (isNoData(data)) {
            return null;
        }

        const { value: scalarValue } = getScalarFormattedValue(data, config?.value, config?.comparisonColumn);
        const formatted = config.formatted ?? true;

        if (!scalarValue) {
            return null;
        }

        if (Array.isArray(scalarValue)) {
            return getDisplayableValue(
                getScalarFormattedValue(data, { type: 'count' as const }).value as FormattedStreamValue,
                formatted
            );
        }

        return getDisplayableValue(scalarValue, formatted);
    }, [config, data]);

    const comparisonColumn = useMemo(() => {
        if (isNoData(data)) {
            return null;
        }
        const comparisonColumns = findColumns(data.metadata.columns, required('role', 'comparison')).getValue([]);

        if (!config.comparisonColumn && comparisonColumns.length === 0) {
            return null;
        }

        // If the user hasn't manually selected a comparison column, default to the first available comparison column
        const foundComparisonColumn = config.comparisonColumn
            ? findColumn(data.metadata.columns, required('name', config.comparisonColumn)).getValue(null)
            : comparisonColumns[0];

        if (!foundComparisonColumn) {
            return null;
        }

        return foundComparisonColumn;
    }, [config, data]);

    const comparisonValue = useMemo(() => {
        if (isNoData(data) || !comparisonColumn) {
            return null;
        }

        // We know a comparison value will be a number
        const valueToShow = data.rows[0]?.[comparisonColumn.dataIndex].value as number;
        const columns = data.metadata.columns;
        const rows = data.rows;
        const formattingContext = { columns, rows, formatSpec: {} };
        const resolvedShapeConfig = getResolvedShapeConfig(comparisonColumn.column.rawShapeConfig, formattingContext);

        if (resolvedShapeConfig.failed) {
            return null;
        }

        // The formatted comparison value will include a minus sign if negative, so
        // format the comparison value as an absolute number, as we will be displaying positive/negative
        // with an up/down arrow instead of with a minus sign withing the formatted number
        const formatted = formatValueAsColumn(
            { column: comparisonColumn.column, value: Math.abs(valueToShow) },
            { ...formattingContext, resolvedShapeConfig: resolvedShapeConfig.value }
        );
        return {
            value: valueToShow,
            formatted: formatted
        };
    }, [comparisonColumn, data]);

    const comparisonShowsHealth =
        monitorConfig?.monitorType === 'threshold' && monitorConfig.column === comparisonColumn?.column.name;

    return (
        <div
            className={cn('w-full h-full leading-tight', onClick && 'cursor-pointer')}
            data-visualization='data-stream-scalar'
            onClick={onClick}
        >
            <ScalarDisplay
                text={value ?? '0'}
                comparison={comparisonValue}
                label={config?.label}
                labelPosition={config?.labelPosition}
                health={healthState}
                comparisonShowsHealth={comparisonShowsHealth}
                manualSize={config?.manualSize}
            />
        </div>
    );
};

DataStreamScalar.config = 'DataStreamScalarConfig';

export default DataStreamScalar;
