import { useGroups } from './useGroups';

export const useGroupsForACLEditor = () => {
    return useGroups({
        // Groups change very rarely, so always use old cached data to keep the UI snappy ...
        staleTime: Number.POSITIVE_INFINITY,
        cacheTime: Number.POSITIVE_INFINITY,
        // ... but refetch when someone looks at it, to asynchronously pull in any updates
        refetchOnMount: 'always'
    });
};
