import { findColumn, FormattedStreamValue, required, StreamDataColumn } from '@squaredup/data-streams';

/**
 * Get the drilldown url for a given source and plugin config
 *
 * @example
 * getDrilldownUrlFor('sourceId', 'pluginConfigId')
 * returns '/drilldown/node?sourceId=sourceId&__configId=pluginConfigId'
 */
export const getDrilldownUrlFor = (sourceId: string, pluginConfigId: string) =>
    `/drilldown/node?sourceId=${encodeURIComponent(sourceId)}&__configId=${encodeURIComponent(pluginConfigId)}`;

/**
 * Get the first value if all values are the same, otherwise undefined
 */
export const getValueIfAllSame = <T>(values: (T | undefined)[]): T | undefined =>
    values.filter((i) => i).reduce((value, curr) => (curr === value ? value : undefined), values[0]);

/**
 * Get source ids for a a given column.
 * Finds the source id column attached to the given column and returns the source id for each datastream row.
 */
export const getSourceIdsForColumn = (
    allColumns: StreamDataColumn[],
    column: StreamDataColumn,
    rows: FormattedStreamValue[][]
): (string | undefined)[] => {
    const sourceIdColumnName = column.sourceIdColumn;

    if (sourceIdColumnName == null) {
        return rows.map(() => undefined);
    }

    const sourceIdColumnIndexResult = findColumn(allColumns, required('name', sourceIdColumnName)).map(
        (c) => c.dataIndex
    );

    if (sourceIdColumnIndexResult.failed) {
        return rows.map(() => undefined);
    }

    return rows.map((r) => r[sourceIdColumnIndexResult.value]?.value as string | undefined);
};
