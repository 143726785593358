import { Switch } from '@/components/Switch';
import { Toggle } from '@/components/forms/Toggle';
import { cn } from '@/lib/cn';
import { faCircleQuestion, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSize from '@react-hook/size';
import * as Constants from '@squaredup/constants';
import {
    VisualisationHint,
    findColumn,
    number,
    required,
    state,
    type DataStreamBaseTileConfig,
    type StreamDataColumn
} from '@squaredup/data-streams';
import {
    GROUP_BY_NONE,
    MonitorCondition,
    MonitorFrequencyPresets,
    MonitorLogicConfig,
    getAllowedMonitorFrequency
} from '@squaredup/monitoring';
import { getFeatureLimit, isFeatureEnabled } from '@squaredup/tenants';
import { TimeframeEnumValue, defaultTimeframeEnum } from '@squaredup/timeframes';
import clsx from 'clsx';
import LoadingSpinner from 'components/LoadingSpinner';
import { Button } from 'components/button';
import Autocomplete from 'components/forms/autocomplete/Autocomplete';
import Input from 'components/forms/input/Input';
import Json from 'components/forms/json/Json';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';
import { useShowUpgradeModal } from 'components/plans/common';
import Tooltip from 'components/tooltip/Tooltip';
import { getScalarValueColumn, getStateColumn } from 'dashboard-engine/dataStreams/VisualisationMatching';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { getBarChartColumns } from 'dashboard-engine/visualisations/DataStreamBarChart/dataUtils';
import { findDonutLabelColumn } from 'dashboard-engine/visualisations/DataStreamDonut/dataUtils';
import { findLineGraphLabelColumn } from 'dashboard-engine/visualisations/DataStreamLineGraph/LineGraphColumns';
import { formatDuration, intervalToDuration } from 'date-fns';
import type { Config } from 'dynamo-wrapper';
import deepEqual from 'fast-deep-equal/es6';
import { capitalize } from 'lodash';
import { useTenant } from 'queries/hooks/useTenant';
import { useTier } from 'queries/hooks/useTier';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { IsTenantAdmin } from 'services/AccessControlService';
import { List, SCRIPTS } from 'services/ScriptService';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import DataStreamTileEditorScriptModal from '../scripts/DataStreamTileEditorScriptModal';
import { DataStreamTileEditorMonitoringExpandable } from './DataStreamTileEditorMonitoringExpandable';
import { MonitorTypes } from './constants';
import { sanitiseFrequency } from './sanitiseFrequency';

type MonitorType = (typeof monitorTypes)[keyof typeof monitorTypes];

const monitorTypes = {
    THRESHOLD: 'threshold',
    STATE: 'state',
    SCRIPT: 'script'
} as const;

const aggregations = {
    TOP: 'top',
    SUM: 'sum',
    AVERAGE: 'mean',
    COUNT: 'count'
};

const operatorOptions = [
    { label: 'greater than', value: '>' },
    { label: 'less than', value: '<' }
];

const monitorConfigDefaults = {
    frequency: 5,
    warningOperator: '>',
    errorOperator: '>',
    warningVal: '0',
    errorVal: '0'
};

const monitorSwitchOptions = [
    {
        value: monitorTypes.STATE,
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <span>State</span>
            </span>
        )
    },
    {
        value: monitorTypes.THRESHOLD,
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <span>Threshold</span>
            </span>
        )
    },
    {
        value: monitorTypes.SCRIPT,
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <span>Script</span>
            </span>
        )
    }
];

const columnTooltipState =
    'Select which column to monitor, it must be specified as a State column using the Columns tab.';
const columnTooltipOther = 'Select which column to aggregate using the above value aggregation.';

export const FieldTitle: React.FC<{ className?: string }> = ({ children, className }) => (
    <p className={clsx('flex space-x-2 items-baseline shrink-0 whitespace-nowrap', className)}>{children}</p>
);

const EditorSelect = ({ className = '', ...props }) => (
    <Autocomplete
        name=''
        className={cn(
            'ring-1 ring-inset ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input bg-componentBackgroundPrimary',
            className
        )}
        {...props}
        isMulti={false}
        selectOptionsAs='valueString'
        menuShouldScrollIntoView
    />
);

// There has to be a nicer way to get the values back from a jsonLogic object. This feels horrible
const getMonitorConditionProperties = (logic: MonitorLogicConfig) => {
    const [firstCondition, firstConditionState, ...secondConditionWithState] = logic;
    const firstConditionOperator = Object.keys(firstCondition || {})?.[0];
    const [firstConditionAggregation, firstConditionVal] = Object.values(firstCondition || {})?.[0] || [];

    const [secondCondition, secondConditionState] = secondConditionWithState || [];
    const secondConditionOperator = Object.keys(secondCondition || {})?.[0] || null;
    const [, secondConditionVal] = Object.values(secondCondition || {})?.[0] || [];

    return {
        ...(firstConditionVal !== null && {
            aggregation: firstConditionAggregation.var,
            [`${firstConditionState}Operator`]: firstConditionOperator,
            [`${firstConditionState}Val`]: firstConditionVal
        }),
        ...(secondCondition !== null && {
            [`${secondConditionState}Operator`]: secondConditionOperator,
            [`${secondConditionState}Val`]: secondConditionVal
        })
    };
};

function getInitialMonitorType(condition?: Partial<MonitorCondition>) {
    if (condition?.logic) {
        return monitorTypes.THRESHOLD;
    }
    if (condition?.script) {
        return monitorTypes.SCRIPT;
    }
    return monitorTypes.STATE;
}

function getMonitorScriptProperties(condition?: Partial<MonitorCondition>) {
    return {
        script: condition?.script || undefined,
        scriptConfig: condition?.config
    };
}

function getMonitorScriptOptions(scripts?: Config[]) {
    const subType = Constants.getSubTypeForScriptType(Constants.scriptTypes.monitorConditionJS.value);
    return scripts?.filter((s) => s.subType === subType).map((s) => ({ label: s.displayName, value: s.id }));
}

function getValidValueColumns(columns: StreamDataColumn[], monitorType: MonitorType): StreamDataColumn[] {
    if (monitorType === monitorTypes.STATE) {
        return columns.filter((c) => c.shapeName === state.name);
    } else if (monitorType === monitorTypes.THRESHOLD) {
        return columns.filter((c) => c.valueShapeName === number.name);
    }

    return columns;
}

function getFallbackColumn(columns: StreamDataColumn[], monitorType: MonitorType): string | undefined {
    return getValidValueColumns(columns, monitorType)[0]?.name;
}

function getMonitorFormDefaults(
    config: DataStreamBaseTileConfig['monitor'],
    timeframe: TimeframeEnumValue,
    configuredType: string,
    stateColumn: StreamDataColumn | undefined,
    valueColumn: StreamDataColumn | undefined,
    visualisation: DataStreamBaseTileConfig['visualisation'],
    columnFallback: string | undefined,
    monitorFrequencyLimit: number
) {
    const isStateMonitorByDefault = Boolean(stateColumn?.name && visualisation?.type === 'data-stream-blocks');
    const isTableVisualisation = visualisation?.type === 'data-stream-table';
    const frequency = sanitiseFrequency(timeframe, monitorFrequencyLimit, config?.frequency);

    if (config?.condition) {
        return {
            monitorType: configuredType || monitorTypes.THRESHOLD,
            groupBy: config?.groupBy || GROUP_BY_NONE,
            column: config?.condition?.columns?.[0],
            aggregation: config?.aggregation || aggregations.TOP,
            ...monitorConfigDefaults,
            frequency: frequency.toString(),
            ...(configuredType === monitorTypes.SCRIPT &&
                getMonitorScriptProperties(config?.condition as MonitorCondition)),
            ...(config?.condition?.logic?.if?.length &&
                getMonitorConditionProperties(config?.condition.logic.if as MonitorLogicConfig))
        };
    }

    return {
        monitorType: isStateMonitorByDefault ? monitorTypes.STATE : monitorTypes.THRESHOLD,
        groupBy: config?.groupBy,
        column: isStateMonitorByDefault ? stateColumn!.name : valueColumn?.name ?? columnFallback,
        ...monitorConfigDefaults,
        frequency: frequency.toString(),
        ...(!isStateMonitorByDefault && { aggregation: isTableVisualisation ? aggregations.COUNT : aggregations.TOP })
    };
}

const allMonitorFrequencyOptions = Object.values(MonitorFrequencyPresets).map((presetMins) => ({
    // Convert minutes to user-friendly name, e.g. 360 mins to '6 hours'
    label: formatDuration(intervalToDuration({ start: 0, end: presetMins * 60 * 1000 })),
    value: presetMins
}));

/**
 * Adjust the monitoring frequency if required - ensures the frequency is allowed for the timeframe
 * and matches one of our presets.
 */

const isMonitorFrequencyOptionAllowed = (option: { value: number }, timeframe: TimeframeEnumValue) => {
    const allowedMonitorFrequency = getAllowedMonitorFrequency(timeframe);
    return option.value >= allowedMonitorFrequency.minimumMinutes;
};

const MonitorConditionControl = ({
    condition,
    conditionLabel,
    conditionOperatorField,
    conditionValueField,
    conditionEnabled,
    setConditionEnabled
}: {
    condition: string;
    conditionLabel: string;
    conditionOperatorField: string;
    conditionValueField: string;
    conditionEnabled?: boolean;
    setConditionEnabled: (checked: boolean) => void;
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth] = useSize(containerRef);
    return (
        <div ref={containerRef} className='space-y-4' data-panel={condition}>
            <div className='flex space-x-2 max-w-[110px] w-full justify-between items-center'>
                <p className='text-sm whitespace-nowrap'>{capitalize(condition)}</p>
                <Toggle
                    color={condition}
                    name={condition}
                    checked={conditionEnabled}
                    onCheckedChange={setConditionEnabled}
                />
            </div>

            <div
                className={clsx('flex gap-4', {
                    hidden: !conditionEnabled,
                    'flex-row': containerWidth > 250,
                    'flex-col': containerWidth <= 250
                })}
            >
                <EditorSelect name={conditionOperatorField} options={operatorOptions} isClearable={false} />
                <Input name={conditionValueField} label={`${conditionLabel} value`} type='number' placeholder='0' />
            </div>
        </div>
    );
};

export const DataStreamTileEditorMonitoringForm = () => {
    const { tileConfig, setTileConfig } = useTileEditorContext();
    const { data, isLoading: isLoadingDataStream } = useDataStreamConfig(tileConfig);
    const visualisation = tileConfig?.visualisation;
    const timeframe = tileConfig?.timeframe ?? defaultTimeframeEnum;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data: tenant } = useTenant();
    const userIsTenantAdmin = IsTenantAdmin(tenant);
    const { data: tier } = useTier();
    const isUpgradePending = tenant?.licenceData?.upgradePending ?? false;
    const monitoringIntervalLimit = tier
        ? getFeatureLimit(tier, 'monitorFrequency', Constants.monitorFrequencyDefaultMinutes)
        : { value: Constants.monitorFrequencyDefaultMinutes };
    const monitoringIntervalLimitMins =
        'isUnlimited' in monitoringIntervalLimit && monitoringIntervalLimit.isUnlimited
            ? 0
            : 'value' in monitoringIntervalLimit
            ? monitoringIntervalLimit.value
            : Constants.monitorFrequencyDefaultMinutes;
    const { openUpgradeModal, upgradeModal } = useShowUpgradeModal(
        tenant,
        'monitorFrequency',
        monitoringIntervalLimitMins
    );
    const isScriptMonitorAvailable = tier ? isFeatureEnabled(tier, 'scriptMonitors') : false;

    const config = useMemo(() => {
        return {
            ...tileConfig?.monitor,
            monitorType: tileConfig?.monitor?.monitorType,
            _type: MonitorTypes.simple,
            frequency: sanitiseFrequency(timeframe, monitoringIntervalLimitMins, tileConfig?.monitor?.frequency)
        };
    }, [tileConfig?.monitor, timeframe, monitoringIntervalLimitMins]);

    const [isFirstConfiguration] = useState(!config?.condition);
    const [configuredColumn] = useState(config?.condition?.columns?.[0]);
    const [configuredType] = useState(
        (config?.monitorType as MonitorType) || getInitialMonitorType(config?.condition as MonitorCondition)
    );

    const [columns, stateColumn, valueColumn, numericValueColumn] = useMemo(() => {
        let stateCol, valueCol, numericValueCol;

        let { columns: cols } = data.metadata;

        const scalarComparisonColumn = visualisation?.config?.['data-stream-scalar']?.comparisonColumn as string;

        const comparisonColumns = cols.filter((c) => c.role === 'comparison');

        // If a comparison column is set, use that as the monitoring value
        const defaultComparisonColumn =
            comparisonColumns.find((c) => c.name === scalarComparisonColumn) ?? comparisonColumns[0];

        const stateColumnQuery = getStateColumn(cols);
        const valueColumnQuery = defaultComparisonColumn
            ? findColumn(cols, required('name', defaultComparisonColumn.name))
            : getScalarValueColumn(cols);

        if (stateColumnQuery.succeeded) {
            stateCol = stateColumnQuery.value.column;
        }

        if (valueColumnQuery.succeeded) {
            valueCol = valueColumnQuery.value.column;

            if (valueColumnQuery.value.column.valueShapeName === number.name) {
                numericValueCol = valueColumnQuery.value.column;
            }
        }

        return [cols, stateCol, valueCol, numericValueCol];
    }, [data.metadata, visualisation?.config]);

    const monitoringAdvancedOptionsDiv = useRef(null);
    const { isLoading, data: scripts } = useQuery<Config[]>(SCRIPTS, List);

    const [errorEnabled, setErrorEnabled] = useState(
        config?.condition ? config.condition.logic?.if?.includes('error') : true
    );
    const [warningEnabled, setWarningEnabled] = useState(
        config?.condition ? config.condition.logic?.if?.includes('warning') : false
    );

    const monitorFrequencies = allMonitorFrequencyOptions
        .filter((option) => isMonitorFrequencyOptionAllowed(option, timeframe))
        .map(({ value, label }) => ({
            isDisabled: value < monitoringIntervalLimitMins,
            value: value.toString(),
            labelText: label,
            label: (
                <>
                    {label}
                    {value < monitoringIntervalLimitMins && (
                        <>
                            {' '}
                            -{' '}
                            {isUpgradePending ? (
                                'Upgrading...'
                            ) : userIsTenantAdmin ? (
                                <Button
                                    type='button'
                                    variant='link'
                                    onClick={openUpgradeModal}
                                    className='pointer-events-auto' // required as the option will be 'disabled'
                                    data-testid='upgrade-monitor-frequency'
                                >
                                    Upgrade
                                </Button>
                            ) : (
                                'Upgrade required'
                            )}
                        </>
                    )}
                </>
            )
        }));

    const isStateMonitorByDefault = Boolean(stateColumn?.name && visualisation?.type === 'data-stream-blocks');

    const columnFallback = getFallbackColumn(columns, isStateMonitorByDefault ? 'state' : 'threshold');

    const defaultValues = getMonitorFormDefaults(
        config,
        timeframe,
        configuredType as string,
        stateColumn,
        valueColumn,
        visualisation,
        columnFallback,
        monitoringIntervalLimitMins
    );

    const [defaultMonitorType] = useState(defaultValues.monitorType);

    // Watch specific fields for conditional rendering
    const formProps = useForm({ mode: 'onChange', defaultValues, shouldUnregister: true });
    const { watch, setValue, resetField } = formProps;

    const monitoredFields = watch();
    const watchMonitorType = watch('monitorType', defaultMonitorType) as MonitorType;

    const selectMonitorScript = (id: string) => {
        setValue('script', id);
    };

    // Ensure the frequency is within the allowed range when changing timeframe
    useEffect(() => {
        setValue(
            'frequency',
            sanitiseFrequency(timeframe, monitoringIntervalLimitMins, parseInt(monitoredFields?.frequency)).toString()
        );
    }, [timeframe, setValue, monitoringIntervalLimitMins, monitoredFields?.frequency]);

    // Reset column if the metadata changes and column is no longer available
    useEffect(() => {
        if (
            monitoredFields.column &&
            !isLoadingDataStream &&
            !columns.map((c) => c.name).includes(monitoredFields.column)
        ) {
            if (stateColumn) {
                setValue('column', stateColumn.name);
            } else {
                resetField('column', { defaultValue: null });
            }
        }
    }, [columns, monitoredFields.column, resetField, setValue, stateColumn, isLoadingDataStream]);

    // Set default grouping.
    useEffect(() => {
        if (
            isFirstConfiguration &&
            !config?.groupBy &&
            isGroupingSupportedForMonitorType(monitoredFields?.monitorType)
        ) {
            const defaultGroupBy = getDefaultGroupBy(visualisation, data?.metadata?.columns) || GROUP_BY_NONE;
            setValue('groupBy', defaultGroupBy);
        }
    }, [
        isFirstConfiguration,
        data?.metadata?.columns,
        setValue,
        visualisation,
        config?.groupBy,
        monitoredFields?.monitorType
    ]);

    // When switching between monitor types set the appropriate column value if one hasn't been set for that
    // monitor type before
    useEffect(() => {
        if (monitoredFields.aggregation === 'count') {
            return;
        }

        if (monitoredFields?.monitorType === monitorTypes.STATE) {
            const value = isFirstConfiguration
                ? stateColumn?.name ?? getFallbackColumn(columns, monitoredFields.monitorType)
                : configuredType === monitorTypes.STATE
                ? configuredColumn
                : stateColumn?.name ?? getFallbackColumn(columns, monitoredFields.monitorType);

            if (value) {
                setValue('column', value);
            } else {
                resetField('column', { defaultValue: null }); // Reset the field if no available column
            }
        } else if (monitoredFields?.monitorType === monitorTypes.THRESHOLD) {
            const columnFieldValue = isFirstConfiguration
                ? numericValueColumn?.name ?? getFallbackColumn(columns, monitoredFields.monitorType) ?? null // null to clear the field
                : configuredColumn;

            setValue('column', columnFieldValue as string | undefined);
        } else if (monitoredFields?.monitorType === monitorTypes.SCRIPT) {
            setValue('column', undefined);
        }
    }, [
        stateColumn?.name,
        valueColumn?.name,
        monitoredFields.monitorType,
        monitoredFields.aggregation,
        configuredColumn,
        configuredType,
        isFirstConfiguration,
        setValue,
        resetField,
        columns,
        numericValueColumn?.name
    ]);

    // Watch for form changes and update monitor config
    useEffect(() => {
        const {
            aggregation,
            column,
            script,
            scriptConfig,
            frequency,
            errorOperator,
            warningOperator,
            errorVal,
            warningVal,
            groupBy
        } = monitoredFields;

        // We need to ensure the conditions for error/warning states are correctly set in a THRESHOLD monitor
        // before we commit the changes
        const validMonitorConditions =
            (watchMonitorType && watchMonitorType !== monitorTypes.THRESHOLD) ||
            (watchMonitorType === monitorTypes.THRESHOLD && (errorEnabled || warningEnabled));

        const updatedMonitorConfig = {
            _type: MonitorTypes.simple,
            tileRollsUp: true,
            monitorType: watchMonitorType,
            frequency: sanitiseFrequency(timeframe, monitoringIntervalLimitMins, parseInt(frequency)),
            aggregation,
            column: column,
            groupBy: isGroupingSupportedForMonitorType(watchMonitorType) ? groupBy : undefined,
            condition: {
                ...((column || aggregation === aggregations.COUNT) && {
                    columns: aggregation === aggregations.COUNT ? [] : [column!]
                }),
                ...(watchMonitorType === monitorTypes.THRESHOLD &&
                    (errorEnabled || warningEnabled) && {
                        logic: {
                            if: [
                                ...(errorEnabled && errorVal !== undefined
                                    ? [
                                          {
                                              [errorOperator]: [{ var: aggregation }, parseFloat(errorVal)]
                                          },
                                          'error'
                                      ]
                                    : []),
                                ...(warningEnabled && warningVal !== undefined
                                    ? [
                                          {
                                              [warningOperator]: [{ var: aggregation }, parseFloat(warningVal)]
                                          },
                                          'warning'
                                      ]
                                    : [])
                            ] as MonitorLogicConfig
                        }
                    }),
                ...(watchMonitorType === monitorTypes.SCRIPT &&
                    script && {
                        script,
                        config: scriptConfig
                    })
            }
        };

        // Quick and dirty diff of monitor config. We only want to update when something has changed
        if (validMonitorConditions && !deepEqual(config, updatedMonitorConfig)) {
            setTileConfig({
                ...tileConfig,
                monitor: {
                    ...updatedMonitorConfig,
                    _type: MonitorTypes.simple
                }
            });
        }
    }, [
        monitoredFields,
        errorEnabled,
        warningEnabled,
        watchMonitorType,
        config,
        tileConfig,
        setTileConfig,
        timeframe,
        monitoringIntervalLimitMins
    ]);

    return (
        <FormProvider {...formProps}>
            <form className='text-sm'>
                <div className='items-center w-full mb-4'>
                    <div className='flex items-center justify-between mb-4'>
                        <p className='w-14 shrink-0'>Type</p>
                        <a
                            href='https://squaredup.com/cloud/monitoring'
                            target='_blank'
                            rel='noreferrer'
                            className='text-textLink'
                        >
                            Learn more
                        </a>
                    </div>
                    <div>
                        <Switch
                            className='w-full'
                            itemClassName='w-full justify-center'
                            value={watchMonitorType}
                            options={monitorSwitchOptions}
                            onValueChange={(selectedType) => {
                                setValue('monitorType', selectedType);
                            }}
                        />
                    </div>
                </div>

                {watchMonitorType === monitorTypes.THRESHOLD && (
                    <div className='flex flex-col w-full mb-4 space-y-2 items-left'>
                        <FieldTitle className='mb-1.5'>
                            <span>Value</span>
                            <Tooltip title='Select which value to monitor, for example the average of all data or just the top value.'>
                                <FontAwesomeIcon className='text-textSecondary' icon={faCircleQuestion} />
                            </Tooltip>
                        </FieldTitle>

                        <EditorSelect
                            name='aggregation'
                            isClearable={false}
                            options={Object.entries(aggregations).map(([, val]) => ({
                                label: capitalize(val),
                                value: val
                            }))}
                        />
                    </div>
                )}

                {watchMonitorType !== monitorTypes.SCRIPT && monitoredFields.aggregation !== aggregations.COUNT && (
                    <div className='flex flex-col w-full space-y-2 items-left'>
                        <FieldTitle>
                            <span className='mb-1.5'>Column</span>
                            <Tooltip
                                title={
                                    watchMonitorType === monitorTypes.STATE ? columnTooltipState : columnTooltipOther
                                }
                            >
                                <FontAwesomeIcon className='text-textSecondary' icon={faCircleQuestion} />
                            </Tooltip>
                        </FieldTitle>
                        <EditorSelect
                            name='column'
                            className='mb-4'
                            isClearable={false}
                            noOptionsMessage={() =>
                                watchMonitorType === monitorTypes.STATE
                                    ? 'No state columns available'
                                    : watchMonitorType === monitorTypes.THRESHOLD
                                    ? 'No numeric columns available'
                                    : 'No columns available'
                            }
                            placeholder='Select column'
                            validation={{ required: true }}
                            options={getValidValueColumns(columns, watchMonitorType).map((col) => ({
                                label: col.displayName,
                                value: col.name
                            }))}
                            aria-label='Column'
                        />
                    </div>
                )}

                {watchMonitorType === monitorTypes.SCRIPT && (
                    <FeatureUnavailableBanner
                        featureKey='scriptMonitors'
                        container='modal'
                        content='narrow'
                        title='Script evaluation'
                        className='mb-4'
                    />
                )}

                {isGroupingSupportedForMonitorType(watchMonitorType) && (
                    <div
                        className={cn(
                            'flex flex-col w-full space-y-2 items-left',
                            watchMonitorType !== monitorTypes.SCRIPT && 'border-b-[1px] border-b-dividerPrimary'
                        )}
                    >
                        <FieldTitle>
                            <span className='mb-1.5'>Evaluate by</span>
                            <Tooltip title='Calculate a health state for each unique value in the selected column.'>
                                <FontAwesomeIcon className='text-textSecondary' icon={faCircleQuestion} />
                            </Tooltip>
                        </FieldTitle>
                        <EditorSelect
                            name='groupBy'
                            className={cn('mb-4', watchMonitorType === monitorTypes.SCRIPT && 'mb-0')}
                            isClearable={false}
                            placeholder='Select column'
                            validation={{ required: true }}
                            isDisabled={watchMonitorType === monitorTypes.SCRIPT && !isScriptMonitorAvailable}
                            options={[
                                {
                                    label: 'None',
                                    value: GROUP_BY_NONE
                                },
                                ...columns.map((col) => ({ label: col.displayName, value: col.name }))
                            ]}
                        />
                    </div>
                )}

                {watchMonitorType === monitorTypes.SCRIPT &&
                    (!isLoading ? (
                        <div className='flex flex-col w-full my-4 items-left'>
                            <div className='flex items-center mb-2'>
                                <FieldTitle>Script</FieldTitle>
                                <span className='px-2 text-textSecondary'>
                                    <Tooltip title='The monitor condition script to run.'>
                                        <FontAwesomeIcon className='text-textSecondary' icon={faCircleQuestion} />
                                    </Tooltip>
                                </span>
                            </div>
                            <div className='flex space-x-3'>
                                <EditorSelect
                                    name='script'
                                    placeholder='Select a script to run'
                                    validation={{ required: true }}
                                    options={getMonitorScriptOptions(scripts) || []}
                                    isDisabled={!isScriptMonitorAvailable}
                                />
                                <Button
                                    className='text-base font-normal'
                                    variant='secondary'
                                    icon={<FontAwesomeIcon fontSize={16} icon={faPlus} />}
                                    onClick={() => setIsModalOpen(true)}
                                    disabled={!isScriptMonitorAvailable}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <LoadingSpinner />
                    ))}

                {watchMonitorType === monitorTypes.SCRIPT && (
                    <div className='flex flex-col items-start w-full'>
                        <div className='flex items-center mb-2'>
                            <FieldTitle>Config</FieldTitle>
                            <span className='px-2 ml-auto text-textSecondary'>
                                <Tooltip title='The optional params.config argument passed to the script.'>
                                    <FontAwesomeIcon className='text-textSecondary' icon={faCircleQuestion} />
                                </Tooltip>
                            </span>
                        </div>
                        <div className='flex-1 w-full min-h-0'>
                            <Json
                                name='scriptConfig'
                                placeholder='The optional params.config argument passed to the script'
                                validation={{ required: false }}
                                heightConstraints={{ min: 150, max: 1000 }}
                                readOnly={!isScriptMonitorAvailable}
                            />
                        </div>
                    </div>
                )}

                {watchMonitorType === monitorTypes.THRESHOLD &&
                    (monitoredFields.aggregation === aggregations.COUNT || monitoredFields.column) && (
                        <div className='flex flex-col border-b-[1px] border-b-dividerPrimary -space-x-6'>
                            <DataStreamTileEditorMonitoringExpandable
                                summary='Conditions'
                                initiallyOpen={true}
                                summaryStyles='pl-0 pr-2 font-semibold'
                                scrollIntoViewOnExpand={true}
                                summaryDataTestId='monitorConditionsExpander'
                            >
                                <div className='flex flex-col space-y-4'>
                                    <div>
                                        <MonitorConditionControl
                                            condition='error'
                                            conditionLabel='Error'
                                            conditionOperatorField='errorOperator'
                                            conditionValueField='errorVal'
                                            conditionEnabled={errorEnabled}
                                            setConditionEnabled={setErrorEnabled}
                                        />
                                    </div>

                                    <div>
                                        <MonitorConditionControl
                                            condition='warning'
                                            conditionLabel='Warning'
                                            conditionOperatorField='warningOperator'
                                            conditionValueField='warningVal'
                                            conditionEnabled={warningEnabled}
                                            setConditionEnabled={setWarningEnabled}
                                        />
                                    </div>
                                </div>
                            </DataStreamTileEditorMonitoringExpandable>
                        </div>
                    )}

                <div className='border-b-[1px] border-b-dividerPrimary'>
                    <DataStreamTileEditorMonitoringExpandable
                        summary='Advanced'
                        initiallyOpen={false}
                        summaryStyles='pl-0 pr-2 font-semibold'
                        scrollIntoViewOnExpand={true}
                        summaryDataTestId='monitorAdvancedOptionsExpander'
                    >
                        <div ref={monitoringAdvancedOptionsDiv} className='w-full'>
                            <p className='flex mb-2 mr-4 space-x-2 shrink-0 whitespace-nowrap'>
                                <span>Frequency</span>
                                <Tooltip
                                    title={
                                        <>
                                            <p>How often this monitor is re-evaluated.</p>
                                            <p>Available options are determined by the tile timeframe.</p>
                                        </>
                                    }
                                >
                                    <FontAwesomeIcon className='text-textSecondary' icon={faCircleQuestion} />
                                </Tooltip>
                            </p>
                            <EditorSelect
                                name='frequency'
                                options={monitorFrequencies}
                                isClearable={false}
                                isSearchable={false}
                                label='Frequency'
                                aria-label='Frequency'
                                data-testid='monitorFrequencyOptions'
                            />
                        </div>
                    </DataStreamTileEditorMonitoringExpandable>
                </div>
            </form>

            {isModalOpen && (
                <DataStreamTileEditorScriptModal
                    setIsModalOpen={setIsModalOpen}
                    selectMonitorScript={selectMonitorScript}
                />
            )}
            {upgradeModal}
        </FormProvider>
    );
};

/**
 * Determine a reasonable default 'evaluate by' using some heuristics based on the viz settings
 * and/or data stream columns.
 */
function getDefaultGroupBy(
    visualisation?: DataStreamBaseTileConfig['visualisation'],
    columns?: StreamDataColumn[]
): string | undefined {
    let groupBy: string | undefined;

    if (!columns) {
        return undefined;
    }

    if (visualisation) {
        // The viz config gives a good idea of what column might be good to group by.
        switch (visualisation.type) {
            case 'data-stream-line-graph':
                groupBy =
                    getVisColumnName(visualisation, 'data-stream-line-graph', 'seriesColumn', columns) ||
                    findLineGraphLabelColumn(columns).getValue(undefined)?.column?.name;
                break;
            case 'data-stream-donut-chart':
                groupBy =
                    getVisColumnName(visualisation, 'data-stream-donut-chart', 'labelColumn', columns) ||
                    findDonutLabelColumn(columns).getValue(undefined)?.column.name;
                break;
            case 'data-stream-bar-chart':
                {
                    const barChartConfig = visualisation.config?.['data-stream-bar-chart'];
                    const xAxisDataColumnName = barChartConfig?.xAxisData as string;
                    groupBy = isValidColumn(columns, xAxisDataColumnName) ? xAxisDataColumnName : undefined;
                    if (!groupBy) {
                        const {
                            value: { labelColumn }
                        } = getBarChartColumns(columns, barChartConfig);
                        groupBy = labelColumn?.column?.name;
                    }
                }
                break;
            default:
        }
    }

    if (!groupBy) {
        // If we didn't find anything good to group, just use a label column if there is one.
        groupBy = findColumn(columns, required('role', 'label')).getValue(undefined)?.column?.name;
    }

    return groupBy;
}

function getVisColumnName(
    visualisation: DataStreamBaseTileConfig['visualisation'] | undefined,
    visType: VisualisationHint | undefined,
    column: 'labelColumn' | 'seriesColumn',
    columns: StreamDataColumn[]
): string | undefined {
    const col = visType && (visualisation?.config?.[visType]?.[column] as string);
    return col && col !== 'auto' && col !== 'none' && isValidColumn(columns, col) ? col : undefined;
}

function isValidColumn(columns: StreamDataColumn[], columnName: string | undefined): boolean {
    return Boolean(columns?.find((col) => col.name === columnName));
}

function isGroupingSupportedForMonitorType(monitorType: string | undefined): boolean {
    return Boolean(monitorType && monitorType !== monitorTypes.STATE);
}
