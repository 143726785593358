import { SimpleTile } from 'dashboard-engine/basetiles/types';

const tile: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: 'script'
    },
    visualisation: {
        type: 'data-stream-table'
    }
};

export default tile;