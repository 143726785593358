import type { SimpleTileConfig } from '@squaredup/dashboards';
import { SimpleTile } from 'dashboard-engine/basetiles/types';
import { TextConfig } from 'dashboard-engine/visualisations/Text/Config';
import placeholder from '../datasources/misc/placeholder/placeholder';
import Text from '../visualisations/Text/Text';

export type TextTileConfig = SimpleTileConfig & {
    _type: 'tile/text',
    visualisation: {
        config: TextConfig
    }
}

const text: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: placeholder
    },
    transforms: [],
    visualisation: {
        type: Text
    }
};

export default text;
