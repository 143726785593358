import { SimpleTile } from 'dashboard-engine/basetiles/types';
import Text from '../visualisations/Text/Text';
import placeholder from '../datasources/misc/placeholder/placeholder';

const newTile: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: placeholder,
        config: {
            data: 'Choose a tile type'
        }
    },
    transforms: [],
    visualisation: {
        type: Text
    }
};

export default newTile;
