import * as Constants from '@squaredup/constants';
import { ConfigId, Serialised } from '@squaredup/ids';
import { withSuffix } from '@squaredup/utilities';
import { getDefaultValues } from 'components/forms/jsonForms/DisplayJsonUi';
import { ResolvedUIConfig, resolveFormFields } from 'components/forms/jsonForms/resolveAutocompleteOptions';
import { AgentGroupOptions } from 'components/hooks/useAgentGroups';
import type { Config, ProjectedPlugin } from 'dynamo-wrapper';
import { useDatasourceConfigsForAdministration } from 'queries/hooks/useDatasourceConfigsForAdministration';
import { useDataStreamDefinitions } from 'queries/hooks/useDataStreamDefinitions';
import { useQuery } from 'react-query';
import { GetConfig } from 'services/PluginService';
import { PluginConfigFormData } from '../types';
import { RECENT_NAME } from './useMutatePlugin';
import { usePlugin } from './usePlugin';

interface PluginDefaultValuesProps {
    config: (Serialised<Config<Omit<PluginConfigFormData, 'displayName'>>> & { json?: string }) | undefined;
    selectedPlugin: Serialised<ProjectedPlugin>;
    agentGroups: AgentGroupOptions;
}

const FORM_FIELDS = 'pluginform';

function getEditMode(config: Serialised<Config<Omit<PluginConfigFormData, 'displayName'>>> | undefined) {
    const globalConfigId = new ConfigId(Constants.GlobalConfigId).value;

    if (config) {
        if (config.isDraft && config.baseIdString === globalConfigId) {
            return 'newWithDraft';
        }
        return 'existing';
    }
    return 'new';
}

type PluginDefaultValues = {
    defaultValues: Record<string, any>;
    isIdle: boolean;
    isLoading: boolean;
    formFields?: ResolvedUIConfig[];
};

export const usePluginDefaultValues = ({
    config,
    selectedPlugin,
    agentGroups = []
}: PluginDefaultValuesProps): PluginDefaultValues => {
    const { data: streamDefinitions = [] } = useDataStreamDefinitions();

    const editMode = getEditMode(config);
    const existingValues: Record<string, unknown> =
        (editMode !== 'new' && {
            displayName: config?.displayName,
            id: config?.id,
            json: config?.json,
            agentGroupId: agentGroups.find((g) => g.value === `${config?.agentGroupId}`),
            ...config?.config
        }) ||
        {};

    const { isLoading: isLoadingExisting, data: existingPlugins = [] } = useDatasourceConfigsForAdministration();

    const { id: pluginId } = selectedPlugin ?? { id: '' };
    const { data: plugin, isLoading: isLoadingPlugin } = usePlugin(pluginId);

    const {
        data: formFields,
        isLoading: isLoadingFormFields,
        isIdle
    } = useQuery(
        [FORM_FIELDS, pluginId],
        async (): Promise<ResolvedUIConfig[] | undefined> => {
            const fields = await GetConfig(pluginId);

            return resolveFormFields(
                fields,
                existingValues,
                streamDefinitions,
                { scope: [], pluginConfigId: config?.id },
                (config?.plugin as { pluginId: string })?.pluginId
            )?.map((f) => ({
                helpAlign: 'Left',
                ...f
            }));
        },
        {
            enabled: Boolean(plugin)
        }
    );

    const recentNames = JSON.parse(sessionStorage.getItem(RECENT_NAME) ?? '[]');

    const defaultValues = {
        displayName: withSuffix(
            selectedPlugin.displayName ?? '',
            [...existingPlugins.map((s) => s.description), ...recentNames],
            { ignoreFirstItem: true }
        ),
        createDefaultContent: false,
        ...getDefaultValues(formFields, { includeHidden: true }),
        ...existingValues
    };

    const isLoading = isLoadingFormFields || isLoadingPlugin || isLoadingExisting;

    return {
        defaultValues,
        isIdle,
        isLoading,
        formFields
    };
};
