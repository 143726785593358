import { DataStreamScope } from '@squaredup/data-streams';
import stringify from 'fast-json-stable-stringify';

const SCOPES = 'scopes';
const OBJECTS = 'objects';
const DATASTREAM = 'datastream';

export const scopeQueryKeys = {
    all: [SCOPES],
    detail: (scopeId: string) => [SCOPES, scopeId],
    workspace: (workspaceId?: string | null) => [SCOPES, String(workspaceId)],
    objects: (workspaceId?: string | null, scopeId?: string) => [SCOPES, String(workspaceId), String(scopeId), OBJECTS],
    scopeContentToObjects: (scope?: Partial<DataStreamScope>, workspaceId?: string | null) => [
        SCOPES,
        DATASTREAM,
        stringify(scope),
        stringify(workspaceId)
    ]
};
