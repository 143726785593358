import type { QueryKey } from 'react-query';

const DATA_STREAM_DEFINITIONS = 'dataStreamDefinitions';

export const dataStreamDefinitionQueryKeys = {
    /**
     * The key for all data stream definitions.
     *
     * Invalidating this invalidates the complete list of data stream definitions,
     * including those for individual workspaces, with or without templates.
     *
     * It also invalidates the individual data stream definitions cached by Id,
     * though these will be repopulated from the workspace list once it's reloaded.
     */
    all: [DATA_STREAM_DEFINITIONS],
    /**
     * The key for all data stream definitions for a specific workspace.
     * @param workspaceId The workspace ID.
     * @param options Set to `{ includeTemplates: true }` to get templates as well.
     * Set to `undefined` to exclude templates or if you want to invalidate all data
     * cached for the workspace, whether it includes templates or not.
     */
    forWorkspace: (workspaceId: string, options: { includeTemplates: true | undefined }): QueryKey => [
        DATA_STREAM_DEFINITIONS,
        'byWorkspace',
        workspaceId,
        ...(options.includeTemplates ? [{ includeTemplates: true }] : [])
    ],
    /**
     * Only used for invalidation of all data stream definitions cached by workspace.
     */
    forAllWorkspaces: (): QueryKey => [DATA_STREAM_DEFINITIONS, 'byWorkspace'],
    /**
     * The key for a specific data stream definition.
     */
    byId: (dataStreamId: string): QueryKey => [DATA_STREAM_DEFINITIONS, 'byId', dataStreamId]
};
