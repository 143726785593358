import { type DataStreamBaseTileConfig, type DataStreamDefinitionId } from '@squaredup/data-streams';
import type { Dispatch, SetStateAction } from 'react';
import type { QueryClient } from 'react-query';
import type { DataStream } from 'services/DataStreamDefinitionService';

export type DataStreamId = DataStreamDefinitionId['value'];

export type TileEditorState =
    | {
          selectedDataStream?: undefined;
      }
    | {
          selectedDataStream: TileEditorDataStream;
      };

/**
 * Dependencies used to produce side-effects.
 */
export type TileEditorStateReducerExternals = {
    queryClient: QueryClient;
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
};

export type TileEditorStateInternal = TileEditorState & {
    /**
     * A function that performs any side-effects required as a result of an action.
     * This property can only be set in the reducer function and should not be executed
     * outside of `TileEditorStateProvider`.
     */
    sideEffects?: (externals: TileEditorStateReducerExternals) => void;
};

/**
 * A data stream which has been extended for use in the tile editor state reducer.
 */
export type TileEditorDataStream = DataStream & {
    /**
     * Is this data stream a preset of a configurable data stream?
     */
    isPreset: boolean;
    /**
     * The display name of this data stream, prepended with
     * the name of the data stream this stream is a preset of (if any).
     */
    displayNameFull: string;
};

/**
 * Create an initial tile editor state object based on the given config.
 */
export const createTileEditorState = ({
    dataStreams,
    config
}: {
    dataStreams: TileEditorDataStream[];
    config: DataStreamBaseTileConfig;
}): TileEditorState => {
    const selectedDataStream = dataStreams.find((ds) => ds.id === config?.dataStream?.id);

    if (selectedDataStream == null) {
        return {};
    }

    return { selectedDataStream };
};
