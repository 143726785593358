import { TooltipButton } from '@/components/Button';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTileContext } from 'contexts/TileContext';

export const EditButton = () => {
    const { onEdit } = useTileContext();

    return (
        <TooltipButton
            variant='tertiary'
            icon={<FontAwesomeIcon icon={faPencil} fixedWidth />}
            title='Edit'
            onClick={() => onEdit()}
            data-testid='tileEdit'
        />
    );
};
