import Column from 'dashboard-engine/layouts/Column';
import List from 'dashboard-engine/layouts/List';
import Grid from 'dashboard-engine/layouts/grid/Grid';

import { withSize } from 'react-sizeme';

const withSizeHOC = withSize({ monitorWidth: true });
const SizedGrid = withSizeHOC(Grid);

const layoutComponents = {
    list: List,
    grid: SizedGrid,
    column: Column
};

function RenderLayout({ config, isLoading = false }) {
    const component = config._type.split('/').pop();
    const LayoutComponent = layoutComponents[component];

    return <LayoutComponent config={config} isLoading={isLoading} />;
}

export default RenderLayout;
