const urlParams = new URLSearchParams(window.location.search);
const environment = window.SQUPRELEASEVARS?.Environment;
const isProd = ['Production', 'ProductionEU', 'ProductionUK'].includes(environment);
const isTestEnvironment = window.Cypress || false;

if ((isProd && !isTestEnvironment) || urlParams.get('analytics') === 'enabled') {
    !(function () {
        window.analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
            if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
            else {
                analytics.invoked = !0;
                analytics.methods = [
                    'trackSubmit',
                    'trackClick',
                    'trackLink',
                    'trackForm',
                    'pageview',
                    'identify',
                    'reset',
                    'group',
                    'track',
                    'ready',
                    'alias',
                    'debug',
                    'page',
                    'once',
                    'off',
                    'on',
                    'addSourceMiddleware',
                    'addIntegrationMiddleware',
                    'setAnonymousId',
                    'addDestinationMiddleware'
                ];
                analytics.factory = function (e) {
                    return function () {
                        var t = Array.prototype.slice.call(arguments);
                        t.unshift(e);
                        analytics.push(t);
                        return analytics;
                    };
                };
                for (var e = 0; e < analytics.methods.length; e++) {
                    var key = analytics.methods[e];
                    analytics[key] = analytics.factory(key);
                }
                analytics.load = function (key, e) {
                    var t = document.createElement('script');
                    t.type = 'text/javascript';
                    t.defer = true;
                    t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                    var n = document.getElementsByTagName('script')[0];
                    n.parentNode.insertBefore(t, n);
                    analytics._loadOptions = e;
                };
                analytics.SNIPPET_VERSION = '4.13.1';
                // cspell:disable-next-line
                let segmentWriteKey;
                if (environment === 'Production') {
                    segmentWriteKey = 'RafxRnbtj1W8FVICmSTHCArmDFxD4UJq';
                } else if (environment === 'ProductionEU') {
                    segmentWriteKey = 'AwgfHoygYX791S2999iwgte7Pi1dOG7b';
                } else if (environment === 'ProductionUK') {
                    segmentWriteKey = 'xKli2kQDSHPKsPDv1T8EaUjafNEcfB82';
                } else if (environment === 'Pre-Prod') {
                    segmentWriteKey = 'S9EHouIktnVJ45BwPydpmnTKZG6igZYL';
                } else {
                    segmentWriteKey = 'WINNECUucS60YSTGm0PKLpuXdFSGOBxT';
                }
                analytics.load(segmentWriteKey);
                analytics.page();
                const anonymousId = urlParams.get('anonymousId');
                anonymousId && analytics.setAnonymousId(anonymousId);
            }
    })();
}
