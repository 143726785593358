import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamScope } from '@squaredup/data-streams';
import { AgentGroupId, ConfigId, Serialised, SqUpPluginConfigId } from '@squaredup/ids';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import { getOptionValue } from 'components/forms/autocomplete/Autocomplete';
import { getAllFieldsByName } from 'components/forms/jsonForms/DisplayJsonUi';
import PluginContext from 'contexts/PluginContext';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import { TestConfigAPI } from 'pages/settings/plugins/PluginTest';
import { PLUGIN } from 'pages/settings/plugins/components/usePlugin';
import { PluginConfigFormData } from 'pages/settings/plugins/types';
import { useDataStreamDefinition } from 'queries/hooks/useDataStreamDefinition';
import { useDataStreamDefinitions } from 'queries/hooks/useDataStreamDefinitions';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Create, Update } from 'services/DataStreamDefinitionService';
import { Get as GetDetail, PLUGIN_DETAIL } from 'services/PluginService';
import { Get } from 'services/SourceConfigService';
import DataStreamDeleteModal from './DataStreamDeleteModal';
import { DataStreamTemplateForm } from './DataStreamTemplateForm';

interface DataStreamTemplateModalProps {
    sourceDataStreamId: string;
    scope: DataStreamScope | undefined;
    pluginConfigId: ConfigId['value'] | undefined;
    plugin: Serialised<ProjectedPlugin>;
    defaultDataSourceConfig?: Record<string, any>;
    dataStreamId?: string;
    setSelectedTemplate: (template: string) => void;
    onClose: (id?: any, deleted?: boolean) => void;
}

export function DataStreamTemplateModal({
    sourceDataStreamId,
    defaultDataSourceConfig,
    scope,
    pluginConfigId,
    plugin,
    dataStreamId = '',
    setSelectedTemplate,
    onClose
}: DataStreamTemplateModalProps) {
    const { data: streamDefinitions = [], isLoading: isDefinitionsLoading } = useDataStreamDefinitions();
    const { data: sourceDataStream, isLoading: isDataSourceLoading } = useDataStreamDefinition(sourceDataStreamId);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const isUpdating = Boolean(defaultDataSourceConfig);

    const formFields = sourceDataStream?.template;
    const formFieldsByName = getAllFieldsByName(formFields ?? []);

    const dataSourceConfig =
        defaultDataSourceConfig || (sourceDataStream?.definition.dataSourceConfig as Record<string, object>);

    const { data: pluginDetail, isLoading: isLoadingPluginDetail } = useQuery(
        [PLUGIN, plugin?.id],
        async () => GetDetail(plugin.id),
        { enabled: Boolean(plugin?.id) }
    );

    const { data: pluginConfig, isLoading: isLoadingPluginConfig } = useQuery(
        [PLUGIN_DETAIL, pluginConfigId],
        () => Get(pluginConfigId as string),
        {
            enabled: Boolean(pluginConfigId) && pluginConfigId !== SqUpPluginConfigId.value
        }
    );

    const createDataStream = (data: Record<string, any> & { displayName?: string }) => {
        if (sourceDataStream) {
            const streamDataSource = pluginDetail?.dataSources.find(s => s.name === sourceDataStream.dataSourceName);
            const properties = Object.keys(data).filter((p) => p !== 'displayName');
            properties.forEach((p: string) => {
                if (formFieldsByName.get(p)?.type === 'key-value') {
                    // Keep key-value objects verbatim
                    dataSourceConfig[p] = data[p];
                } else {
                    // don't save whole option objects into the data source config
                    dataSourceConfig[p] = getOptionValue(data[p]);
                }
            });

            const definition = {
                timeframes: sourceDataStream.definition.timeframes ??
                    streamDataSource?.timeframes ??
                    true,
                ...sourceDataStream.definition,
                dataSourceConfig,
                name: sourceDataStream.dataSourceName.toLowerCase()
            };

            return Create({
                definition,
                displayName: data.displayName ?? '',
                dataSourceName: sourceDataStream.dataSourceName,
                pluginId: sourceDataStream.pluginId,
                parentPluginVersion: '',
                sourceTemplateId: sourceDataStreamId
            }).then((id) => onClose(id));
        } else {
            onClose();
        }
    };

    const updateDataStream = (data: Record<string, any> & { displayName?: string }) => {
        if (sourceDataStream) {
            const streamDataSource = pluginDetail?.dataSources.find(s => s.name === sourceDataStream.dataSourceName);
            const updatedDataSourceConfig = { ...dataSourceConfig };
            const properties = Object.keys(data).filter((p) => p !== 'displayName');
            properties.forEach((p: string) => {
                if (formFieldsByName.get(p)?.type === 'key-value') {
                    // Keep key-value objects verbatim
                    updatedDataSourceConfig[p] = data[p];
                } else {
                    updatedDataSourceConfig[p] = getOptionValue(data[p]);
                }
            });

            const filteredConfig: Record<string, any> = {};

            for (let key in updatedDataSourceConfig) {
                if (key !== 'displayName') {
                    filteredConfig[key] = updatedDataSourceConfig[key];
                }
            }

            const definition = {
                timeframes: sourceDataStream.definition.timeframes ??
                    streamDataSource?.timeframes ??
                    true,
                ...sourceDataStream.definition,
                dataSourceConfig: filteredConfig,
                name: sourceDataStream.dataSourceName.toLowerCase()
            };

            return Update(dataStreamId, {
                definition,
                displayName: data.displayName,
                dataSourceName: sourceDataStream.dataSourceName,
                pluginId: sourceDataStream.pluginId,
                parentPluginVersion: '',
                sourceTemplateId: sourceDataStreamId
            }).then((id) => onClose(id));
        } else {
            onClose();
        }
    };

    // Handles interactive testing for field groups with the testResultField
    // property set
    const handleInteractiveTest = async (testName: string, formDataToTest: Record<string, unknown>) => {
        const pluginConfigToUse = {
            ...pluginConfig!.config!,
            agentGroupId: pluginConfig?.agentGroupId
                ? new AgentGroupId(pluginConfig.agentGroupId.toString())
                : undefined,
            ___testName: testName, // May be better to add extra arg to the whole test config route?
            ___dataSourceConfig: formDataToTest
        } as any as PluginConfigFormData;
        const testAPI = TestConfigAPI(pluginDetail, pluginConfigId);
        const result = await testAPI({ data: pluginConfigToUse });
        return result;
    };

    const dataStreamTemplateModalDescription = isUpdating ? (
        <>
            <FontAwesomeIcon className='pr-1' icon={faInfoCircle} />
            Warning: editing this configuration may impact other tiles also using this data stream
        </>
    ) : null;

    if (isLoadingPluginDetail || isLoadingPluginConfig) {
        return null;
    }

    return showDeleteModal ? (
        <DataStreamDeleteModal
            dataStreamId={dataStreamId}
            dataStreamTitle={dataSourceConfig.displayName as string}
            close={(deleted?: boolean) => {
                if (deleted) {
                    onClose(dataStreamId, true);
                }
                setShowDeleteModal(false);
                setSelectedTemplate('');
            }}
        />
    ) : (
        <Modal
            title={`Configure ${
                defaultDataSourceConfig?.displayName || sourceDataStream?.displayName || 'Data Stream'
            }`}
            description={dataStreamTemplateModalDescription}
            close={(_event, reason) => reason !== 'outside' && onClose()}
            fullWidth
            maxWidth='max-w-6xl'
        >
            {isDefinitionsLoading || isDataSourceLoading ? (
                <div className='my-10 text-center'>
                    <LoadingSpinner />
                </div>
            ) : (
                <PluginContext.Provider
                    value={{
                        plugin: plugin,
                        config: pluginConfig! as Record<string, unknown>,
                        testFunction: handleInteractiveTest
                    }}
                >
                    <DataStreamTemplateForm
                        defaultDataSourceConfig={defaultDataSourceConfig}
                        scope={scope}
                        pluginConfigId={pluginConfigId}
                        pluginId={plugin?.id}
                        streamDefinitions={streamDefinitions}
                        formFields={formFields}
                        isUpdating={isUpdating}
                        onClose={onClose}
                        onSubmit={isUpdating ? updateDataStream : createDataStream}
                        onDeleteClick={() => setShowDeleteModal(true)}
                    />
                </PluginContext.Provider>
            )}
        </Modal>
    );
}
