import { IconDefinition, IconName } from '@fortawesome/pro-solid-svg-icons';

export const gauge: IconDefinition = {
    iconName: 'gauge' as IconName,
    prefix: 'fas', // solid icon
    icon: [
        512, // viewBox width
        512, // viewBox height
        [], // ligatures
        'f269', // unicode codepoint - private use area
        'M255.98 395.12C251.67 395.12 247.33 393.76 243.64 390.93 234.71 384.1 233.02 371.34 239.84 362.41L411.74 137.72C418.57 128.8 431.33 127.08 440.26 133.92 449.19 140.75 450.88 153.51 444.06 162.44L272.16 387.13C268.15 392.37 262.1 395.11 255.98 395.11ZM377.83 147.62 338.77 198.7C313.73 186.86 285.61 180.17 256.04 180.17 156.68 180.17 74.4 255.48 63.64 352.04 63.73 352.49 63.73 353.03 63.73 353.49 63.73 371.03 49.44 385.31 31.81 385.31S0 371.02 0 353.48C.18 351.31.36 349.14.63 347.06.63 346.97.72 346.97.72 346.88 14.01 217.68 123.23 116.88 256.05 116.88 300.08 116.88 341.58 128 377.83 147.62ZM512 353.48C512 371.02 497.72 385.3 480.09 385.3S448.26 371.02 448.26 353.48C448.26 352.94 448.26 352.49 448.35 351.94 443.92 312.7 427.74 276.9 403.23 248.33L442.38 197.16C480.35 237.12 505.58 289.2 511.36 347.06V347.33C511.63 349.32 511.81 351.4 511.99 353.48Z' // path
    ]
};
