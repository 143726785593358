import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    DATE_FILTER_DEFAULT_UNIT,
    DATE_FILTER_DEFAULT_VALUE,
    FilterObject,
    isDateFilterOperation,
    isTenseUnitFilterOperation,
    StreamDataColumn
} from '@squaredup/data-streams';
import Input from 'components/forms/input/Input';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { TooltipValidation } from 'components/forms/validationMessage/TooltipValidationMessage';
import Tooltip from 'components/tooltip/Tooltip';
import { add, sub } from 'date-fns';
import EditorSelect from 'ui/editor/components/EditorSelect';
import {
    ColumnOption,
    dateOptions,
    Filter,
    getEmptyFilter,
    getFilterOperations,
    getFilterValueType,
    multiOperationOptions,
    stateOptions,
    tenseOptions
} from 'ui/editor/dataStream/utilities/filters';
import { DeleteRowButton } from './DeleteRowButton';

interface FilterOptionProps {
    index: number;
    filter: Filter;
    columnOptions: AutocompleteOption[];
    columns: StreamDataColumn[];
    update: (values: Filter) => void;
    remove: () => void;
}

const PlaceholderEmptyInput = () => <Input type='text' name='placeholder' disabled placeholder='Enter a value' />;

const PreviewDate: React.FC<{ filter: Filter }> = ({ filter }) => {
    if (!filter.operation || !filter.column) {
        return <></>;
    }

    const operation = filter.operation;
    const value = filter.value;
    const unit = filter.unit;

    if (!value || !unit) {
        return null;
    }

    const dateFunc =
        (operation === 'datemorethan' && filter.tenseUnit === 'before') || operation === 'datewithinlast' ? sub : add;

    const comparisonDate = dateFunc(new Date(), {
        [unit || 'days']: Number(value)
    });

    const comparisonDateString = comparisonDate.toLocaleString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });

    let text = '';

    if (operation === 'datemorethan' && filter.tenseUnit === 'before') {
        text = `before ${comparisonDateString}`;
    }

    if (operation === 'datemorethan' && filter.tenseUnit === 'after') {
        text = `after ${comparisonDateString}`;
    }

    if (operation === 'datewithinlast') {
        text = `between ${comparisonDateString} and now`;
    }

    if (operation === 'datewithinnext') {
        text = `between now and ${comparisonDateString}`;
    }

    return (
        <p>
            <strong>Preview: </strong>
            {text}
        </p>
    );
};

export const FilterOption: React.FC<FilterOptionProps> = ({
    index,
    filter,
    columnOptions,
    columns,
    update,
    remove
}) => {
    const column = columns.find((c: any) => filter.column === c.name);

    const operationOptions = getFilterOperations(column);

    const handleColumnChange = (changedColumn: ColumnOption) => {
        // If there's no column, reset the filter as the other fields will be invalid
        if (!changedColumn) {
            update(getEmptyFilter());
            return;
        }

        const newColumn = columns.find((c) => changedColumn.value === c.name);
        const newOperations = getFilterOperations(newColumn);

        // If the column has changed, and the existing operation isn't supported by the new column,
        // update the filter with a valid filter and value
        if (filter?.operation && !newOperations.some((option) => option.value === filter?.operation)) {
            update({
                column: changedColumn.value,
                operation: newOperations[0].value,
                value: isDateFilterOperation(newOperations[0].value as FilterObject['operation'])
                    ? DATE_FILTER_DEFAULT_VALUE
                    : null,
                unit: isDateFilterOperation(newOperations[0].value as FilterObject['operation'])
                    ? DATE_FILTER_DEFAULT_UNIT
                    : null,
                tenseUnit: isTenseUnitFilterOperation(newOperations[0].value as FilterObject['operation'])
                    ? 'before'
                    : null
            });
        }
    };

    const handleOperationChange = (changedOperation: AutocompleteOption) => {
        if (isDateFilterOperation(changedOperation.value as FilterObject['operation']) && !filter.unit) {
            update({
                unit: DATE_FILTER_DEFAULT_UNIT,
                value: DATE_FILTER_DEFAULT_VALUE,
                ...filter
            });
        }

        if (isTenseUnitFilterOperation(changedOperation.value as FilterObject['operation']) && !filter.tenseUnit) {
            update({
                tenseUnit: 'before',
                ...filter
            });
        }
    };

    const filterValueType = getFilterValueType(column?.shapeName);

    return (
        <div className='flex items-center space-x-2'>
            {index > 0 && (
                <div className='w-32'>
                    <EditorSelect
                        name='multiOperation'
                        options={multiOperationOptions}
                        isClearable={false}
                        isDisabled={index !== 1}
                    />
                </div>
            )}
            <div className='flex-1 min-w-0'>
                <EditorSelect
                    name={`filters.${index}.column`}
                    options={columnOptions}
                    placeholder='Select column'
                    onSelect={handleColumnChange}
                    isClearable={false}
                />
            </div>
            <div className='flex-1 min-w-0'>
                <EditorSelect
                    name={`filters.${index}.operation`}
                    options={operationOptions}
                    placeholder='Equals'
                    isClearable={false}
                    isDisabled={!column}
                    onSelect={handleOperationChange}
                />
            </div>
            {filter.operation && !['notempty', 'empty'].includes(filter.operation) && (
                <>
                    {filterValueType === 'state' && (
                        <div className='flex-1 min-w-0'>
                            <EditorSelect name={`filters.${index}.stateValue`} options={stateOptions} />
                        </div>
                    )}
                    {filterValueType === 'text' && (
                        <TooltipValidation
                            name={`filters.${index}.value`}
                            highlightValidValues={false}
                            className='flex-1 min-w-0'
                        >
                            <Input.Raw type='text' name={`filters.${index}.value`} placeholder='Enter a value' />
                        </TooltipValidation>
                    )}
                    {filterValueType === 'date' && (
                        <>
                            <TooltipValidation
                                name={`filters.${index}.value`}
                                highlightValidValues={false}
                                className='flex-1 max-w-[122px] min-w-0'
                            >
                                <Input.Raw
                                    type='number'
                                    min='0'
                                    name={`filters.${index}.value`}
                                    placeholder='Enter a value'
                                />
                            </TooltipValidation>
                            <TooltipValidation
                                name={`filters.${index}.unit`}
                                highlightValidValues={false}
                                className='flex-1 max-w-[122px] min-w-0'
                            >
                                <EditorSelect
                                    name={`filters.${index}.unit`}
                                    options={dateOptions}
                                    isClearable={false}
                                />
                            </TooltipValidation>
                        </>
                    )}
                    {filter.operation === 'datemorethan' && (
                        <TooltipValidation
                            name={`filters.${index}.tenseUnit`}
                            highlightValidValues={false}
                            className='flex-1 max-w-[130px] min-w-0'
                        >
                            <EditorSelect
                                name={`filters.${index}.tenseUnit`}
                                options={tenseOptions}
                                isClearable={false}
                            />
                        </TooltipValidation>
                    )}
                    {filterValueType === 'date' && (
                        <Tooltip
                            title={<PreviewDate filter={filter} />}
                            className='ml-2 cursor-pointer text-textSecondary shrink-0'
                        >
                            <FontAwesomeIcon className='text-base' icon={faCircleQuestion} />
                        </Tooltip>
                    )}
                </>
            )}

            {!filter.operation ||
                (!column && (
                    <div className='flex-1 min-w-0'>
                        <PlaceholderEmptyInput />
                    </div>
                ))}

            <Tooltip title='Remove this filter' className='flex w-6 place-items-center'>
                <DeleteRowButton aria-label='Remove this filter' className='block w-full h-full' onClick={remove} />
            </Tooltip>
        </div>
    );
};
