import type { AcePermission } from 'dynamo-wrapper';
import { useTenant } from 'queries/hooks/useTenant';
import { useQuery } from 'react-query';
import { AccessControlQueryKeys, GetEntityTypePermissions, IsTenantAdmin } from 'services/AccessControlService';

export const usePluginPermissions = () => {
    const { data: pluginPermissions, isLoading: isLoadingPermissions } = useQuery(
        AccessControlQueryKeys.EntityTypePermissions('config'),
        () => GetEntityTypePermissions('config')
    );
    const { data: tenant, isLoading: isTenantLoading } = useTenant();

    const canEdit = (configId: string) => {
        if (IsTenantAdmin(tenant)) {
            return true;
        }
        if (pluginPermissions) {
            const permissions = pluginPermissions.find((perm) => perm.objectId === configId)?.permissions;
            return permissions && ['RW', 'AD'].some((perm) => permissions.includes(perm as AcePermission));
        }
        return false;
    };

    const canRead = (configId: string) => {
        if (IsTenantAdmin(tenant)) {
            return true;
        }
        if (pluginPermissions) {
            const permissions = pluginPermissions.find((perm) => perm.objectId === configId)?.permissions;
            return permissions && ['RO', 'RW', 'AD'].some((perm) => permissions.includes(perm as AcePermission));
        }
        return false;
    };

    const canDelete = (configId: string) => {
        if (IsTenantAdmin(tenant)) {
            return true;
        }
        if (pluginPermissions) {
            const permissions = pluginPermissions.find((perm) => perm.objectId === configId)?.permissions;
            const isAdmin = permissions && permissions.includes('AD');
            return isAdmin;
        }
        return false;
    };

    return {
        isLoading: isTenantLoading || isLoadingPermissions,
        canEdit,
        canDelete,
        canRead
    };
};
