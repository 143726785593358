import type { UserGroupModel } from 'dynamo-wrapper';
import { UseQueryOptions, useQuery } from 'react-query';
import { AccessControlQueryKeys } from 'services/AccessControlService';
import { GetGroups } from 'services/UserService';

/**
 * useMutation to invite a user to a dashboard
 * @param options The useMutation options object
 * @returns uesMutation instance
 */
export const useGroups = <T = Array<UserGroupModel>>(
    options?: UseQueryOptions<Array<UserGroupModel>, unknown, T, string>
) => {
    return useQuery({
        ...options,
        queryKey: AccessControlQueryKeys.UserGroups,
        queryFn: GetGroups,
        staleTime: Number.POSITIVE_INFINITY,
        cacheTime: Number.POSITIVE_INFINITY
    });
};
