import { Value } from '@nivo/bar';
import {
    FormattedStreamData,
    FormattedStreamValue,
    FoundColumn,
    Shape,
    StreamData,
    StreamDataColumn,
    date,
    findColumn,
    findColumns,
    getShape,
    number,
    percent,
    preferred,
    required,
    state,
    string
} from '@squaredup/data-streams';
import { DataMatchCriteria, dataMatchCriteria } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { getDateTickFormatter } from 'dashboard-engine/util/tickDateFormatter';
import { toShortString } from 'dashboard-engine/util/tickFormatter';
import { toDate } from 'date-fns';
import { groupBy, orderBy, sum } from 'lodash';
import { dateFormatterFullDate } from '../DataStreamLineGraph/LineGraphDataValidation';
import { BarChartCategories, DataStreamBarChartConfig } from './Config';

export type ResolvedGroupedDataObject = {
    __group__: string | null;
    groupValue: FormattedStreamValue;
    [key: string]: any;
};

type BarChartResult = {
    groupedChartData: ResolvedGroupedDataObject[];
    shape?: Shape;
    dataKeys: string[];
};

type BarChartAxis = {
    column: StreamDataColumn;
    label: string;
};

type BarChartData = {
    group: string;
    groupValue: FormattedStreamValue;
    property: string;
    value: number;
    formattedValue: string;
    [property: string]: FormattedStreamValue | number | string;
};

type BarChartColumns = {
    valueColumn: FoundColumn;
    labelColumn: FoundColumn;
    groupColumn: FoundColumn;
    labelColumnCandidates: FoundColumn[];
    valueColumnCandidates: FoundColumn[];
};

interface ComputedTooltipPointsProps {
    color: string;
    id: Value;
    indexValue: Value;
    formattedFromNivoData: Value;
    subValue?: string;
}

export interface BarChart {
    property: string;
    value: number;
    formattedValue: string;
    row: FormattedStreamValue[];
    __group__?: string | null;
}

/**
 * Specified color palette for all visualizations
 */
export const colors = [
    '#3ABACD',
    '#1C98C6',
    '#3EB5FF',
    '#0669F3',
    '#7A65FA',
    '#622CE8',
    '#844DCE',
    '#7423B2',
    '#994ABB',
    '#842497',
    '#A54EA6',
    '#DB96B7'
];

/**
 * @param columns Metadata
 * @param config Tile config
 * @returns Returns columns for the bar chart based on provided metadata and tile config
 */
export const getBarChartColumns = (
    columns: StreamDataColumn[],
    config?: DataStreamBarChartConfig
): DataMatchCriteria<BarChartColumns> => {
    const criteria = dataMatchCriteria<BarChartColumns>();

    // Determine y-axis column
    let valueColumn = findColumn(columns, required('valueShapeName', number.name), preferred('role', 'value'));
    if (config?.yAxisData) {
        const yValue = findColumn(columns, required('name', config.yAxisData));
        if (yValue.succeeded) {
            valueColumn = yValue;
        }
    }

    if (valueColumn.failed) {
        criteria.fail('Missing Y-Axis column', valueColumn.reason);
    } else {
        criteria.pass('Automatically selected Y-Axis', {
            valueColumn: valueColumn.value
        });
    }

    // Determine label columns
    const labelColumns = findColumns(
        columns,
        required('valueShapeName', string.name),
        preferred.not('shapeName', state.name),
        preferred.not('shapeName', date.name),
        preferred('role', 'label')
    );

    if (labelColumns.failed) {
        criteria.fail('Missing X-Axis column');
    } else if (labelColumns.value.length < 1) {
        criteria.fail('Bar chart requires at least one label column');
    }

    if (labelColumns.succeeded && labelColumns.value.length >= 1) {
        // Determine X-Axis group
        if (!config?.xAxisGroup || config.xAxisGroup === 'auto') {
            let groupColumn = labelColumns.value.length > 1 ? labelColumns.value[0] : labelColumns.value[1];
            criteria.pass('Automatically selected Series', {
                groupColumn
            });
        }

        if (config?.xAxisGroup) {
            const extra = findColumn(columns, required('name', config.xAxisGroup));
            if (extra.succeeded) {
                criteria.pass('Automatically selected Series', {
                    groupColumn: extra.value
                });
            }
        }

        // Determine X-axis data
        let labelColumn = labelColumns.value.length > 1 ? labelColumns.value[1] : labelColumns.value[0];
        if (config?.xAxisData) {
            const xPos = findColumn(columns, required('name', config.xAxisData));

            if (xPos.succeeded) {
                labelColumn = xPos.value;
            }
        }
        criteria.pass('Automatically selected X-Axis', {
            labelColumn,
            labelColumnCandidates: labelColumns.value
        });
    }

    return criteria;
};

/**
 * @param data Tile data
 * @param config Tile config
 * @returns Returns columns for the bar chart based on provided tile data and tile config
 */
export const matchesData = (data?: FormattedStreamData, config?: DataStreamBarChartConfig) => {
    return getBarChartColumns(data?.metadata?.columns ?? [], config);
};

/**
 * Determine if the given columns/config would result in a series column being used
 * e.g manually selected, or auto selected
 * @param columns Metadata columns
 * @param config Tile config
 * @returns boolean
 */
export const hasSeriesColumn = (columns: StreamDataColumn[], config: DataStreamBarChartConfig) => {
    if (columns.length === 0) {
        return false;
    }

    const {
        value: { labelColumn: currentXAxisDataCol, labelColumnCandidates }
    } = getBarChartColumns(columns, config);

    // Determine available grouping options
    const groupColumns = labelColumnCandidates
        ?.map((c) => c.column)
        ?.filter((c) => c.name !== currentXAxisDataCol?.column.name);

    if (config.xAxisGroup === 'none' || !groupColumns || groupColumns.length === 0) {
        return false;
    }

    return true;
};

/**
 * @param valueColumn
 * @param groupColumn
 * @param param2
 * @param isHorizontal
 * @param isPercentageMode
 * @returns the axes for the provided columns based on orientation and mode with associated labels
 */
export const getAxes = (
    valueColumn: StreamDataColumn,
    groupColumn: StreamDataColumn,
    { xAxisLabel, yAxisLabel }: Pick<DataStreamBarChartConfig, 'xAxisLabel' | 'yAxisLabel'>,
    isHorizontal: boolean,
    isPercentageMode: boolean
): { x: BarChartAxis; y: BarChartAxis } => {
    const hasXAxisLabelConfig = xAxisLabel && xAxisLabel.length > 0;
    const hasYAxisLabelConfig = yAxisLabel && yAxisLabel.length > 0;

    const xLabel = hasXAxisLabelConfig ? xAxisLabel : groupColumn?.displayName;
    const yLabel = hasYAxisLabelConfig ? yAxisLabel : valueColumn?.displayName;

    const x: BarChartAxis = { column: groupColumn, label: xLabel };
    const y: BarChartAxis = {
        column: isPercentageMode
            ? {
                  ...valueColumn,
                  targetShapeName: percent.name,
                  shapeName: percent.name,
                  rawShapeConfig: { decimalPlaces: 0 }
              }
            : valueColumn,
        label: yLabel
    };

    /**
     * If the layout is horizontal then the typical 'Y Axis Label' now should be on the X Axis
     * therefore we need to swap them around.
     */
    if (isHorizontal) {
        return { x: y, y: x };
    }

    return { x, y };
};

/**
 * @returns A date that has been rounded up to the nearest minute
 */
export const roundToNearestMinute = (categoryDate: Date) => {
    const coeff = 1000 * 60 * 1;
    return new Date(Math.round(categoryDate.getTime() / coeff) * coeff);
};

/**
 * @returns an array of date categories converted to the appropriate d3 time-scale
 */
export const categoriesToTicks = (categories: BarChartCategories, locale?: string, timeZone?: string) => {
    const roundedCategories = categories
        .filter((d): d is string => Boolean(d))
        //  prevent D3 incorrectly scaling based on second/ms
        .map((d) => roundToNearestMinute(new Date(d)));

    const computedTickFormatter = getDateTickFormatter(roundedCategories, locale, timeZone);

    const formattedCategories = roundedCategories.map((d) => computedTickFormatter(d));

    return formattedCategories;
};

/**
 * @returns a boolean determining whether the passed label is a valid date
 */
const isSubLabelValidDate = (label: Value): boolean => {
    const parsedDate = toDate(label);
    return !isNaN(parsedDate.getTime());
};

export const computedTooltipPoints = ({
    color,
    id,
    indexValue,
    formattedFromNivoData,
    subValue
}: ComputedTooltipPointsProps) => {
    const isDate = isSubLabelValidDate(indexValue); //TODO: Can/should we detect this from column shape?

    return {
        subLabel: isDate ? dateFormatterFullDate(indexValue) : indexValue,
        label: id || 'Value',
        value: subValue ? `${formattedFromNivoData} (${subValue})` : formattedFromNivoData,
        color
    };
};

/**
 * Loops through the series array to get the largest formatted value and then uses that value to determine max legend length
 * @param series
 * @returns a number determining the acceptable length of a legend string
 */
export const legendMaxLength = (series: BarChart[] | ResolvedGroupedDataObject[]) => {
    return series.reduce((innerMax, { formattedValue }) => {
        const length = toShortString(formattedValue).length;
        return length > innerMax ? length : innerMax;
    }, 0);
};

/**
 * @param columns
 * @param data
 * @param displayMode
 * @param hasSorting
 * @returns Data formatted for the Nivo bar chart
 */
export const transformToBarChart = (
    columns: BarChartColumns,
    data: StreamData,
    displayMode: 'actual' | 'percentage' | 'cumulative',
    hasSorting: boolean,
    hasSeries: boolean
): BarChartResult => {
    const { groupColumn, labelColumn, valueColumn } = columns;

    const shape = getShape(valueColumn.column.shapeName);
    if (data.rows.length === 0) {
        return {
            groupedChartData: [],
            shape,
            dataKeys: []
        };
    }

    const allData: BarChartData[] = Array.isArray(data.rows)
        ? data.rows
              .filter((r) => typeof r[valueColumn?.dataIndex].value === 'number')
              .map((r) => ({
                  group: r[labelColumn?.dataIndex]?.formatted,
                  groupValue: r[labelColumn.dataIndex],
                  property: groupColumn ? r[groupColumn?.dataIndex]?.formatted : r[labelColumn?.dataIndex]?.formatted,
                  value: r[valueColumn?.dataIndex]?.value as number,
                  formattedValue: r[valueColumn?.dataIndex]?.formatted,
                  [r[labelColumn?.dataIndex]?.formatted]: r[valueColumn?.dataIndex]?.value as number
              }))
        : [];

    const dataKeys: string[] = [...new Set(allData.map(({ property }) => property))];
    const groupByGroupColumn = groupBy(allData, (e) => e.groupValue.value);

    const groupTotals: { [key: string]: number } = {};

    Object.entries(groupByGroupColumn).forEach(([group, results]) => {
        const total = sum(results.map((r) => r.value));
        groupTotals[group] = total;
    });

    const indexes = dataKeys.slice(0).reduce<{ [key: string]: number }>((acc, cur, index) => {
        // Nivo removes falsey values, so start at 1
        acc[cur] = index + 1;
        return acc;
    }, {});

    const isSortingRequired = !hasSorting && labelColumn.column.shapeName === date.name;

    const groupByGroupColumnSorted = isSortingRequired
        ? orderBy(Object.entries(groupByGroupColumn), '0')
        : Object.entries(groupByGroupColumn);

    /**
     * Store a running total for each series property.
     */
    let runningTotal = {} as { [property: string]: number };

    const groupedChartData = groupByGroupColumnSorted.map(([__group__, results]) => {
        return {
            __group__,
            groupValue: results[0].groupValue,
            ...Object.fromEntries(
                results.map(({ property, value }) => {
                    if (displayMode === 'percentage') {
                        const total = groupTotals[__group__];
                        if (total === 0) {
                            return [property, 0];
                        }
                        return [property, (value / groupTotals[__group__]) * 100];
                    }

                    if (displayMode === 'cumulative') {
                        // if there is no series column, we can just sum the values into one property.
                        const totalProperty = hasSeries ? property : 'total';
                        runningTotal[totalProperty] = (runningTotal[totalProperty] || 0) + value;

                        return [property, runningTotal[totalProperty]];
                    }

                    return [property, value];
                })
            ),
            ...Object.fromEntries(
                results.map(({ property, formattedValue }) => [`${property}-formatted`, formattedValue])
            ),
            ...Object.fromEntries(
                results.map(({ property, value }) => [
                    `${property}-percentage`,
                    `${((value / groupTotals[__group__]) * 100).toFixed(2)}%`
                ])
            ),
            ...Object.fromEntries(
                results.map(({ property, formattedValue }) => [`${property}-formatted`, formattedValue])
            ),
            ...Object.fromEntries(results.map(({ property }) => [`${property}-index`, indexes[property]]))
        };
    });

    return {
        groupedChartData,
        shape,
        dataKeys
    };
};
