import { BarExtendedDatum } from '@nivo/bar';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { LegendPosition } from '../components/Legends';
import { CustomPalette } from '../getColor';

export type LegacyDataStreamBarChartConfig = {
    xAxisColumn?: string;
    yAxisColumn?: string;
    requiresLegacyColumnMigration?: boolean;
} & DataStreamBarChartConfig;

export type DataStreamBarChartConfig = {
    /**
     * String to use as an X-axis label
     */
    xAxisLabel?: string;
    /**
     * String to use as an Y-axis label
     */
    yAxisLabel?: string;
    yAxisData?: string;
    xAxisData?: string;
    xAxisGroup?: string;
    horizontalLayout?: 'vertical' | 'horizontal';
    grouping?: boolean;
    showValue?: boolean;
    showTotals?: boolean;
    displayMode?: 'actual' | 'percentage' | 'cumulative';
    range?: {
        type: 'auto' | 'custom';
        minimum?: number;
        maximum?: number;
    };
    showGrid?: boolean;
    showLegend?: boolean;
    legendPosition?: LegendPosition;
} & CustomPalette;

export interface BarChartBars {
    color: string;
    data: BarExtendedDatum;
    height: number;
    key: string;
    width: number;
    x: number;
    y?: number;
}

export interface GraphTickProps {
    text: string | number | null;
    xPosition: number;
}

/**
 * An array of categories that are to be converted into a D3 scaled date-tick format
 */
export type BarChartCategories = (string | null)[];

/**
 * The maximum number of characters allowed for ticks before truncation
 */
export const maxTickCharacterLength = 15;

/**
 * Ensures constant space for total graph size (to fit rotated ticks etc)
 */
export const barGraphBottomMarginPadding = 40;

/**
 * Ensures constant space for legend label and bottom edge of the graph
 */
export const barGraphBottomLegendPadding = 10;

/**
 * Default bottom margin spacing for bar graph variants
 */
export const barGraphDefaultBottomMargin = 45;

/**
 * Default right margin spacing for bar graph variants
 */
export const barGraphDefaultRightMargin = 20;

/**
 * Rough estimate of the width of a single character in a bar chart
 */
export const barGraphCharacterWidth = 7.5;

/**
 * Min height of bar annotation for it to be rendered above the bar.
 * (When using horizontal layout)
 */
export const barGraphLabelAlignTopMinHeight = 18;

/**
 * Vertical offset of the bar annotation when rendered above the bar.
 */
export const barGraphLabelVerticalOffset = 10;

/**
 * Min height of bar annotation for it to be rendered above the bar.
 * (When using vertical layout)
 */
export const barGraphLabelAlignRightMinWidth = 18;

/**
 * Horizontal; offset of the bar annotation when rendered to right of the bar.
 */
export const barGraphLabelHorizontalOffset = 5;

/**
 * Layout (graph orientation): vertical | horizontal
 */
export const layoutValues: AutocompleteOption[] = [
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' }
];
