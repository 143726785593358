import { IconDefinition, IconName } from '@fortawesome/pro-solid-svg-icons';

export const scalar: IconDefinition = {
    iconName: 'scalar' as IconName,
    prefix: 'fas', // solid icon
    icon: [
        512, // viewBox width
        512, // viewBox height
        [], // ligatures
        'f269', // unicode codepoint - private use area
        'M312.67 322.06v39.78h-159.43v-31.64l81.37-76.85c6.43-5.83 11.15-11.05 14.17-15.68 3.01-4.61 5.07-8.83 6.18-12.65 1.09-3.81 1.65-7.33 1.65-10.55 0-8.43-2.86-14.92-8.58-19.43-5.73-4.53-14.13-6.79-25.18-6.79-8.84 0-17.01 1.72-24.55 5.12-7.54 3.42-13.92 8.74-19.14 15.97l-35.56-22.89c8.03-12.06 19.29-21.6 33.76-28.64 14.45-7.03 31.13-10.54 50.02-10.54 15.67 0 29.38 2.56 41.14 7.68 11.75 5.12 20.89 12.31 27.42 21.55 6.53 9.24 9.8 20.29 9.8 33.15 0 6.83-.86 13.62-2.56 20.34-1.72 6.73-5.12 13.82-10.25 21.25-5.13 7.43-12.61 15.77-22.45 25.02l-48.63 45.8h90.82ZM512 297.35c0 11.85-3.12 22.95-9.34 33.3-6.23 10.35-15.78 18.74-28.64 25.16-12.85 6.43-29.33 9.65-49.42 9.65-14.67 0-29.23-1.96-43.7-5.88-14.47-3.92-26.72-9.49-36.77-16.72l18.99-37.38c8.03 5.84 17.37 10.46 28.03 13.87 10.65 3.41 21.39 5.13 32.24 5.13 12.26 0 21.9-2.42 28.94-7.24 7.02-4.82 10.54-11.45 10.54-19.89s-3.11-14.36-9.34-18.99c-6.22-4.61-16.27-6.93-30.14-6.93h-22.3v-32.25l43.35-49.12h-89.76v-39.18h147.67v31.64l-47.41 54.02c15.49 2.44 27.88 7.75 37.17 15.9 13.26 11.66 19.89 26.63 19.89 44.91ZM90.82 150.88 90.82 361.84 42 361.84 42 190.06-.2 190.06-.2 150.88 90.82 150.88Z' // path
    ]
};
