import RenderDynamic from 'dashboard-engine/render/RenderDynamic';

function List({ config, isLoading = false }) {
    return config.contents.map((content, index) => (
        <div className='w-full' key={`list-${index}`}>
            <RenderDynamic config={content} isLoading={isLoading} />
        </div>
    ));
}

export default List;
