import { cn } from '@/lib/cn';
import NetworkSkeleton from 'components/map/NetworkSkeleton';
import { useAppContext } from 'contexts/AppContext';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { ReactNode, useMemo } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { LayoutFlow } from './components/LayoutFlow';
import { NetworkMapStoreProvider } from './context/NetworkMapStoreContext';
import { useObjectMapData } from './data/useObjectMapData';
import { setNodesAndEdgeVisibility } from './data/utils/setNodesAndEdgeVisibility';
import { LayoutTypes } from './layout/types';
import { NetworkMapStoreState } from './NetworkMapStore';

export interface MapProps {
    controls?: ReactNode;
    className?: string;
    expandedNodeIds?: string[];
    layoutType?: LayoutTypes;
    background?: NetworkMapStoreState['background'];
}

export const NetworkMap: React.FC<MapProps> = ({
    controls,
    className,
    expandedNodeIds: initialExpandedNodeIds,
    layoutType = LayoutTypes.hierarchyVertical,
    background = 'backgroundSecondary'
}) => {
    const { currentWorkspaceID } = useAppContext();
    const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace(currentWorkspaceID);

    const mapId = useMemo(() => `map-${uuid()}`, []);

    // Initialise expandedNodeIds to the passed nodes or the current workspace's nodeId if none are passed
    const expandedNodeIds = initialExpandedNodeIds ?? [workspace?.data.id];
    const { data, isLoading } = useObjectMapData(expandedNodeIds);
    const { nodes: graphNodes, edges: graphEdges } = data ?? {};

    const { nodes, edges } = setNodesAndEdgeVisibility(
        graphNodes ?? [],
        graphEdges ?? [],
        expandedNodeIds ?? [],
        expandedNodeIds ?? [],
        new Map<string, string[]>(),
        []
    );

    if (isLoadingWorkspace || isLoading) {
        return <NetworkSkeleton variant={layoutType === LayoutTypes.hierarchyVertical ? 'hierarchical' : 'central'} />;
    }

    return (
        <div id={mapId} className={cn('h-full w-full relative', className)}>
            <div className='absolute inset-0 w-full'>
                <NetworkMapStoreProvider
                    mapId={mapId}
                    layoutType={layoutType}
                    nodes={nodes ?? []}
                    edges={edges ?? []}
                    graphNodes={graphNodes ?? []}
                    graphEdges={graphEdges ?? []}
                    background={background}
                    expandedNodeIds={expandedNodeIds}
                    pinnedNodeIds={expandedNodeIds}
                    ungroupedNodeIds={[]}
                    pinnedGroupsWithMemberIds={new Map<string, string[]>()}
                >
                    <ReactFlowProvider key='mapReactFlow'>
                        <LayoutFlow controls={controls} key='mapReactFlowLayout' />
                    </ReactFlowProvider>
                </NetworkMapStoreProvider>
            </div>
        </div>
    );
};
