import graphNode from '../datasources/graph/graphNode/graphNode';
import { SimpleTile } from 'dashboard-engine/basetiles/types';
import Text from '../visualisations/Text/Text';

const graphNodeText: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: graphNode
    },
    visualisation: {
        type: Text
    }
};

export default graphNodeText;
