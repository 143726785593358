import { cn } from '@/lib/cn';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { useDOMElement } from 'components/hooks/useDOMElement';
import Tooltip from 'components/tooltip/Tooltip';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

export const buttonVariants = cva(
    'inline-flex items-center select-none outline-none focus:ring-0 focus:outline-none disabled:pointer-events-none aria-disabled:pointer-events-none',
    {
        variants: {
            variant: {
                destructive:
                    'py-input px-md font-semibold rounded leading-input bg-destructiveButtonBackground text-primaryButtonText whitespace-nowrap hover:bg-destructiveButtonBackgroundHover data-[state="open"]:bg-destructiveButtonBackgroundHover focus:bg-destructiveButtonBackgroundHover disabled:bg-destructiveButtonBackgroundDisabled disabled:text-primaryButtonTextDisabled',
                primary:
                    'py-input px-md font-semibold rounded leading-input bg-primaryButtonBackground text-primaryButtonText whitespace-nowrap hover:bg-primaryButtonBackgroundHover data-[state="open"]:bg-primaryButtonBackgroundHover focus:bg-primaryButtonBackgroundHover disabled:bg-primaryButtonBackgroundDisabled disabled:text-primaryButtonTextDisabled',
                secondary:
                    'py-inputWithBorder px-md font-semibold rounded leading-input bg-secondaryButtonBackground text-secondaryButtonText whitespace-nowrap border border-secondaryButtonOutline hover:bg-secondaryButtonBackgroundHover hover:border-secondaryButtonOutlineHover data-[state="open"]:bg-secondaryButtonBackgroundHover data-[state="open"]:border-secondaryButtonOutlineHover focus:bg-secondaryButtonBackgroundHover focus:border-secondaryButtonOutlineHover disabled:bg-secondaryButtonBackgroundDisabled disabled:text-secondaryButtonTextDisabled',
                tertiary:
                    'text-tertiaryButton tracking-body hover:text-tertiaryButtonHover focus:text-tertiaryButtonHover whitespace-nowrap disabled:text-tertiaryButtonDisabled',
                link: 'text-textLink tracking-body hover:underline focus:underline disabled:text-textLinkDisabled',
                choice:
                    'py-input px-md rounded leading-input bg-componentBackgroundPrimary text-textPrimary whitespace-nowrap ring-tileOutline ring-inset ring-1 hover:bg-tagBackground hover:ring-outlinePrimary focus-visible:bg-tagBackground focus:ring-1 focus-visible:ring-outlinePrimary disabled:bg-componentBackgroundPrimary disabled:text-textDisabled',
                upgrade:
                    'py-input px-md font-semibold rounded leading-input bg-upgradeButtonBackgroundPrimary text-upgradeButtonTextPrimary whitespace-nowrap hover:bg-upgradeButtonBackgroundHover focus:bg-upgradeButtonBackgroundHover disabled:bg-upgradeButtonBackgroundDisabled disabled:text-upgradeButtonTextDisabled'
            }
        },
        defaultVariants: {
            variant: 'primary'
        }
    }
);

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    icon?: ReactNode;
    href?: string;
    asChild?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, icon, href, title, children, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button';

        return (
            <Comp 
                className={cn(buttonVariants({ variant, className }), children && 'gap-x-3')} 
                ref={ref} 
                {...props}
            >
                {icon && <span>{icon}</span>}
                {children && (<span>{children}</span>)}
            </Comp>
        );
    }
);

export const TooltipButton = forwardRef<
    HTMLButtonElement,
    Omit<ButtonProps, 'title'> & {
        tooltipClassName?: string;
        title?: ReactNode;
    }
>(({ className, tooltipClassName, variant, icon, title, children, ...props }, ref) => {
    return (
        <Tooltip title={title} className='inline-flex' tooltipClassName={tooltipClassName} disabled={!title}>
            <button className={cn(buttonVariants({ variant, className }), children && 'gap-x-3')} ref={ref} {...props}>
                {icon && <span className='shrink-0'>{icon}</span>}
                <span>{children}</span>
            </button>
        </Tooltip>
    );
});

interface ButtonAction {
    label: string;
    icon?: ReactNode;
    onSelect: () => void;
}

/**
 * A button with secondary actions. The actions appear in a dropdown attached to the button. It can only be of variant
 * type 'destructive', 'primary' or 'secondary'
 * The button content still has all the same actions/options as a standard button
 */
export const ActionButton = forwardRef<
    HTMLButtonElement, 
    ButtonProps & { portal?: string, actions: ButtonAction[] } & { variant?: 'destructive' | 'primary' | 'secondary' }
>(
    ({ variant = 'primary', className, actions, icon, title, portal, children, disabled, onClick, ...props }, ref) => {
        const portalTarget = useDOMElement(portal);

        return (
            <div className='flex min-w-0 shrink-0'>
                <button 
                    className={cn(
                        buttonVariants({ variant, className }), 
                        children && 'gap-x-3', 'rounded-r-none',
                        variant === 'secondary' && 'border-r-0'
                    )} 
                    ref={ref}
                    disabled={disabled}
                    onClick={onClick}
                    {...props}
                >
                    {icon && <span className='inline-flex items-center shrink-0'>{icon}</span>}
                    <span>{children}</span>
                </button>

                {variant === 'secondary' && <div className={cn('w-px bg-secondaryButtonDivider', disabled && 'bg-secondaryButtonDividerDisabled')} />}

                <DropdownMenu.Root modal={false}>
                    <DropdownMenu.Trigger asChild disabled={disabled}>
                       <button 
                            className={cn(
                                buttonVariants({ variant, className }), 
                                children && 'px-4 rounded-l-none border-l',
                                variant === 'destructive' && 'border-destructiveButtonDivider disabled:border-destructiveButtonDividerDisabled',
                                variant === 'primary' && 'border-primaryButtonDivider disabled:border-primaryButtonDividerDisabled',
                                variant === 'secondary' && 'border-l-0' 
                            )} 
                            disabled={disabled}
                        >
                            <FontAwesomeIcon icon={faChevronDown} />
                        </button>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Portal container={portalTarget}>
                        <DropdownMenu.Content 
                            className='border shadow-none rounded-input bg-componentBackgroundPrimary text-textPrimary border-outlinePrimary'
                            style={{ zIndex: 100 }}
                            align='end'
                            sideOffset={1}
                        >
                            {actions.map(({ label, icon: actionIcon, onSelect }, index) => 
                                <DropdownMenu.Item 
                                    onSelect={onSelect} 
                                    key={index}
                                    className='flex items-center space-x-2 leading-[16px] py-input px-md cursor-pointer outline-none ring-0 focus:ring-0'
                                >
                                    {actionIcon && <span>{actionIcon}</span>}
                                    {label}
                                </DropdownMenu.Item>
                            )}
                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            </div>
        );
    });
