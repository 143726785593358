import DropdownMenu from '@/components/DropdownMenu';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';

type FullscreenProps = {
    onOpenFullscreen?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const Fullscreen: React.FC<FullscreenProps> = ({ onOpenFullscreen }) => {
    const { config } = useTileContext();

    if (config._type !== TileTypes.dataStream) {
        return null;
    }

    return (
        <DropdownMenu.Item
            icon={<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} fixedWidth />}
            onSelect={onOpenFullscreen as unknown as (event: Event) => void}
            key='fullscreen'
        >
            Full screen
        </DropdownMenu.Item>
    );
};
