import { Dialog } from '@/components/Dialog';
import { VisualizationState } from 'dashboard-engine/hooks/useVisualizationState';
import { MutableRefObject } from 'react';
import { FullscreenTile } from './FullscreenTile';

interface FullScreenWrapperProps {
    visualizationState: MutableRefObject<VisualizationState>;
    isOpen: boolean;
    onClose: () => void;
}

export const FullScreenWrapper: React.FC<FullScreenWrapperProps> = ({ 
    visualizationState,
    isOpen, 
    onClose 
}) => {
    if (!isOpen) {
        return null;
    }

    return (
        <Dialog
            open={true}
            onOpenChange={onClose}
        >
            <FullscreenTile
                visualizationState={visualizationState}
                onClose={onClose} 
            />
        </Dialog>
    );
};
