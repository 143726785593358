import { buildQuery, Node } from '@squaredup/graph';
import { TimeframeEnumValue } from '@squaredup/timeframes';
import DataStreamData from 'dashboard-engine/dataStreams/DataStreamData';
import { GlobalDataStreamContext } from 'dashboard-engine/dataStreams/GlobalDataStreamContext';
import React, { FC, useMemo } from 'react';
import { DrilldownPanel } from './DrilldownPanel';
import { DataStreamVisualisationPanel } from './DrilldownVisualisations';
import { GraphNodeMapPanel } from './GraphNodeMapPanel';
import { GraphNodePropertyPanel } from './GraphNodePropertyPanel';

const DrilldownColumn: FC = ({ children }) => <div className='flex flex-col w-1/2 gap-2.5'>{children}</div>;

interface GraphNodeDrilldownOverviewProps {
    node: Node & {
        isCanonical: boolean;
    };
    timeframe: TimeframeEnumValue;
}

const GraphNodeDrilldownOverview: React.FC<GraphNodeDrilldownOverviewProps> = ({ node, timeframe }) => {
    const scope = useMemo(() => buildQuery({ ids: [node.id] }, ''), [node.id]);

    return (
        <GlobalDataStreamContext>
            <div className='flex flex-1 h-full w-full min-h-0  pt-5 gap-2.5'>
                <DrilldownColumn>
                    <DataStreamVisualisationPanel node={node} timeframe={timeframe} />
                </DrilldownColumn>
                <DrilldownColumn>
                    {node.isCanonical && (
                        <div className='flex-shrink-0'>
                            <DrilldownPanel title='Correlated Objects'>
                                <div className='relative h-32'>
                                    <DataStreamData
                                        key={`${node.id}-health`}
                                        dataStreamId={'datastream-health'}
                                        timeframe={'last24hours'}
                                        scope={{
                                            query: scope.gremlinQuery,
                                            bindings: scope.bindings,
                                            queryDetail: { ids: [node.id] }
                                        }}
                                        options={{
                                            // For drilldown the node could have come from
                                            // any workspace we have access to.
                                            accessControlType: 'directOrAnyWorkspaceLinks'
                                        }}
                                        config={{ sublabel: 'data.sourceName' }}
                                    />
                                </div>
                            </DrilldownPanel>
                        </div>
                    )}
                    <div className='flex-initial min-h-0'>
                        <GraphNodePropertyPanel node={node} />
                    </div>
                    <div className='flex-grow flex-shrink-0'>
                        <GraphNodeMapPanel node={node} />
                    </div>
                </DrilldownColumn>
            </div>
        </GlobalDataStreamContext>
    );
};

export default GraphNodeDrilldownOverview;
