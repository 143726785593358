import { useAppContext } from 'contexts/AppContext';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { isOpenAccess } from 'lib/openAccessUtil';
import { EditButton } from './components/EditButton';
import { ExploreButton } from './components/ExploreButton';
import { FullscreenButton } from './components/FullscreenButton';
import { RefetchButton } from './components/RefetchButton';
import { TileMenu } from './components/TileMenu';

interface TileHeaderActionsProps {
    onOpenFullscreen?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const TileHeaderActions: React.FC<TileHeaderActionsProps> = ({ onOpenFullscreen }) => {
    const { config } = useTileContext();
    const { editing } = useDashboardContext();
    const { searchOpen } = useAppContext();

    const isOA = isOpenAccess();
    const isDataStreamTile = config._type === TileTypes.dataStream;

    const isEditEnabled = Boolean(editing);
    const isFullscreenEnabled = isDataStreamTile && !editing && !searchOpen;
    const isRefetchEnabled = isDataStreamTile && !editing && !searchOpen && !isOA;
    const isExploreEnabled = isDataStreamTile && searchOpen;
    const isTileMenuEnabled = !searchOpen;

    return (
        <div className='flex items-center gap-xxxs min-h-[21px]'>
            {isEditEnabled && <EditButton />}
            {isFullscreenEnabled && <FullscreenButton onOpenFullscreen={onOpenFullscreen} />}
            {isRefetchEnabled && <RefetchButton />}
            {isExploreEnabled && <ExploreButton />}
            {isTileMenuEnabled && <TileMenu onOpenFullscreen={onOpenFullscreen} />}
        </div>
    );
};
