import { DashboardType } from '@squaredup/dashboards';
import { DataStreamBaseTileConfig, FormattedStreamData } from '@squaredup/data-streams';
import { TileTypes } from 'dashboard-engine/constants';
import { useForceRefetch } from './useForceRefetch';

const useGetForceRefetchForTile = (tileConfig: DataStreamBaseTileConfig) => {
    return useForceRefetch(tileConfig).forceRefetch;
};

const useGetForceRefetchForAllTiles = (tileConfigs: DataStreamBaseTileConfig[]) => {
    return tileConfigs.map(useGetForceRefetchForTile);
};

/**
 * Returns function that invokes all of the forceRefetches for all tiles in a dashboard
 */
const useForceRefetchFetchAllTiles = (tileForceRefetches: (() => Promise<FormattedStreamData> | undefined)[]) => {
    return () => tileForceRefetches.forEach((forceRefetch) => forceRefetch());
};

/**
 * Hook that gets all of the data stream tiles on a dashboard and returns a function to invoke
 * A forced refetch of all of the data.
 */
export const useDashboardForceRefetch = (dashboard?: DashboardType) => {
    const dataStreamTiles =
        (dashboard?.content.contents
            .filter(({ config }) => config._type === TileTypes.dataStream)
            .map(({ config }) => config) as DataStreamBaseTileConfig[]) ?? [];

    const fetchAllTilesData = useGetForceRefetchForAllTiles(dataStreamTiles);

    return useForceRefetchFetchAllTiles(fetchAllTilesData);
};
