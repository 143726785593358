import type { DashboardType } from '@squaredup/dashboards';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { useMutation, useQueryClient } from 'react-query';
import { BatchDelete as deleteDashboards } from 'services/DashboardService';

export function useDeleteDashboards() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: dashboardQueryKeys.deleteDashboardsMutation,
        mutationFn(dashboards: DashboardType[]) {
            return deleteDashboards(dashboards.map(({ id }) => id));
        },
        async onMutate(dashboards) {
            await queryClient.cancelQueries(dashboardQueryKeys.list, {});

            const oldListDashboards = queryClient.getQueryData(dashboardQueryKeys.list) as DashboardType[];

            queryClient.setQueryData(
                dashboardQueryKeys.list,
                oldListDashboards.filter((dash) => !dashboards.find(({ id }) => id === dash.id))
            );

            return { oldListDashboards, dashboards };
        },
        onSettled() {
            if (
                !queryClient.isMutating({
                    mutationKey: dashboardQueryKeys.deleteDashboardsMutation
                })
            ) {
                // We want to invalidate health queries as this will change as monitors are removed
                queryClient.invalidateQueries(dashboardQueryKeys.states);
                queryClient.invalidateQueries(workspaceQueryKeys.states);
                queryClient.invalidateQueries(dashboardQueryKeys.list);
            }
        }
        // We can't easily implement an onError since it's hard to figure out which dashboard delete failed
        // We can just rely on the onSettled to invalidate the queries for us
    });
}
