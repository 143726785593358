import { SortingState } from '@tanstack/react-table';
import { useRef } from 'react';

/**
 * Initial state for visualisations that support it
 * Only used by the table visualisation currently.
 * Needs to be passed down in a ref to avoid re-renders.
 */
export interface VisualizationState {
    globalFilter?: string;
    sorting?: SortingState;
}

export const useVisualizationState = () => {
    /**
     * The initial visualisation state is stored as a ref.
     * Because it's only used on initial render/mount of a visualisation (currently only for fullscreen tiles).
     * So any updates to initial state shouldn't trigger a re-render.
     */
    const visualizationState = useRef<VisualizationState>({});

    const onVisualizationStateChange = (state: VisualizationState) => {
        visualizationState.current = {
            ...visualizationState.current,
            ...state
        };
    };

    return {
        visualizationState,
        onVisualizationStateChange
    };
};
