import { Switch } from '@/components/Switch';
import { cn } from '@/lib/cn';
import { ComponentPropsWithoutRef, useEffect, useState } from 'react';
import { CurrencyCode } from '../Paddle';

export const ModalPanel = ({ ...props }: ComponentPropsWithoutRef<'div'>) => (
    <>
        <hr className='border-dividerPrimary' />
        <div className='flex flex-col gap-4 px-8 py-4' {...props} />
    </>
);

export type BillingCycle = 'monthly' | 'annual';

export const useBillingCycleSwitch = ({
    disabled,
    defaultValue,
    expand
}: { disabled?: boolean; defaultValue?: BillingCycle; expand?: boolean } = {}) => {
    const [billingCycle, setBillingCycle] = useState<BillingCycle>(defaultValue ?? 'annual');

    // reset if the default value is changed
    useEffect(() => {
        if (defaultValue) {
            setBillingCycle(defaultValue);
        };
    }, [defaultValue]);

    return {
        billingCycle,
        BillingCycleSwitch: () => (
            <Switch
                className={cn('self-center', expand && 'flex-grow w-full')}
                itemClassName={cn(expand && 'flex-1 justify-center')}
                options={[
                    {
                        label: 'Monthly',
                        value: 'monthly'
                    },
                    {
                        label: 'Annual (save up to 20%)',
                        value: 'annual'
                    }
                ]}
                value={billingCycle}
                onValueChange={(value) => setBillingCycle(value === 'monthly' ? 'monthly' : 'annual')}
                disabled={disabled}
                defaultValue={defaultValue}
            />
        )
    };
};

export const CurrencySwitch = ({
    isVisible,
    setCurrency,
    value
}: {
    value: CurrencyCode;
    isVisible: boolean;
    setCurrency: (code: CurrencyCode) => void;
}) => {
    if (!isVisible) {
        return null;
    }

    return (
        <Switch
            className='self-center'
            options={[
                { label: 'USD', value: 'USD' },
                { label: 'GBP', value: 'GBP' },
                { label: 'EUR', value: 'EUR' }
            ]}
            value={value}
            onValueChange={(v) => setCurrency(v as CurrencyCode)}
        />
    );
};
