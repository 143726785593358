import { Button } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { cn } from '@/lib/cn';
import { useDOMElement } from 'components/hooks/useDOMElement';
import Tooltip from 'components/tooltip/Tooltip';
import { TruncatedText } from 'components/TruncatedText';
import { ControlButton } from 'reactflow';
import { useLayoutType, useMapId, useSetLayoutType } from '../context/NetworkMapStoreContext';
import { LayoutTypes } from '../layout/types';
import { LayoutIcon } from './LayoutIcon';

export const LayoutNames = {
    [LayoutTypes.network]: 'Network',
    [LayoutTypes.hierarchyVertical]: 'Vertical',
    [LayoutTypes.hierarchyHorizontal]: 'Horizontal'
};

export const LayoutButton: React.FC = () => {
    const container = useDOMElement(useMapId());

    const layoutType = useLayoutType();
    const setLayoutType = useSetLayoutType();

    return (
        <ControlButton className='relative'>
            <DropdownMenu>
                <Tooltip title='Layout'>
                    <DropdownMenu.Trigger>
                        <Button
                            variant='tertiary'
                            className='absolute inset-0 flex items-center justify-center w-full h-full p-0'
                        >
                            <LayoutIcon layoutType={layoutType} />
                        </Button>
                    </DropdownMenu.Trigger>
                </Tooltip>

                <DropdownMenu.Menu
                    align='end'
                    side='right'
                    sideOffset={4}
                    className='text-sm bg-secondaryButtonBackground'
                    container={container}
                >
                    {Object.keys(LayoutTypes)
                        .filter((layout: any) => !isNaN(Number(layout)))
                        .map((layout) => {
                            const layoutValue = Number(layout) as keyof typeof LayoutNames;

                            return (
                                <DropdownMenu.Item
                                    key={layoutValue}
                                    onSelect={() => setLayoutType(layoutValue)}
                                    icon={<LayoutIcon layoutType={layoutValue} />}
                                    className={cn(
                                        'data-[highlighted]:bg-secondaryButtonBackgroundHover',
                                        layoutType === layoutValue && 'bg-filterActive'
                                    )}
                                >
                                    <TruncatedText title={LayoutNames[layoutValue]} />
                                </DropdownMenu.Item>
                            );
                        })}
                </DropdownMenu.Menu>
            </DropdownMenu>
        </ControlButton>
    );
};
