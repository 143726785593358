import { cn } from '@/lib/cn';
import clsx from 'clsx';
import { useEveryoneGroup } from 'components/hooks/useEveryoneGroup';
import useSafeStateChange from 'lib/useSafeStateChange';
import { useCustomTypes } from 'queries/hooks/useCustomTypes';
import { useDataStreamDefinitions } from 'queries/hooks/useDataStreamDefinitions';
import { useGroupsForACLEditor } from 'queries/hooks/useGroupsForACLEditor';
import { useUsersForACLEditor } from 'queries/hooks/useUsersForACLEditor';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import AppRoutes from 'routes/AppRoutes';
import API from 'services/API';
import { ListPluginSourceConfigs, PLUGIN_SOURCES } from 'services/SourceConfigService';
import { RecordUserLogin } from 'services/UserService';
import ErrorFallback from 'ui/errorHandling/ErrorFallback';
import GlobalSearch from 'ui/globalSearch/GlobalSearch';
import { useEnsureAppConfiguration } from 'ui/hooks/useEnsureAppConfiguration';
import NavBar from 'ui/nav/Navbar';
import { socketCleanup, socketConnect } from 'ui/notifications/WebSocketNotifications';
import Config from '../../config';
import AppContextWrapper from './AppContextWrapper';

function Wrapper() {
    const safeStateChange = useSafeStateChange();
    const queryClient = useQueryClient();
    const location = useLocation();
    const { tenant } = useEnsureAppConfiguration();

    const isSettingsPage = location.pathname.includes('/settings/');
    const isLightBackground = ['/settings/', '/explorer/', '/scopes/', '/scope/', '/monitoring/'].some(
        (lightPagePath) => location.pathname.includes(lightPagePath)
    );

    // Preload static(-ish) data so it's available immediately when we need it, to keep the UI responsive.
    useGroupsForACLEditor();
    useUsersForACLEditor();
    useDataStreamDefinitions();
    useEveryoneGroup();
    useCustomTypes(tenant);

    // Connect to the webhook, and start listening for messages
    useEffect(() => {
        if (tenant) {
            socketConnect(tenant.id, Config.Websocket, queryClient);

            return () => {
                socketCleanup();
            };
        }
    }, [tenant, queryClient]);

    // Record user sign-in to the tenant. Debatable whether this should only be once per login,
    // or every time the app loads, but as we use it to detect dormant tenants it's better to be safe
    // than sorry and record more frequently than strictly necessary.
    useEffect(() => {
        if (tenant) {
            RecordUserLogin(tenant.id);
        }
    }, [tenant]);

    useEffect(() => {
        let isMounted = true;

        if (!tenant) {
            // Don't start working until we know we've loaded the right app version for the tenant.
            return;
        }

        const preloadResources = async () => {
            await Promise.all([queryClient.prefetchQuery(PLUGIN_SOURCES, ListPluginSourceConfigs)]);

            if (isMounted) {
                const debugEnabled = tenant.debugEnabledUntil ? tenant.debugEnabledUntil > Date.now() : false;
                if (debugEnabled) {
                    // eslint-disable-next-line no-console
                    console.debug(`Debug logging enabled until ${new Date(tenant.debugEnabledUntil!).toString()}`);
                }

                // Pass the tenant debug flag to the server, so it doesn't need to look it up.
                API.defaults.headers.common['squp-tenant-flag-debug'] = tenant.debugEnabledUntil ?? 0;
            }
        };

        preloadResources();

        return () => {
            isMounted = false;
        };
    }, [queryClient, safeStateChange, tenant]);

    return (
        <AppContextWrapper>
            <div
                id='wrapper'
                className={cn(
                    'relative z-0 w-full h-full overflow-hidden',
                    isLightBackground ? 'bg-backgroundSecondary' : 'bg-backgroundPrimary'
                )}
            >
                {!isSettingsPage && <GlobalSearch />}
                <div className='grid grid-cols-[max-content_minMax(0,1fr)] w-full h-full overflow-hidden'>
                    <NavBar />
                    <div className='flex-col flex-grow h-full min-w-0 transition-colors willChangeWidth scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                        <div id='sidebarPortal' />

                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <AppRoutes
                                render={(page, { innerContainerClassName, outerContainerClassName } = {}) => (
                                    <div
                                        id='dashDownloadAsImageContainer'
                                        className={clsx(
                                            'flex-grow clearfix w-full h-screen px-4 overflow-auto transition duration-200 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary',
                                            outerContainerClassName
                                        )}
                                    >
                                        <div className={clsx('w-full h-full px-6 mx-auto', innerContainerClassName)}>
                                            {page}
                                        </div>
                                    </div>
                                )}
                            />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            <div id='graphTooltip' />
        </AppContextWrapper>
    );
}

Wrapper.propTypes = {};

export default Wrapper;
