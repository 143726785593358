import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Delete, Workspace } from 'services/WorkspaceService';
import { invalidateWorkspaceQueries } from 'services/WorkspaceUtil';

const WorkspacesDeleteModal: React.FC<{ workspace: Workspace; onClose: () => void }> = ({
    workspace,
    onClose
}) => {
    const [isDeleting, setDeleting] = useState(false);
    const queryClient = useQueryClient();

    const handleDelete = async () => {
        setDeleting(true);

        await Delete(workspace.id);
        invalidateWorkspaceQueries(queryClient);
        setDeleting(false);

        onClose();
    };

    const description = 'Are you sure you want to permanently delete this workspace? All dashboards and collections in this workspace will be permanently deleted.';

    return (
        <Modal
            title={`Delete Workspace: ${workspace.displayName}`}
            description={description}
            close={onClose}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <ModalButtons>
                <Button onClick={onClose} variant='tertiary'>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    onClick={handleDelete}
                    disabled={isDeleting}
                    data-testid='confirmDelete'
                    variant='destructive'
                >
                    {isDeleting ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
};

export default WorkspacesDeleteModal;
