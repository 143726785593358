import { TruncatedText } from 'components/TruncatedText';
import { isEqual } from 'lodash';
import { useDataStreamDefinitions } from 'queries/hooks/useDataStreamDefinitions';
import { useDatasetContext } from '../../../contexts/DatasetContext';

export const DataStreamConfigurationStepSubLabel: React.FC = () => {
    const { config } = useDatasetContext();
    const { data: dataStreamDefinitions = [], isLoading } = useDataStreamDefinitions();

    if (isLoading) {
        return null;
    }

    const dataStream =
        config?.dataStream?.id == null ? undefined : dataStreamDefinitions.find((d) => d.id === config?.dataStream?.id);

    const presetName = dataStream?.definition.presetOf != null ? dataStream.displayName : undefined;

    if (presetName && isEqual(config?.dataStream?.dataSourceConfig, dataStream?.definition.dataSourceConfig)) {
        return <TruncatedText title={presetName} />;
    }

    const isLegacyConfiguredDataStream = dataStream?.sourceTemplate != null;

    if (config.dataStream?.dataSourceConfig != null || isLegacyConfiguredDataStream) {
        return <span>Configured</span>;
    }

    return <span>Configure parameters</span>;
};
