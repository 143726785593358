import { buildQuery } from '@squaredup/graph';
import { SqUpPluginId } from '@squaredup/ids';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import { orderBy } from 'lodash';
import { useDataStreamDefinitions } from 'queries/hooks/useDataStreamDefinitions';
import { useQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { SqUpPluginConfig } from 'utilities/injectSqUpDataSource';
import { useDatasetContext } from '../../../contexts/DatasetContext';
import { FilterQueryParameters } from '../../utilities/getObjectFilters';
import { useDataSources } from '../useDataSources';

/**
 * Gets all the sources for a data stream, filters them based on source IDs
 * @param dataStreamQuery optional gremlin snippet to query by data stream
 * @returns useQuery result of PluginSourceConfigs
 */
export const useObjectFilterSources = ({
    dataStreamQuery,
    queryParams
}: {
    dataStreamQuery?: string;
    queryParams: FilterQueryParameters;
}) => {
    const { isGlobal } = useDataStreamWorkspaceContext();
    const { config } = useDatasetContext();

    const { data: dataStreams = [] } = useDataStreamDefinitions();
    const dataStream = dataStreams.find((ds) => ds.id === config.dataStream?.id);

    const isSqUpDataStream = dataStream?.pluginId === SqUpPluginId.value;

    const { data: dataSourceConfigs } = useDataSources();

    return useQuery(
        ['source', dataStreamQuery, stringify(isGlobal)],
        async () => {
            const sourcesQuery = dataStreamQuery + '.values("__configId").dedup()';

            const { gremlinQueryResults: availableSourceIds } = await Query(
                buildQuery(queryParams, sourcesQuery),
                isGlobal ? 'directOrAnyWorkspaceLinks' : undefined
            );

            return availableSourceIds;
        },
        {
            enabled: dataStreamQuery !== undefined,
            select: (data) => {
                const configs = (dataSourceConfigs || []).filter(({ id }) => data.includes(id));
                return orderBy(isSqUpDataStream ? [...configs, SqUpPluginConfig] : configs, [
                    ({ displayName }) => displayName?.toLowerCase()
                ]);
            }
        }
    );
};
