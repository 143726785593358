import { SimpleTile } from 'dashboard-engine/basetiles/types';
import graphCustom from '../datasources/graph/graphCustom/graphCustom';

const tile: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: graphCustom,
        config: {
            'gremlinQuery': 'g.V().has("sourceType", "squaredup/space").valueMap(true)'
        }
    },
    transforms: [
        {
            type: 'custom',
            config: (data: any) => {
                return data.map((node: any) => node.sourceId[0]);
            }
        }
    ],
    visualisation: {
        type: 'workspace-network'
    }
};

export default tile;
