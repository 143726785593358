/*
 * This is a modified version of the original BarItem component from the nivo v0.68 library.
 * Allowing us to customize the label position rendering.
 * https://github.com/plouc/nivo/blob/v0.68.0/packages/bar/src/BarItem.js
 *
 * This will need to be refactored if Nivo is updated, as the original component has changed in later versions.
 */
import { BarExtendedDatum, BarItemProps, GraphicsContainer } from '@nivo/bar';
import { BasicTooltip } from '@nivo/tooltip';
import React from 'react';
import {
    barGraphCharacterWidth,
    barGraphLabelAlignRightMinWidth,
    barGraphLabelAlignTopMinHeight,
    barGraphLabelHorizontalOffset,
    barGraphLabelVerticalOffset
} from './Config';

const DataStreamBarItem = (
    props: BarItemProps & {
        getTooltipLabel?: (data: BarExtendedDatum) => string;
        labelSkipHeight?: number;
        labelSkipWidth?: number;
        data: BarExtendedDatum & {
            fill?: string;
        };
        overflowLabelsHorizontal?: boolean;
        overflowLabelsVertical?: boolean;
    }
) => {
    const {
        data,
        x,
        y,
        width,
        height,
        borderRadius,
        color,
        borderWidth,
        borderColor,
        labelColor,
        showTooltip,
        hideTooltip,
        onClick,
        onMouseEnter,
        onMouseLeave,
        overflowLabelsVertical,
        overflowLabelsHorizontal,
        label,
        theme,
        tooltip,
        shouldRenderLabel,
        tooltipFormat,
        getTooltipLabel
    } = props;

    const nivoTooltip = (
        <BasicTooltip
            id={getTooltipLabel?.(data)}
            value={data.value}
            enableChip={true}
            color={color}
            format={tooltipFormat as any}
            renderContent={(typeof tooltip === 'function' ? tooltip.bind(null, { color, ...data }) : null) as any}
        />
    );

    const handleTooltip = (e: React.MouseEvent<GraphicsContainer>) => showTooltip(nivoTooltip, e);

    const handleMouseEnter = (e: React.MouseEvent<GraphicsContainer>) => {
        onMouseEnter(data, e);
        showTooltip(nivoTooltip, e);
    };

    const handleMouseLeave = (e: React.MouseEvent<GraphicsContainer>) => {
        onMouseLeave(data, e);
        hideTooltip();
    };

    const handleMouseClick = (e: React.MouseEvent<GraphicsContainer>) => {
        onClick(data, e);
    };

    const labelLength = label?.length ?? 0;
    const hideLabel =
        labelLength === 0 ||
        width < labelLength * barGraphCharacterWidth ||
        (height < barGraphLabelAlignTopMinHeight && !overflowLabelsVertical);

    // if height/width is too small, align the label above/to the right of the bar depending on layout.
    const shouldLabelAlignTop = height < barGraphLabelAlignTopMinHeight && overflowLabelsVertical;
    const shouldLabelAlignRight = width < barGraphLabelAlignRightMinWidth && overflowLabelsHorizontal;

    const labelY = shouldLabelAlignTop ? -barGraphLabelVerticalOffset : height / 2;
    const labelX = shouldLabelAlignRight ? width + barGraphLabelHorizontalOffset : width / 2;
    const labelFill = shouldLabelAlignTop || shouldLabelAlignRight ? 'currentColor' : labelColor;

    return (
        <g transform={`translate(${x}, ${y})`}>
            <rect
                width={width}
                height={height}
                rx={borderRadius}
                ry={borderRadius}
                fill={data.fill ? data.fill : color}
                strokeWidth={borderWidth}
                stroke={borderColor}
                onMouseEnter={handleMouseEnter}
                onMouseMove={handleTooltip}
                onMouseLeave={handleMouseLeave}
                onClick={handleMouseClick}
            />
            {shouldRenderLabel && !hideLabel && (
                <text
                    x={labelX}
                    y={labelY}
                    textAnchor={shouldLabelAlignRight ? 'start' : 'middle'}
                    dominantBaseline='central'
                    style={{
                        ...theme.labels?.text,
                        pointerEvents: 'none',
                        fill: labelFill
                    }}
                >
                    {label}
                </text>
            )}
        </g>
    );
};

export default DataStreamBarItem;
