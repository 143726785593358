import { TileConfig } from '@squaredup/dashboards';
import type { ClientDataStreamRequest, DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { baseDataStreamConfig, TileTypes } from 'dashboard-engine/constants';
import { formatDistance } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { getDataStreamDataFn, parseUseDataStreamArgs } from './useDataStream';
import { useDataStreamConfigToRequest } from './useDataStreamConfigToRequest';
import { useIsFetchingQuery } from './useIsFetchingQuery';

export const useForceRefetch = (tileConfig: TileConfig) => {
    const queryClient = useQueryClient();

    const { isGlobal, workspace } = useDataStreamWorkspaceContext();
    const workspaceId = isGlobal ? null : workspace;

    const { dataStreamRequest, isConfigured } = useDataStreamConfigToRequest(
        tileConfig._type === TileTypes.dataStream ? (tileConfig as DataStreamBaseTileConfig) : baseDataStreamConfig
    );

    const args = useMemo(() => parseUseDataStreamArgs(dataStreamRequest), [dataStreamRequest]);

    const forceRefetch = useCallback(() => {
        if (args.isDataStreamSelected && args.dataStreamRequest) {
            const ignoreCacheRequest: ClientDataStreamRequest = {
                ...args.dataStreamRequest,
                options: { ...args.dataStreamRequest.options, noCacheRead: true }
            };

            const queryFn = getDataStreamDataFn(
                { isDataStreamSelected: true, dataStreamRequest: ignoreCacheRequest },
                workspaceId,
                undefined,
                false
            );

            return queryClient.fetchQuery(args.queryKey, queryFn);
        }
    }, [args, queryClient, workspaceId]);

    const isRefetching = useIsFetchingQuery(args.queryKey);
    const isEnabled = isConfigured && args.queryKey;
    const queryState = queryClient.getQueryState(args.queryKey);
    const lastRefetched = queryState?.dataUpdatedAt
        ? `Last refreshed: ${formatDistance(queryState.dataUpdatedAt, Math.max(Date.now(), queryState.dataUpdatedAt), {
              addSuffix: true
          })}`
        : '';

    return useMemo(
        () => ({
            forceRefetch,
            lastRefetched,
            isRefetching,
            isEnabled
        }),
        [isEnabled, isRefetching, lastRefetched, forceRefetch]
    );
};
