import { oneMinute } from 'queries/constants';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { GetSubscription } from 'services/TenantService';

type QueryReturnType = Awaited<ReturnType<typeof GetSubscription>>;

/*
 * useQuery returning the current tenant subscription
 * @param options The react-query options e.g. refreshInterval
 * @returns The current tenant subscription
 */
export const useSubscription = <T = QueryReturnType>(
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) => useQuery(
    tenantQueryKeys.subscription,
    GetSubscription,
    {
        staleTime: oneMinute,
        cacheTime: oneMinute,
        ...options
    }
);
