import type { DatasourceConfig } from '@squaredup/dashboards';
import type { VisualisationConfig } from '@squaredup/data-streams';
import { SimpleTile } from 'dashboard-engine/basetiles/types';

const custom: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {} as DatasourceConfig,
    transforms: [],
    visualisation: {} as VisualisationConfig
};

export default custom;
