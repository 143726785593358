import Text from '@/components/Text';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Constants from '@squaredup/constants';
import { getAllowedMonitorFrequency } from '@squaredup/monitoring';
import { getFeatureLimit } from '@squaredup/tenants';
import { defaultTimeframeEnum, TimeframeEnumValue } from '@squaredup/timeframes';
import Button from 'components/button/Button';
import { useDOMElement } from 'components/hooks/useDOMElement';
import trackEvent from 'lib/analytics';
import { useDataStreamDefinition } from 'queries/hooks/useDataStreamDefinition';
import { useTier } from 'queries/hooks/useTier';
import type { FC } from 'react';
import TimeframeChooser from 'ui/timeframe/TimeframeChooser';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useTileEditorStepsContext } from '../../contexts/TileEditorStepsContext';
import { EditorSteps } from '../constants';
import { useDataStreamSupportedTimeframes } from '../hooks/useDataStreamSupportedTimeframes';
import { sanitiseFrequency } from '../monitoring/sanitiseFrequency';

export const TimeframeStep: FC = () => {
    const { nextStep, setCurrentEditorStep } = useTileEditorStepsContext();
    const { config, setConfig } = useDatasetContext();
    const { data: datastream } = useDataStreamDefinition(config.dataStream?.id);
    const container = useDOMElement('dialogContent');
    const { data: tier } = useTier();
    const monitoringIntervalLimit = tier
        ? getFeatureLimit(tier, 'monitorFrequency', Constants.monitorFrequencyDefaultMinutes)
        : { value: Constants.monitorFrequencyDefaultMinutes };
    const monitoringIntervalLimitMins =
        'isUnlimited' in monitoringIntervalLimit && monitoringIntervalLimit.isUnlimited
            ? 0
            : 'value' in monitoringIntervalLimit
            ? monitoringIntervalLimit.value
            : Constants.monitorFrequencyDefaultMinutes;

    const isMonitored = Object.keys(config.monitor || {}).length > 0;
    const isKPI = Object.keys(config.kpi || {}).length > 0;

    const supportedTimeframes = useDataStreamSupportedTimeframes(config.dataStream?.id);

    const handleUpdateTimeframe = (newTimeframe: TimeframeEnumValue | null) => {
        const updatedConfig = { ...config };

        if (config.monitor || config.kpi) {
            updatedConfig.timeframe = newTimeframe ?? defaultTimeframeEnum;
        } else if (newTimeframe) {
            updatedConfig.timeframe = newTimeframe;
        } else if (newTimeframe === null) {
            updatedConfig.timeframe = undefined;
        }

        if (newTimeframe && updatedConfig?.monitor?.frequency) {
            // Set the frequency to something appropriate for the new timeframe.
            const suggestedDefault = getAllowedMonitorFrequency(newTimeframe).defaultMinutes;
            updatedConfig.monitor.frequency = sanitiseFrequency(
                newTimeframe,
                monitoringIntervalLimitMins,
                suggestedDefault
            );
        }

        setConfig(updatedConfig);

        trackEvent('Tile Timeframe Selected', { timeframe: updatedConfig.timeframe });
    };

    return (
        <div className='flex flex-col h-full px-6 pr-5 overflow-auto text-sm py-7 tile-scroll-overflow'>
            <div>
                <div className='items-center' data-testid='tileEditorTimeframeChooser'>
                    <Text.H3 className='mb-4 text-textPrimary'>Timeframe</Text.H3>
                    <Text.Body className='mb-4'>
                        By default, tiles use the timeframe set at the dashboard level, but you can also set a fixed
                        timeframe here.{' '}
                        <a
                            href='https://docs.squaredup.com/first-steps/dashboards#dashboard-timeframe'
                            className='text-textLink'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Learn more.
                        </a>
                    </Text.Body>
                    <TimeframeChooser
                        container={container}
                        currentTimeframe={config.timeframe}
                        allowNullSelection={!isMonitored && !isKPI}
                        supportsNoneTimeframe={datastream?.definition.supportsNoneTimeframe}
                        forceSelection={isMonitored}
                        forceSelectionKPI={isKPI}
                        alignment='start'
                        onChange={handleUpdateTimeframe}
                        title={'Tile timeframe'}
                        supportedTimeframes={supportedTimeframes}
                    />
                </div>

                {datastream?.definition.requiresParameterTimeframe && (
                    <Text.Body className='mt-6'>
                        <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className='h-8 mb-1 mr-2 align-middle text-statusWarningPrimary'
                            fixedWidth
                        />
                        This data stream requires you to include the timeframe in the&nbsp;
                        <Button onClick={() => setCurrentEditorStep(EditorSteps.query)} variant={'link'}>
                            Parameters
                        </Button>
                        &nbsp;step.
                        <a
                            href='https://docs.squaredup.com/first-steps/tiles/data-tiles#timeframe'
                            className='ml-2 text-textLink'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Learn more.
                        </a>
                    </Text.Body>
                )}
            </div>

            <div className='flex items-end flex-1 pr-4 mt-4 text-base md:justify-end'>
                <div>
                    <Button variant='primary' onClick={nextStep}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
};
