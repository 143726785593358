import { CustomLayerProps } from '@nivo/line';
import { motion } from 'framer-motion';
import { FC, Fragment, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { spring } from './transition';

interface LineGraphLineProps extends Pick<CustomLayerProps, 'lineGenerator'> {
    color: string;
    hoverId: string | number | undefined;
    strokeWidth: number;
    lineId: string | number;
    points: Record<'x' | 'y', number>[];
    onLineLeave: () => void;
    onLineHover: (id: string | number) => void;
    onMouseMove: (e: React.MouseEvent) => void;
    drilldownUrl?: string;
}

/**
 * Determine the line style
 * @param lineId Current line ID
 * @param hoverId ID of line being hovered
 * @param strokeWidth Default stroke width
 * @returns Opacity and stroke width
 */
const getHoverStyle = (lineId: string | number, hoverId: string | number | undefined, strokeWidth: number) => {
    if (!hoverId) {
        return {
            opacity: 1,
            strokeWidth
        };
    }
    if (hoverId === lineId) {
        return {
            opacity: 1,
            strokeWidth: strokeWidth * 1.5
        };
    } else {
        return {
            opacity: 0.2,
            strokeWidth
        };
    }
};

/**
 *
 * @returns an individual line graph line for render that triggers its own hover events
 */
export const LineGraphLine = ({
    points,
    lineId,
    hoverId,
    strokeWidth,
    color,
    onLineHover,
    onLineLeave,
    onMouseMove,
    lineGenerator,
    drilldownUrl
}: LineGraphLineProps) => {
    const path = useMemo(() => lineGenerator(points), [lineGenerator, points]);

    const handleMouseEnter = () => {
        onLineHover(lineId);
    };

    const handleMouseLeave = () => {
        onLineLeave();
    };

    const style = getHoverStyle(lineId, hoverId, strokeWidth);

    const Container: FC =
        drilldownUrl == null ? Fragment : ({ children }) => <NavLink to={drilldownUrl}>{children}</NavLink>;

    return (
        <Container>
            <g>
                <motion.path
                    fill='none'
                    stroke={color}
                    strokeWidth={style.strokeWidth}
                    animate={{
                        opacity: style.opacity,
                        d: path
                    }}
                    transition={spring}
                    pointerEvents='none'
                />
                <path
                    d={path}
                    fill='none'
                    stroke='transparent'
                    strokeWidth={15} // Adjusts transparent hover buffer size
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={handleMouseEnter}
                    onMouseMove={onMouseMove}
                    strokeLinejoin='round'
                    pointerEvents='stroke' // Only enables hover events when the stroke is visible
                />
            </g>
        </Container>
    );
};
