import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Presence } from 'components/Presence';
import Button from 'components/button';
import DropdownMenu from 'components/dropdownMenu';
import Input from 'components/forms/input/Input';
import Text from 'components/primitives/components/Text';
import { WorkspaceStateIndicator } from 'components/ui/state/WorkspaceStateIndicator';
import { useAppContext } from 'contexts/AppContext';
import { checkFileTypeIsImage, imageExtensions, readFileAsDataURL, workspaceAvatarMaxSizeInBytes } from 'lib/blobs';
import { iconNameValid, workspacePredefinedIconKey } from 'lib/fontawesome/fontawesome';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { ComponentPropsWithoutRef, createContext, useContext, useEffect, useRef, useState } from 'react';
import { Controller, ControllerRenderProps, FieldValues, useController, useFormContext } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Tabs } from 'ui/Tabs';

export const WorkspaceAvatarPicker = () => {
    const queryClient = useQueryClient();
    const appCtx = useAppContext();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const formCtx = useFormContext();
    const [tabIndex, setTabIndex] = useState(formCtx.getValues('avatar')?.startsWith(workspacePredefinedIconKey) ? 1 : 0);

    if (
        appCtx.currentWorkspaceID &&
        queryClient.getQueryState(workspaceQueryKeys.avatar(appCtx.currentWorkspaceID))?.status === 'loading'
    ) {
        return <Skeleton className=' h-[9.15rem] w-[9.15rem] rounded-input mx-auto' />;
    }

    return (
        <Controller
            name='avatar'
            render={(props) => (
                <>
                    <div
                        className={cn(
                            'group h-[9.15rem] w-[9.15rem] relative rounded-input overflow-hidden bg-transparent border-4 border-componentBackgroundPrimary ring-1 ring-outlinePrimary mx-auto flex items-center justify-center',
                            props.field.value && !props.field.value?.startsWith(workspacePredefinedIconKey) && 'bg-white'
                        )}
                    >
                        <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
                            <DropdownMenu.Button
                                className='absolute inset-0 z-10 opacity-0 peer'
                                aria-label='open file picker'
                            />
                            <DropdownMenu.Menu className='max-w-[22rem] mt-2 translate-x-1' align='end'>
                                <TabCtx.Provider
                                    value={{
                                        input: inputRef.current,
                                        field: props.field,
                                        closeDropdown: () => setIsDropdownOpen(false)
                                    }}
                                >
                                    <Tabs
                                        tabs={tabs}
                                        tabsID='avatarPickerTabs'
                                        selectedIndex={tabIndex}
                                        onSelect={setTabIndex}
                                    />
                                </TabCtx.Provider>
                            </DropdownMenu.Menu>
                        </DropdownMenu>

                        <Presence isPresent={!props.field.value}>
                            <WorkspaceStateIndicator id='' size={72} />
                        </Presence>

                        {props.field.value && !props.field.value?.startsWith(workspacePredefinedIconKey) && (
                            <img
                                className='absolute inset-0 object-contain w-full h-full'
                                alt="workspace's avatar"
                                src={props.field.value}
                            />
                        )}
                        {props.field.value && props.field.value?.startsWith(workspacePredefinedIconKey) && (
                            <FontAwesomeIcon
                                className='absolute inset-0 w-full h-full text-textPrimary'
                                icon={props.field.value.replace(workspacePredefinedIconKey, '')}
                            />
                        )}

                        <div className='absolute inset-0 flex items-center justify-center text-4xl transition-opacity opacity-0 pointer-events-none group-hover:opacity-100 peer-aria-expanded:opacity-100 text-textSecondary after:absolute after:inset-0 after:bg-backgroundSecondary after:opacity-80'>
                            <FontAwesomeIcon icon={faCamera} className='z-10' />
                        </div>
                    </div>

                    <Presence isPresent={Boolean(errorMsg)}>
                        <p className='text-center text-textDestructive first-letter:capitalize'>{errorMsg}</p>
                    </Presence>

                    <input
                        ref={inputRef}
                        className='hidden'
                        data-testid='workspaceAvatarInput'
                        type='file'
                        accept={imageExtensions.join(',')}
                        onChange={async (e) => {
                            try {
                                setErrorMsg(null);
                                const newWorkspaceAvatarFile = e.target.files?.item(0);
                                if (!newWorkspaceAvatarFile) {
                                    return;
                                }

                                if (!checkFileTypeIsImage(newWorkspaceAvatarFile)) {
                                    throw new Error('The file uploaded was not compatible (PNG, JPG, SVG, GIF, WEBP).');
                                }

                                if (newWorkspaceAvatarFile.size > workspaceAvatarMaxSizeInBytes) {
                                    throw new Error(`File cannot exceed ${workspaceAvatarMaxSizeInBytes / 1024} KB`);
                                }

                                const newWorkspaceAvatarUrl = await readFileAsDataURL(newWorkspaceAvatarFile);

                                props.field.onChange(newWorkspaceAvatarUrl);
                            } catch (error) {
                                if (error instanceof Error) {
                                    setErrorMsg(error.message);
                                }
                            }
                        }}
                    />
                </>
            )}
        />
    );
};

const ImageTab = () => {
    const tabCtx = useTabCtx();

    return (
        <Container>
            <DropdownMenu.Item
                onSelect={() => {
                    if (!tabCtx.input) {
                        throw new Error('file uploader is missing from the dom');
                    }
                    tabCtx.input.value = '';
                    tabCtx.input.click();
                }}
            >
                Upload image
            </DropdownMenu.Item>
            <Presence isPresent={tabCtx.field.value && !tabCtx.field.value?.startsWith(workspacePredefinedIconKey)}>
                <DropdownMenu.Item className='text-textDestructive' onSelect={() => tabCtx.field.onChange(null)}>
                    Delete image
                </DropdownMenu.Item>
            </Presence>
            <DropdownMenu.Separator />
            <Text.Body className='px-3 py-2 text-textSecondary'>
                Hint: use a square image for the best display results.
            </Text.Body>
        </Container>
    );
};

const IconTab = () => {
    const tabCtx = useTabCtx();
    const formCtx = useFormContext();

    useEffect(() => document.getElementById('iconName')?.focus());

    const submitHandler = async () => {
        const iconName = formCtx.getValues('iconName')?.toLowerCase();

        if (!iconNameValid(iconName)) {
            return;
        }

        tabCtx.field.onChange(workspacePredefinedIconKey + iconName);

        tabCtx.closeDropdown();
    };

    return (
        <div className='h-full px-2 py-4'>
            <Input
                placeholder='Enter a FontAwesome icon name'
                name='iconName'
                prepend={formCtx.getValues('iconName') && <PreviewIcon />}
                validation={{
                    validate: (iconName: string) => iconNameValid(iconName.toLowerCase()) || 'Unrecognised icon name.'
                }}
            />
            <p className='mt-2 ml-4 text-textSecondary'>
                Click{' '}
                <Button href='https://fontawesome.com/icons' variant='link' type='button'>
                    here
                </Button>{' '}
                to see the full list of Font Awesome icon names (style: Solid only).
            </p>
            <DropdownMenu.Separator className='my-4' />
            <div className='flex items-center'>
                <Presence isPresent={tabCtx.field.value?.startsWith(workspacePredefinedIconKey)}>
                    <Button
                        variant='link'
                        className='text-textDestructive'
                        onClick={() => {
                            tabCtx.field.onChange(null);
                            formCtx.resetField('iconName');
                            tabCtx.closeDropdown();
                        }}
                    >
                        Remove icon
                    </Button>
                </Presence>
                <Button
                    className='ml-auto'
                    disabled={!iconNameValid(formCtx.getValues('iconName')?.toLowerCase())}
                    onClick={submitHandler}
                >
                    Apply
                </Button>
            </div>
        </div>
    );
};

const PreviewIcon = () => {
    const formCtx = useController({ name: 'iconName' });
    const iconName = formCtx.field.value?.toLowerCase();

    if (!iconName || !iconNameValid(iconName)) {
        return null;
    }

    return <FontAwesomeIcon icon={iconName} className='text-textPrimary' />;
};

const tabs = [
    {
        label: 'Image',
        component: <ImageTab />,
        key: 'Image'
    },
    {
        label: 'Icon',
        component: <IconTab />,
        key: 'Icon'
    }
];

type TabCtxProps = {
    field: ControllerRenderProps<FieldValues, 'avatar'>;
    input: HTMLInputElement | null;
    closeDropdown: () => void;
};

const TabCtx = createContext<TabCtxProps | undefined>(undefined);

const useTabCtx = () => {
    const tabCtx = useContext(TabCtx);

    if (!tabCtx) {
        throw new Error('useTabCtx must be used within a TabCtx.Provider');
    }

    return tabCtx;
};

const Container = (props: ComponentPropsWithoutRef<'div'>) => {
    return <div {...props} className='h-full py-2' />;
};
