import { Divider } from '@/components/Divider';
import { AccordionMultipleProps } from '@radix-ui/react-accordion';
import { number, StreamDataColumn } from '@squaredup/data-streams';
import Field from 'components/forms/field/Field';
import { defaultWrapperClassName } from 'components/forms/input/Input';
import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { VisualisationConfigAccordion } from '../VisualisationConfigAccordion';
import { DataStreamGaugeConfig } from './Config';

/**
 * Filter the given columns to those that are valid for use with the gauge visualization
 * @param initialColumns Metadata columns
 * @returns Filtered set of columns
 */
export const getValidColumns = (initialColumns: StreamDataColumn[]) => {
    const values: StreamDataColumn[] = initialColumns.filter((c) => c.role === 'value');
    const numberShapes: StreamDataColumn[] = initialColumns.filter(
        (c) => c.valueShapeName === number.name && c.role !== 'value'
    );
    return [...values, ...numberShapes];
};

function DataStreamGaugeChartOptionsForm({
    config,
    accordionControls,
    onChange
}: {
    config: DataStreamGaugeConfig;
    accordionControls: AccordionMultipleProps;
    onChange: (action: VisualisationOptionAction) => void;
}) {
    const defaultValues: DataStreamGaugeConfig = {
        label: config?.label ?? '',
        minimum: config?.minimum ?? 0,
        maximum: config?.maximum ?? 100
    };

    const formMethods = useForm<DataStreamGaugeConfig>({
        mode: 'onChange',
        defaultValues
    });

    const { watch, reset, register, handleSubmit } = formMethods;

    useEffect(() => {
        // Cast required to get TS to allow validation before submit
        // Works fine but underlying types don't quite match
        const subscription = watch(handleSubmit((data) => onChange({ action: 'form-change', data })) as any);
        return () => subscription.unsubscribe();
    }, [watch, onChange, handleSubmit]);

    useEffect(() => {
        if (isEmpty(config)) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, config, reset]);

    const labelChange = (label: string) => {
        onChange({ action: 'label-text', data: label });
    };

    return (
        <FormProvider {...formMethods}>
            <form className='w-full'>
                <VisualisationConfigAccordion
                    value='monitoring'
                    label='Monitoring'
                    accordionControls={accordionControls}
                >
                    <p className='text-sm text-textSecondary'>
                        To monitor this value with thresholds configure Monitoring on this tile using the tab above.
                    </p>
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion value='options' label='Options' accordionControls={accordionControls}>
                    <Field
                        label='Range'
                        help='Specify the min and max of the gauge.'
                        helpAlign='Left'
                        tooltipAlign='Right'
                    >
                        <div className='flex items-center justify-between space-x-4'>
                            <input
                                type='number'
                                className={defaultWrapperClassName}
                                {...register('minimum', {
                                    setValueAs: (value) => (isNaN(parseInt(value)) ? 0 : parseInt(value))
                                })}
                                placeholder='0'
                            />
                            <p>to</p>
                            <input
                                type='number'
                                className={defaultWrapperClassName}
                                {...register('maximum', {
                                    setValueAs: (value) => (isNaN(parseInt(value)) ? 100 : parseInt(value))
                                })}
                                placeholder='100'
                            />
                        </div>
                    </Field>

                    <Field label='Label'>
                        <input
                            type='text'
                            className={defaultWrapperClassName}
                            {...register('label')}
                            maxLength={250}
                            placeholder='Enter a label'
                            onChange={(e) => labelChange(e.target.value)}
                        />
                    </Field>
                </VisualisationConfigAccordion>
            </form>
        </FormProvider>
    );
}

export default DataStreamGaugeChartOptionsForm;
