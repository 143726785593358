import { ClientDataStreamRequest, findColumn, required, StreamData } from '@squaredup/data-streams';
import { debounceAsync, type DataStreamSearchConfig } from '@squaredup/utilities';
import { memoize, orderBy, uniqBy } from 'lodash';
import { requestData } from 'services/DataStreamService';
import { AutocompleteOption, ensureOptions, getAutoCompleteLabelText, OptionsLoader } from './autocompleteOptions';

/**
 * Convert data stream data with value and (optionally) label columns to autocomplete options
 */
const streamDataToOptions = (streamData: StreamData) => {
    if (streamData.rows.length === 0) {
        return [];
    }

    const valueColumn = findColumn(streamData.metadata.columns, required('role', 'value'));
    const labelColumn = findColumn(streamData.metadata.columns, required('role', 'label'));
    const descriptionColumn = findColumn(streamData.metadata.columns, required('role', 'description'));

    if (valueColumn.failed) {
        throw new Error('No value column in option data stream');
    }

    const valueIndex = valueColumn.value.dataIndex;
    const labelIndex = labelColumn.succeeded ? labelColumn.value.dataIndex : valueIndex;
    const descriptionIndex: number | false = descriptionColumn.succeeded ? descriptionColumn.value.dataIndex : false; 

    return streamData.rows.reduce((acc, row) => {
        const value = row[valueIndex].formatted;
        const label = row[labelIndex].formatted;
        const description = descriptionIndex === false ? undefined : row[descriptionIndex].formatted;

        acc.push({ 
            value, 
            label,
            description
        });

        return acc;
    }, [] as AutocompleteOption[]);
};

export const nullOptionsLoader: OptionsLoader = {
    canLoad: false,
    getOption: (x) => x,
    loadOptions: async () => [],
    onLoad: () => undefined
};

export const readStreamAsAutocompleteOptions = (
    request: ClientDataStreamRequest,
    defaultOptions: AutocompleteOption[],
    searchConfig: DataStreamSearchConfig
): OptionsLoader => {
    const isBackendSearch = searchConfig.type === 'server';

    const getOptionsImpl = memoize(async (autocompleteSearch?: string) => {
        if (isBackendSearch && (!autocompleteSearch || autocompleteSearch.length < searchConfig.minCharacters)) {
            return [];
        }

        return streamDataToOptions(
            await requestData({
                ...request,
                dataSourceConfig: isBackendSearch
                    ? // `autocompleteSearch` naming is important, plugins expect it to be named this way.
                      { ...(request.dataSourceConfig ?? {}), autocompleteSearch }
                    : // Don't pass it if we don't need it to avoid breaking data stream caching.
                      request.dataSourceConfig
            })
        );
    });

    // Debounce if we're searching on the backend to avoid excessive requests.
    const getOptions = isBackendSearch ? debounceAsync(getOptionsImpl, searchConfig.debounceMs) : getOptionsImpl;

    // The options which have been loaded, scoped so that getOption can access them.
    let loaded: { resolvedOptions: AutocompleteOption[] } = { resolvedOptions: [] };
    // A function called once the options have been loaded.
    let onLoad: (() => void) | undefined;

    const optionsLoader: OptionsLoader = {
        loadOptions: async (search: string) => {
            // Don't pass search if it doesn't affect the output because it'll break memoization.
            const options = await getOptions(isBackendSearch ? search : undefined).catch(() => []);

            const uniqueOptions = uniqBy(options, (o) => o.value);
            // Options for values that don't exist in the data,
            // i.e. were previously added manually by the user
            const extraOptions = defaultOptions.filter(
                ({ value: v }) => !uniqueOptions.some(({ value: v2 }) => v === v2)
            );

            // Always expose all the options
            // (not filtered by the search criteria unless we're filtering on the backend).
            loaded.resolvedOptions = orderBy(uniqueOptions.concat(extraOptions), (o) =>
                getAutoCompleteLabelText(o).toLowerCase()
            );

            // Now that the options are loaded we can trigger onLoad.
            onLoad?.();

            if (isBackendSearch) {
                // No need to filter ourselves if the backend has done it.
                return loaded.resolvedOptions;
            }

            return loaded.resolvedOptions.filter((o) =>
                getAutoCompleteLabelText(o).toLowerCase().includes(search.toLowerCase())
            );
        },
        getOption: (x: unknown) => ensureOptions(loaded.resolvedOptions, x),
        onLoad: (f: () => void) => {
            onLoad = f;
        },
        canLoad: true
    };

    return optionsLoader;
};
