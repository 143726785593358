import { useTenant } from 'queries/hooks/useTenant';
import { useEffect, useState } from 'react';

export const useTenantSessionStorage = (key: string, initialValue: string): [string, (value: string) => void] => {
    const { data: tenant } = useTenant();
    const fullKey = `${tenant?.id},${key}`;

    const [state, setState] = useState(() => {
        const currentValue = sessionStorage.getItem(fullKey);
        if (currentValue) {
            return currentValue;
        }

        return initialValue;
    });

    useEffect(() => {
        sessionStorage.setItem(fullKey, state);
    }, [fullKey, state]);

    const updateStore = (value: string) => setState(value);

    return [state, updateStore];
};
