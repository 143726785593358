import RenderLayout from './RenderLayout';
import RenderTile from './RenderTile';

const renderComponentTypes = {
    layout: RenderLayout,
    tile: RenderTile
};

function RenderDynamic({ config, isLoading = false }) {
    try {
        const [type] = config._type.split('/');
        const DynamicComponent = renderComponentTypes[type];
        return <DynamicComponent config={config} isLoading={isLoading} />;
    } catch (e) {
        return (
            <div className='p-3 rounded bg-statusErrorPrimary'>
                <span className='font-bold'>Error:</span> Something went wrong during render.
            </div>
        );
    }
}

export default RenderDynamic;
