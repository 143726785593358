import { SimpleTile } from 'dashboard-engine/basetiles/types';
import graphNodes from '../datasources/graph/graphNodes/graphNodes';

const tile: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: graphNodes,
        config: {
            //type: 'db'
            // Example to get nodes related to ID
            //'related': '61e03fa9-4168-5d4a-b136-6412d7138ed9-SCOM_16'
        }
    },
    transforms: [
        {
            type: 'custom',
            config: (data: any) => data.map((node: any) => ({
                ...node,
                id: node.id,
                name: node.name[0],
                state: node.state
            }))
        }
    ],
    visualisation: {
        type: 'blocks'
    }
};

export default tile;
