import { Toggle } from '@/components/forms/Toggle';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StreamDataColumn, type DataStreamSortingConfig } from '@squaredup/data-streams';
import Button from 'components/button';
import Field from 'components/forms/field/Field';
import stringify from 'fast-json-stable-stringify';
import { useCallback, useMemo } from 'react';
import { Controller, DeepPartial, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import type { PartialDeep } from 'type-fest';
import { useFormChange } from 'ui/editor/dataStream/hooks/useFormChange';
import { SortingValues, getSortingColumns, getValidSort } from 'ui/editor/dataStream/utilities/sorting';
import { SortRow } from './SortRow';

interface SortingFormProps {
    defaultValues: PartialDeep<SortingValues>;
    columns: StreamDataColumn[];
    onChange?: (
        event:
            | { isValid: true; formData: DataStreamSortingConfig['sort'] }
            | { isValid: false; formData: PartialDeep<SortingValues> }
    ) => void;
}

export const SortingForm: React.FC<SortingFormProps> = ({ defaultValues, columns, onChange }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columnOptions = useMemo(() => getSortingColumns(columns), [stringify(columns)]);

    const formProps = useForm<SortingValues>({ defaultValues });

    const { control, watch, setValue } = formProps;

    const {
        fields: sortByFields,
        append: appendSortBy,
        remove: removeSortBy
    } = useFieldArray({
        control,
        name: 'by'
    });

    const fields = watch();

    const handleChange = useCallback(
        (values: DeepPartial<SortingValues>) => {
            const validSort = getValidSort(values);

            if (validSort == null) {
                onChange?.({ isValid: false, formData: values });
                return;
            }

            onChange?.({ isValid: true, formData: validSort });
        },
        [onChange]
    );

    useFormChange(watch, handleChange);

    const selectedColumnIsValid = columnOptions.some((o) => o.value === fields.by?.[0]?.column);

    if (!selectedColumnIsValid) {
        if (fields.enableTop) {
            setValue('enableTop', false);
        } else if (fields.by?.[0]?.column != null) {
            setValue('by.0.column', null);
        }
    }

    return (
        <FormProvider {...formProps}>
            <form>
                <div className='grid grid-cols-[minmax(0,1fr)_minmax(0,1fr)_1.5rem] gap-2'>
                    {sortByFields.length > 0 && (
                        <>
                            <Field.Label label='Sort by' spacing='none' className='col-start-1 mt-2 -mb-2' />
                            <Field.Label label='Direction' spacing='none' className='mt-2 -mb-2' />
                        </>
                    )}

                    {sortByFields.map((field, index) => (
                        <SortRow
                            key={field.id}
                            fieldIndex={index}
                            columns={columns}
                            columnOptions={columnOptions}
                            deleteSort={() => removeSortBy(index)}
                        />
                    ))}

                    <Button
                        onClick={() => appendSortBy({ column: null, direction: 'asc' })}
                        variant='secondary'
                        className='col-start-1 mt-2 w-fit'
                        data-testid='grouping-addGroup'
                    >
                        <FontAwesomeIcon icon={faPlus} className='mr-3' /> <span>Add sort by</span>
                    </Button>
                </div>
                <Field.Label className='mt-2 text-textPrimary' spacing='none' label='Top'>
                    <div className='flex flex-row items-center col-start-1 mb-2 space-x-2 min-h-[2.92rem]'>
                        <Controller
                            name='enableTop'
                            render={({ field: { value, onChange: onValueChange } }) => (
                                <Toggle
                                    checked={value}
                                    disabled={!selectedColumnIsValid}
                                    aria-label='Enable top'
                                    data-testid='sorting-top'
                                    onCheckedChange={() => {
                                        onValueChange(!value);
                                        if (!fields.top) {
                                            setValue('top', '10');
                                        }
                                    }}
                                />
                            )}
                        />
                        {fields.enableTop && (
                            <div>
                                <Field.Input type='number' name='top' min={1} placeholder='Number of results' />
                            </div>
                        )}
                    </div>
                </Field.Label>
            </form>
        </FormProvider>
    );
};
