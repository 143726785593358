import { omit } from 'lodash';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import type { TileEditorAction } from './TileEditorActions';
import type { TileEditorStateInternal, TileEditorStateReducerExternals } from './TileEditorState';

export type TileEditorStoreWithDispatch = TileEditorStateInternal & {
    dispatch: (action: TileEditorAction) => void;
};

export type TileEditorStateReducer = (
    state: TileEditorStateInternal,
    action: TileEditorAction
) => TileEditorStateInternal;

/**
 * Create the reducer used in the tile editor store. This allows for testing
 * without having to render the store using `renderHook`.
 */
export const tileEditorStateReducer: TileEditorStateReducer = (state, action): TileEditorStateInternal => {
    switch (action.type) {
        case 'selectDataStream': {
            const isSelectingDifferentDataStream = state.selectedDataStream?.id !== action.dataStream.id;

            if (!isSelectingDifferentDataStream) {
                return state;
            }

            return {
                ...state,
                selectedDataStream: action.dataStream,
                sideEffects: ({ queryClient, setConfig }: TileEditorStateReducerExternals) => {
                    // Cache any data stream related data to ensure the
                    // tile editor immediately reflects the current state
                    queryClient.setQueryData(
                        dataStreamDefinitionQueryKeys.byId(action.dataStream.id),
                        action.dataStream
                    );

                    setConfig((c) => ({
                        ...c,
                        dataStream: {
                            ...omit(c.dataStream, 'pluginConfigId'),
                            dataSourceConfig: action.dataStream.definition.dataSourceConfig,
                            id: action.dataStream.id,
                            name: action.dataStream.definition?.name
                        }
                    }));
                }
            };
        }

        default:
            return state;
    }
};
