import { IconDefinition, IconName } from '@fortawesome/pro-solid-svg-icons';

export const blocks: IconDefinition = {
    iconName: 'blocks' as IconName,
    prefix: 'fas', // solid icon
    icon: [
        512, // viewBox width
        512, // viewBox height
        [], // ligatures
        'f269', // unicode codepoint - private use area
        'M447.73 0H64.38C28.87 0 0 28.87 0 64.38v105.23c0 35.62 28.87 64.38 64.38 64.38h383.35c35.51 0 64.27-28.76 64.27-64.38v-105.23c0-35.51-28.76-64.38-64.27-64.38ZM196.3 99.89l-89 83.77-1.09.98q-.11.11-.22.11c-.76.87-1.63 1.53-2.61 2.07-1.42.98-3.05 1.74-4.68 2.18-.65.22-1.31.44-2.07.54-1.09.22-2.29.33-3.49.33-4.14 0-8.28-1.31-11.66-4.14-.33-.22-.76-.54-1.09-.76-.11-.11-.22-.22-.33-.33l-.76-.76-41.5-39c-7.63-7.3-8.06-19.39-.76-27.02 7.19-7.63 19.28-7.95 26.91-.76l29.2 27.56 77.02-72.55c7.63-7.19 19.72-6.86 26.91.87 7.19 7.63 6.86 19.72-.76 26.91ZM447.73 278.01H64.38c-35.51 0-64.38 28.76-64.38 64.38v105.23c0 35.51 28.87 64.38 64.38 64.38h383.35c35.51 0 64.27-28.87 64.27-64.38v-105.23c0-35.62-28.76-64.38-64.27-64.38ZM175.06 423.43c7.73 7.3 8.06 19.28.87 27.02-3.81 3.92-8.82 5.99-13.94 5.99-4.68 0-9.37-1.74-13.07-5.23l-31.92-30.07-31.92 30.07c-3.7 3.49-8.39 5.23-13.07 5.23-5.01 0-10.13-2.07-13.84-5.99-7.19-7.73-6.86-19.72.76-27.02l30.28-28.43-30.28-28.54c-7.63-7.19-7.95-19.28-.76-26.91 7.19-7.73 19.28-8.06 26.91-.87l31.92 30.07 31.92-30.07c7.73-7.19 19.83-6.86 27.02.87 7.19 7.62 6.86 19.72-.87 26.91l-30.28 28.54 30.28 28.43Z' // path
    ]
};
