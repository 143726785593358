import { TooltipButton } from '@/components/Button';
import { cn } from '@/lib/cn';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { useDataStreamFromTileConfig } from 'dashboard-engine/hooks/useDataStreamFromTileConfig';

interface FullScreenButtonProps {
    onOpenFullscreen?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const FullscreenButton: React.FC<FullScreenButtonProps> = ({ onOpenFullscreen }) => {
    const { editing } = useDashboardContext();
    const { config } = useTileContext();

    const { isConfigured } = useDataStreamFromTileConfig(config as DataStreamBaseTileConfig);

    if (!isConfigured) {
        return null;
    }

    return (
        <TooltipButton
            variant='tertiary'
            icon={<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} fixedWidth />}
            title='Full screen'
            className={cn(!editing && 'hidden group-hover/tile:inline-flex')}
            onClick={onOpenFullscreen}
        />
    );
};
