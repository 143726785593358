import type { DashboardType, DashboardVariable } from '@squaredup/dashboards';
import { TimeframeEnumValue } from '@squaredup/timeframes';
import DashboardContext from 'contexts/DashboardContext';
import { GlobalDataStreamContext } from 'dashboard-engine/dataStreams/GlobalDataStreamContext';
import RenderDynamic from 'dashboard-engine/render/RenderDynamic';

interface PerspectiveDashboardProps {
    dashboard: DashboardType;
    dashboardVariables: DashboardVariable[];
    timeframe: TimeframeEnumValue;
    workspace: string;
}

const PerspectiveDashboard: React.FC<PerspectiveDashboardProps> = ({
    workspace,
    dashboard,
    dashboardVariables,
    timeframe
}) => {
    return (
        <GlobalDataStreamContext workspace={workspace}>
            <DashboardContext.Provider
                value={{
                    editing: false,
                    setEditing: undefined,
                    dashboard,
                    timeframe,
                    currentWorkspace: undefined,
                    showDetailedErrors: false,
                    variables: dashboardVariables,
                    setVariables: undefined,
                    updateTile: undefined
                }}
            >
                <div className='py-4'>
                    <RenderDynamic config={dashboard.content} key={dashboard.id} />
                </div>
            </DashboardContext.Provider>
        </GlobalDataStreamContext>
    );
};

PerspectiveDashboard.propTypes = {};

export default PerspectiveDashboard;
