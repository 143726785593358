import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { createContext, useContext, useMemo, type Dispatch, type FC, type SetStateAction } from 'react';
import { useQueryClient } from 'react-query';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { useDatasetContext } from '../../contexts/DatasetContext';
import {
    createTileEditorState,
    type TileEditorDataStream,
    type TileEditorState,
    type TileEditorStateReducerExternals
} from './TileEditorState';
import { tileEditorStateReducer, TileEditorStoreWithDispatch } from './TileEditorStateReducer';
import { useTileEditorDataStreams } from './useTileEditorDataStreams';

type TileEditorStore = UseBoundStore<StoreApi<TileEditorStoreWithDispatch>>;

type TileEditorStoreContextValue = {
    useStore: TileEditorStore;
};

const TileEditorStoreContext = createContext<TileEditorStoreContextValue>({
    get useStore(): TileEditorStore {
        throw new Error('useStore must be used inside a TileEditorStoreProvider');
    }
});

export const createTileEditorStore = ({
    initialState,
    ...externals
}: TileEditorStateReducerExternals & { initialState: TileEditorState }) =>
    create<TileEditorStoreWithDispatch>()((set) => {
        return {
            ...initialState,
            dispatch: (action) => {
                set((state) => {
                    const newState = tileEditorStateReducer(state, action);

                    /**
                     * Execute side-effects as part of the dispatch. This means that the side-effects
                     * happen before components are re-rendered with the new state, which may not be
                     * ideal, but it avoids the need for a separate useEffect to handle side-effects.
                     *
                     * Having a separate useEffect means the components are rendered with the new state
                     * before the side-effects have been applied, and are then immediately re-rendered
                     * with the side-effects applied. It also means we don't have to dispatch an action
                     * to mark the side-effects as executed which is another render saved, and means we
                     * don't have to expose that action to the outside world.
                     */
                    if (newState.sideEffects != null) {
                        newState.sideEffects(externals);
                    }

                    return { ...newState, sideEffects: undefined };
                });
            }
        };
    });

/**
 * Provide tile editor store to all children.
 */
export const TileEditorStoreProvider: FC<{
    config: DataStreamBaseTileConfig;
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
    dataStreams: TileEditorDataStream[];
}> = ({ children, config, setConfig, dataStreams }) => {
    const queryClient = useQueryClient();

    const store: TileEditorStore = useMemo(
        () =>
            createTileEditorStore({
                queryClient,
                setConfig,
                initialState: createTileEditorState({
                    dataStreams,
                    config
                })
            }),
        [config, dataStreams, queryClient, setConfig]
    );

    return <TileEditorStoreContext.Provider value={{ useStore: store }}>{children}</TileEditorStoreContext.Provider>;
};

/**
 * Provide tile editor store to all children using config from `DatasetContext`.
 */
export const TileEditorStoreProviderFromDataSetContext: FC = ({ children }) => {
    const { config, setConfig } = useDatasetContext();

    const { dataStreams } = useTileEditorDataStreams();

    return (
        <TileEditorStoreProvider config={config} setConfig={setConfig} dataStreams={dataStreams}>
            {children}
        </TileEditorStoreProvider>
    );
};

export const useTileEditorStore = <T,>(selector: (state: TileEditorStoreWithDispatch) => T): T => {
    return useContext(TileEditorStoreContext).useStore(selector);
};
