import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { Serialised } from '@squaredup/ids';
import { TimeframeEnumValue, timeframeNames } from '@squaredup/timeframes';
import { useDashboardContext } from 'contexts/DashboardContext';
import type { Plugin } from 'dynamo-wrapper';
import { groupBy, uniq } from 'lodash';
import { useDataStreamDefinitionsForWorkspace } from 'queries/hooks/useDataStreamDefinitions';
import { useQuery } from 'react-query';
import { Get as getPlugin } from 'services/PluginService';
import { PartialDeep } from 'type-fest';

export const getTileTimeframe = (
    tileTimeframe: TimeframeEnumValue,
    dataStreamSupportedTimeframes: TimeframeEnumValue[] | boolean
): TimeframeEnumValue => {
    const timeframeName = timeframeNames.find((t) => t.name === tileTimeframe);

    // If supported timeframes include the current timeframe, return it
    if (
        dataStreamSupportedTimeframes === true ||
        dataStreamSupportedTimeframes === false ||
        dataStreamSupportedTimeframes.includes(tileTimeframe) ||
        !timeframeName
    ) {
        return tileTimeframe;
    }

    // We only want to select the next timeframe within the timeframe's group
    const groupedTimeframes = groupBy(timeframeNames, 'group');
    const timeframeGroup = groupedTimeframes[timeframeName.group].map((t) => t.name as TimeframeEnumValue);

    // Check if there's any timeframes in the current group we can switch to
    const timeframesDifference = timeframeGroup.filter((t) => dataStreamSupportedTimeframes.includes(t));

    if (timeframesDifference.length === 0) {
        return dataStreamSupportedTimeframes[dataStreamSupportedTimeframes.length - 1];
    }

    // Start from the position of the current timeframe in the group list
    // We only want to select a supported timeframe greater than the current one
    const timeframeIndex = timeframeGroup.indexOf(tileTimeframe);
    for (let i = timeframeIndex; i < timeframeGroup.length; i++) {
        const timeframe = timeframeGroup[i];
        if (dataStreamSupportedTimeframes.includes(timeframe)) {
            return timeframe;
        }
    }

    return dataStreamSupportedTimeframes[dataStreamSupportedTimeframes.length - 1];
};

export const useTileTimeframes = (configs?: (PartialDeep<DataStreamBaseTileConfig> | undefined)[]) => {
    const dashboardContext = useDashboardContext();

    const dataStreamIds = uniq(configs?.map((c) => c?.dataStream?.id)).filter(
        (id): id is `datastream-${string}` => id != null
    );

    const { data: dataStreamsForWorkspace } = useDataStreamDefinitionsForWorkspace();

    const dataStreams =
        dataStreamsForWorkspace &&
        dataStreamsForWorkspace.filter((dataStream) => dataStreamIds.includes(dataStream.id));

    const pluginIds = dataStreams ? uniq(dataStreams.map((d) => d.pluginId)) : [];

    const { data: plugins } = useQuery<Serialised<Plugin> | Serialised<Plugin>[], Error, Serialised<Plugin>[]>(
        ['plugin', ...pluginIds],
        () => {
            // Same as data stream definitions above, this cache key is used elsewhere for a single plugin id
            if (pluginIds.length === 1) {
                return getPlugin(pluginIds[0]);
            }
            return Promise.all(pluginIds.map((id) => getPlugin(id)));
        },
        {
            enabled: pluginIds.length > 0,
            select: (data) => (Array.isArray(data) ? data : [data]),
            // Plugins very rarely change, supported timeframes even more rarely.
            cacheTime: Number.POSITIVE_INFINITY,
            staleTime: Number.POSITIVE_INFINITY
        }
    );

    if (!dataStreams || !plugins) {
        return [];
    }

    return configs?.map((config) => {
        const dataStream = dataStreams.find((d) => d.id === config?.dataStream?.id);
        const plugin = plugins.find((p) => p.id === dataStream?.pluginId);

        const dataStreamTimeframe = dataStream?.definition.timeframes;

        const pluginDataSource = plugin?.dataSources.find((d) => d.name === dataStream?.dataSourceName);

        const dataSourceTimeframe = pluginDataSource?.timeframes ?? true;

        const dataStreamSupportedTimeframes = dataStreamTimeframe || dataSourceTimeframe;

        const tileTimeframe = config?.timeframe ?? dashboardContext.timeframe;

        return getTileTimeframe(tileTimeframe, dataStreamSupportedTimeframes);
    });
};
