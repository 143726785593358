import { isPresetOf } from '@squaredup/data-streams';
import { useDataStreamDefinitionsForWorkspace } from 'queries/hooks/useDataStreamDefinitions';
import { type DataStream } from 'services/DataStreamDefinitionService';
import type { DataStreamId, TileEditorDataStream } from './TileEditorState';

const toTileEditorDataStream = (
    dataStream: DataStream,
    _index: number,
    dataStreams: DataStream[]
): TileEditorDataStream => {
    const isPreset = dataStream.definition.presetOf != null;
    const presetParentDataStream = isPreset
        ? dataStreams.find((parentDs) => isPresetOf(parentDs, dataStream))
        : undefined;

    if (isPreset && presetParentDataStream != null) {
        return {
            ...dataStream,
            isPreset: true,
            displayNameFull: `${presetParentDataStream.displayName} / ${dataStream.displayName}`
        };
    }

    return {
        ...dataStream,
        isPreset: false,
        displayNameFull: dataStream.displayName
    };
};

const getPresetsMap = (dataStreams: TileEditorDataStream[]) => {
    return new Map(
        dataStreams.flatMap((ds) => {
            const dataStreamWithPresets = ds.isPreset ? dataStreams.find((parentDs) => isPresetOf(parentDs, ds)) : ds;

            if (dataStreamWithPresets == null || dataStreamWithPresets.isPreset) {
                return [];
            }

            const presetIds = dataStreams
                .filter((preset) => isPresetOf(dataStreamWithPresets, preset))
                .map((preset) => preset.id);

            return presetIds.length > 0 ? [[ds.id, presetIds]] : [];
        })
    );
};

const getTemplatesMap = (dataStreams: TileEditorDataStream[]) => {
    return new Map(
        dataStreams.flatMap((ds) => {
            if (ds.template != null) {
                return [[ds.id, ds.id]];
            }

            if (!ds.isPreset) {
                return [];
            }

            const parentPresetDataStream = dataStreams.find((parentDs) => isPresetOf(parentDs, ds));

            return parentPresetDataStream == null ? [] : [[ds.id, parentPresetDataStream.id]];
        })
    );
};

export type TileEditorDataStreams = {
    dataStreams: TileEditorDataStream[];
    dataStreamsById: Map<DataStreamId, TileEditorDataStream>;
    /**
     * A map of data stream Ids to the Ids of the presets associated with it.
     * For configurable data streams with presets these are the presets that have it as a parent.
     * For data streams which are themselves presets these are the presets of it's parent (including itself).
     *
     * Data streams which are not presets or parents with presets are not included in this map.
     */
    presets: Map<DataStreamId, DataStreamId[]>;
    /**
     * A map of data stream Ids to the Ids of the data stream which has it's template.
     * For configurable data streams the key and value are the same.
     * For data streams which are presets the value is the Id of the parent data stream.
     */
    templates: Map<DataStreamId, DataStreamId>;
};

/**
 * Convert a list of DataStreams to a TileEditorDataStreams object,
 * as returned by {@link useTileEditorDataStreams}.
 */
export const toTileEditorDataStreams = (dataStreams: DataStream[]): TileEditorDataStreams => {
    const tileEditorDataStreams = dataStreams.map(toTileEditorDataStream);

    const presets = getPresetsMap(tileEditorDataStreams);
    const templates = getTemplatesMap(tileEditorDataStreams);

    return {
        dataStreams: tileEditorDataStreams,
        dataStreamsById: new Map(tileEditorDataStreams.map((ds) => [ds.id, ds])),
        presets,
        templates
    };
};

const placeholderData: TileEditorDataStreams = {
    dataStreams: [],
    dataStreamsById: new Map(),
    presets: new Map(),
    templates: new Map()
};

/**
 * Gets the data streams for the current workspace (using {@link useDataStreamDefinitionsForWorkspace}),
 * and converts them into a more easily consumable format for the Tile Editor.
 */
export const useTileEditorDataStreams = (): TileEditorDataStreams => {
    const { data = placeholderData } = useDataStreamDefinitionsForWorkspace({
        queryOptions: {
            select: toTileEditorDataStreams,
            keepPreviousData: true
        }
    });

    return data;
};
