import { LINKED_PLUGINS } from 'components/hooks/useLinkedPluginConfigs';
import { AppContext } from 'contexts/AppContext';
import { GRAPH_DATA } from 'dashboard-engine/visualisations/Network/Network';
import { isOpenAccess } from 'lib/openAccessUtil';
import { useContext } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { AccessControlQueryKeys } from './AccessControlService';
import Auth from './Auth';
import { EvaluateHealth } from './HealthService';
import { InvalidatePluginConfigQueries } from './SourceConfigService';
import {
    AddWorkspaceLinksToWorkspace,
    CURRENT_WORKSPACE,
    SCOPE,
    SCOPES,
    WORKSPACE,
    WORKSPACES,
    Workspace
} from './WorkspaceService';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';

export const NO_ACTIVE_WORKSPACE = 'no_active_workspace' as const;

/**
 * Simple wrapper hook to get the currently active workspace.
 *
 * Always returns a string even if no current workspace available - this plays nicer with most of
 * our functions that take a workspace ID and expect it not to be undefined.  Also gives a hint about
 * the problem if the workspace ID is put into a URL (and where an empty string might cause weird
 * side-effects, like returning all workspaces instead of a specific one)
 **/
export const useCurrentWorkspaceId = (defaultValue = NO_ACTIVE_WORKSPACE): string => {
    const { pathname } = useLocation();
    const id = useContext(AppContext)?.currentWorkspaceID || defaultValue;

    // The drilldown overrides the current workspace logic, and
    // ensures a user can see data regardless of their current workspace
    if (pathname.startsWith('/drilldown')) {
        return defaultValue;
    }

    return id;
};

/**
 * Get the workspace ID.  For use outside of React components where hooks like useCurrentWorkspaceId
 * don't work.
 */
export const getCurrentWorkspaceId = (): string | undefined => {
    return isOpenAccess() ? Auth?.user?.openAccessWorkspaceId : sessionStorage.getItem(CURRENT_WORKSPACE) ?? undefined;
};

/**
 * Invalidate any react query data that might be affected by a change to the set of workspaces
 * (e.g. add workspace, delete workspace)
 */
export const invalidateWorkspaceQueries = async (queryClient: QueryClient) =>
    Promise.all([
        queryClient.invalidateQueries(workspaceQueryKeys.all),
        queryClient.invalidateQueries(AccessControlQueryKeys.EntityTypePermissions('space')),
        queryClient.invalidateQueries(WORKSPACE),
        queryClient.invalidateQueries([WORKSPACES, GRAPH_DATA])
    ]);

/**
 * Invalidate any react query data that might be affected by a change to a workspace's linked
 * plugins and/or workspaces.
 */
export const invalidateAfterWorkspaceLinksChange = async (queryClient: QueryClient, _workspaceId?: string) =>
    Promise.all([
        InvalidatePluginConfigQueries(queryClient),
        invalidateWorkspaceQueries(queryClient),
        queryClient.invalidateQueries([SCOPE]),
        queryClient.invalidateQueries([SCOPES]),
        queryClient.invalidateQueries([LINKED_PLUGINS]),
        queryClient.invalidateQueries(dataStreamDefinitionQueryKeys.all),
        queryClient.invalidateQueries([GRAPH_DATA])
    ]);

/**
 * Link the specified downstream workspaces to the current workspace (where required),
 * and invalidate any query data that might have changed as a result.
 *
 * Also optionally re-evaluate health (because changes in downstream workspaces may change
 * the health of the parent workspace)
 */
export const linkDownstreamWorkspaces = async (
    queryClient: QueryClient,
    workspaceId: string,
    downstreamWorkspaceIds: string[],
    evaluateHealth = true
) => {
    const linksUpdated = await AddWorkspaceLinksToWorkspace(workspaceId, downstreamWorkspaceIds);

    if (linksUpdated) {
        await invalidateAfterWorkspaceLinksChange(queryClient, workspaceId);
    }

    if (evaluateHealth) {
        // Update the health of our workspace immediately so we don't have to wait for background monitoring.
        await EvaluateHealth([workspaceId], true);
    }
};

/**
 * Return the current workspace based on the Current workspace id stored in app context, if any, from
 * the list of workspaces previously queried.
 *
 * This does not make a direct query if the workspace list has yet to be queried, as depending on the
 * environment different extensions of Workspace (such as WorkspaceWithHealth) may be present and we
 * don't want to 'poison' the queryCache with invalid objects for their case.
 * @returns Current workspace from the query cache, or undefined if not currently within a workspace
 */
export const useCachedCurrentWorkspace = (): Workspace | undefined => {
    const queryClient = useQueryClient();
    const currentWorkspaceId = useCurrentWorkspaceId();
    return currentWorkspaceId === NO_ACTIVE_WORKSPACE
        ? undefined
        : queryClient.getQueryData<Workspace[]>(workspaceQueryKeys.list)?.find((ws) => ws.id === currentWorkspaceId);
};
