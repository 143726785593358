import type { UserGroupModel } from 'dynamo-wrapper';
import { useGroups } from 'queries/hooks/useGroups';
import { UseQueryOptions } from 'react-query';

type UseEveryoneGroupOptions = Omit<
    UseQueryOptions<UserGroupModel[], unknown, UserGroupModel | undefined, string>,
    'queryKey' | 'queryFn' | 'select'
>;

export const useEveryoneGroup = (options?: UseEveryoneGroupOptions) => {
    return useGroups({
        ...options,
        select: (data) => {
            return data?.find((group) => group.name === 'everyone');
        }
    });
};
