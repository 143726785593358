import { ShapeName } from '@squaredup/data-streams';
import { isDefined } from '@squaredup/utilities';
import stringify from 'fast-json-stable-stringify';
import { z } from 'zod';
import { DataStreamMetadataForm, fromFormFieldName, getShapeOverride, toMetadataOverrides } from './MetadataEditorForm';
import {
    ComparisonStateColumn,
    DataStreamMetadataState,
    DataStreamMetadataStateColumn,
    createMetadataEditorState,
    getCloneColumnName,
    getComparisonColumnName,
    type CloneStateColumn,
    type StateColumn
} from './MetadataEditorState';
import { findClones, findComparisons, sanitizeMetadataOverride } from './MetadataEditorTileState';

type ComparisonColumnAction = {
    comparisonName?: string;
    sourceColumnName: string;
    compareTo: string;
    comparisonType: 'absolute' | 'percentage';
    shape: {
        name: ShapeName;
        config: Record<string, unknown>;
    };
};

/**
 * An action that can be applied to the editor state
 */
export type DataStreamMetadataAction =
    | { type: 'overrideMetadata'; formData: DataStreamMetadataForm }
    | { type: 'saveTileConfig' }
    | { type: 'cloneColumn'; sourceColumnName: string }
    | ({
          type: 'compareColumn';
      } & ComparisonColumnAction)
    | ({
          type: 'updateComparisonColumn';
      } & ComparisonColumnAction)
    | { type: 'removeColumn'; columnName: string }
    | { type: 'reset'; state: DataStreamMetadataState };

/**
 * Get the 1-based number of a clone column from the its name
 */
const getCloneNumber = (cloneName: string): string | undefined => {
    return cloneName.match(/_clone(?<cloneNumber>\d+)$/iu)?.groups?.cloneNumber;
};

const getComparisonNumber = (comparisonName: string): string | undefined => {
    return comparisonName.match(/compare(?<compareNumber>\d+)$/iu)?.groups?.compareNumber;
};

/**
 * Get the default display name for a clone of the given column.
 * @param cloneSourceColumn The column that was cloned.
 * @param cloneIndex The 0-based index of the clone among all the clones of the source column.
 */
const getCloneDisplayName = (
    cloneSourceColumn: StateColumn | CloneStateColumn | ComparisonStateColumn,
    cloneIndex: number
) => {
    const cloneSourceDisplayName =
        cloneSourceColumn.metadataOverrides?.displayName ??
        cloneSourceColumn.metadata?.displayName ??
        cloneSourceColumn.columnName;

    return `Copy of ${cloneSourceDisplayName}${cloneIndex > 0 ? ` (${cloneIndex})` : ''}`;
};

const getComparisonDisplayName = (
    cloneSourceColumn: StateColumn | CloneStateColumn | ComparisonStateColumn,
    cloneIndex: number
) => {
    const cloneSourceDisplayName =
        cloneSourceColumn.metadataOverrides?.displayName ??
        cloneSourceColumn.metadata?.displayName ??
        cloneSourceColumn.columnName;

    return `${cloneSourceDisplayName}${cloneIndex > 0 ? ` (${cloneIndex})` : ''} Comparison`;
};

/**
 * Generate a new unique column name for a clone of the column with the given name
 */
const nextCloneColumnName = (state: DataStreamMetadataState, sourceColumnName: string) => {
    const existingClones = findClones(state, sourceColumnName);

    const maxExistingCloneNumber = Math.max(
        0,
        ...existingClones
            .map((c) => getCloneNumber(c.cloneName))
            .filter(isDefined)
            .map((s) => Number(s))
    );

    const sourceColumn = state.columnMap.get(sourceColumnName);
    const cloneSourceColumn = sourceColumn?.isPattern ? undefined : sourceColumn;

    return {
        name: `${sourceColumnName}_clone${maxExistingCloneNumber + 1}`,
        displayName:
            cloneSourceColumn != null ? getCloneDisplayName(cloneSourceColumn, maxExistingCloneNumber) : undefined
    };
};

/**
 * Generate a new unique column name for a column comparison with the given name
 */
const nextComparisonColumnName = (state: DataStreamMetadataState, sourceColumnName: string) => {
    const existingComparisons = findComparisons(state, sourceColumnName);

    const maxExistingComparisonNumber = Math.max(
        0,
        ...existingComparisons
            .map((c) => getComparisonNumber(c.comparisonName))
            .filter(isDefined)
            .map((s) => Number(s))
    );

    const sourceColumn = state.columnMap.get(sourceColumnName);
    const comparisonSourceColumn = sourceColumn?.isPattern ? undefined : sourceColumn;

    return {
        name: `compare${maxExistingComparisonNumber + 1}`,
        displayName:
            comparisonSourceColumn != null
                ? getComparisonDisplayName(comparisonSourceColumn, maxExistingComparisonNumber)
                : undefined
    };
};

const ensurePatternWildcardExists = (columnMap: Map<string, DataStreamMetadataStateColumn>) => {
    return columnMap.set('.*', {
        pattern: '.*',
        isPattern: true,
        isClone: false,
        isCloneable: false,
        isComparison: false,
        isComparable: false,
        metadataOverrides: { pattern: '.*' }
    });
};

/**
 * Determine if the given column can be removed, i.e. is a clone column
 * which has not been cloned itself
 */
export const columnCanBeRemoved = (state: DataStreamMetadataState, columnName: string) => {
    const foundColumn = state.columnMap.get(columnName);

    if (foundColumn == null) {
        return false;
    }

    if (foundColumn.isComparison) {
        return true;
    }

    const hasClones = !foundColumn.isPattern
        ? findClones(state, foundColumn.columnName).filter((c) => c.isEditable).length > 0
        : false;

    return foundColumn.isClone && !hasClones && foundColumn.isEditable;
};

/**
 * Apply an action to produce a new state object
 */
export const dataStreamMetadataReducer = (
    state: DataStreamMetadataState,
    action: DataStreamMetadataAction
): DataStreamMetadataState => {
    switch (action.type) {
        case 'overrideMetadata': {
            const overrides = toMetadataOverrides(action.formData);

            const newColumnMap = new Map(state.columnMap);

            overrides.forEach(([formFieldName, formMetadataOverride]) => {
                const columnName = fromFormFieldName(formFieldName);
                const column = state.columnMap.get(columnName);

                // We don't support editing pattern column definitions
                if (column == null || column.isPattern) {
                    return;
                }

                if (column.isClone && !formMetadataOverride.displayName) {
                    const cloneNumber = z.number({ coerce: true }).safeParse(getCloneNumber(column.cloneName));

                    // If we're resetting the display name of a clone, set it to the generated
                    // display name override, otherwise we'll get the not-very-useful inferred name
                    formMetadataOverride.displayName = getCloneDisplayName(
                        state.columnMap.get(column.sourceColumnName) as StateColumn | CloneStateColumn,
                        cloneNumber.success ? cloneNumber.data - 1 : 0
                    );
                }

                if (column.isComparison && !formMetadataOverride.displayName) {
                    const comparisonNumber = z
                        .number({ coerce: true })
                        .safeParse(getComparisonNumber(column.comparisonName));

                    // If we're resetting the display name of a comparison, set it to the generated
                    // display name override, otherwise we'll get the not-very-useful inferred name
                    formMetadataOverride.displayName = getComparisonDisplayName(
                        state.columnMap.get(column.sourceColumnName) as StateColumn | ComparisonStateColumn,
                        comparisonNumber.success ? comparisonNumber.data - 1 : 0
                    );
                }

                const newMetadataOverride = sanitizeMetadataOverride(
                    {
                        ...column.metadataOverrides,
                        ...formMetadataOverride
                    },
                    column.metadataOverrides,
                    column.metadata,
                    column.definitions ?? []
                );

                if (stringify(newMetadataOverride) === stringify(column.metadataOverrides ?? {})) {
                    return;
                }

                newColumnMap.set(columnName, {
                    ...column,
                    metadataOverrides: newMetadataOverride
                });
            });

            /**
             * We have overrides, but no defined metadata (e.g. Web API).
             * This will cause all non-overridden columns to be removed from
             * the data stream output which is confusing, so we include a
             * catch-all pattern column to keep them around.
             */
            if (overrides.length > 0 && !state.hasDefinedMetadata) {
                ensurePatternWildcardExists(newColumnMap);
            }

            return createMetadataEditorState({
                tileConfigUpdatePending: true,
                hasDefinedMetadata: state.hasDefinedMetadata,
                columnMap: newColumnMap
            });
        }
        case 'cloneColumn': {
            const sourceColumn = state.columnMap.get(action.sourceColumnName);

            if (sourceColumn == null || sourceColumn.isPattern) {
                return state;
            }

            const { name: cloneName, displayName: cloneDisplayName } = nextCloneColumnName(
                state,
                sourceColumn.columnName
            );

            const newColumn: DataStreamMetadataStateColumn = {
                columnName: getCloneColumnName(sourceColumn.columnName, cloneName),
                shape: sourceColumn.shape,
                metadataOverrides: { cloneAs: cloneName, displayName: cloneDisplayName },
                cloneName,
                isClone: true,
                isCloneable: false,
                isPattern: false,
                isEditable: true,
                isBeingCloned: true,
                isComparison: false,
                isComparable: false,
                sourceColumnName: action.sourceColumnName,
                sampleValue: sourceColumn.sampleValue,
                definitions: []
            };

            const newColumnMap = new Map(state.columnMap).set(newColumn.columnName, newColumn);

            /**
             * We have overrides, but no defined metadata (e.g. Web API).
             * This will cause all non-overridden columns to be removed from
             * the data stream output which is confusing, so we include a
             * catch-all pattern column to keep them around.
             */
            if (!state.hasDefinedMetadata) {
                ensurePatternWildcardExists(newColumnMap);
            }

            return createMetadataEditorState({
                tileConfigUpdatePending: true,
                hasDefinedMetadata: state.hasDefinedMetadata,
                columnMap: newColumnMap
            });
        }
        case 'compareColumn': {
            const sourceColumn = state.columnMap.get(action.sourceColumnName);

            if (sourceColumn == null || sourceColumn.isPattern) {
                return state;
            }

            const { name: comparisonName, displayName: comparisonDisplayName } = nextComparisonColumnName(
                state,
                sourceColumn.columnName
            );
            const { shape } = getShapeOverride({ shape: action.shape.name, shapeConfig: action.shape.config });

            const newColumn: DataStreamMetadataStateColumn = {
                columnName: getComparisonColumnName(sourceColumn.columnName, action.compareTo, comparisonName),
                shape: action.shape,
                comparisonName: comparisonName,
                metadataOverrides: {
                    shape,
                    comparisonName: comparisonName,
                    compareTo: action.compareTo,
                    comparisonType: action.comparisonType,
                    displayName: comparisonDisplayName
                },
                isClone: false,
                isCloneable: false,
                isPattern: false,
                isEditable: true,
                isComparison: true,
                isComparable: false,
                sourceColumnName: action.sourceColumnName,
                sampleValue: sourceColumn.sampleValue,
                definitions: []
            };

            const newColumnMap = new Map(state.columnMap).set(newColumn.columnName, newColumn);

            /**
             * We have overrides, but no defined metadata (e.g. Web API).
             * This will cause all non-overridden columns to be removed from
             * the data stream output which is confusing, so we include a
             * catch-all pattern column to keep them around.
             */
            if (!state.hasDefinedMetadata) {
                ensurePatternWildcardExists(newColumnMap);
            }

            return createMetadataEditorState({
                tileConfigUpdatePending: true,
                hasDefinedMetadata: state.hasDefinedMetadata,
                columnMap: newColumnMap
            });
        }
        case 'updateComparisonColumn': {
            if (!action.comparisonName) {
                return state;
            }

            const existingComparisonColumn = state.columnMap.get(action.comparisonName) as ComparisonStateColumn;

            if (existingComparisonColumn == null || existingComparisonColumn.isPattern) {
                return state;
            }

            const newColumnMap = new Map(state.columnMap);

            // If the source column has changed, we need to remove the existing comparison column before re-adding
            // it with the new source column
            const hasChangedSourceColumn = existingComparisonColumn.sourceColumnName !== action.sourceColumnName;

            if (hasChangedSourceColumn) {
                newColumnMap.delete(existingComparisonColumn.columnName);
            }

            const { name: comparisonName, displayName: comparisonDisplayName } = nextComparisonColumnName(
                state,
                action.sourceColumnName
            );

            const { shape } = getShapeOverride({ shape: action.shape.name, shapeConfig: action.shape.config });

            const newColumn: DataStreamMetadataStateColumn = {
                ...existingComparisonColumn,
                ...(hasChangedSourceColumn
                    ? {
                          columnName: getComparisonColumnName(
                              action.sourceColumnName,
                              action.compareTo,
                              comparisonName
                          ),
                          sourceColumnName: action.sourceColumnName,
                          comparisonName: comparisonName
                      }
                    : {}),
                metadataOverrides: {
                    comparisonName: hasChangedSourceColumn ? comparisonName : existingComparisonColumn.comparisonName,
                    shape,
                    compareTo: action.compareTo,
                    comparisonType: action.comparisonType,
                    displayName: hasChangedSourceColumn
                        ? comparisonDisplayName
                        : existingComparisonColumn.metadataOverrides?.displayName
                }
            };

            newColumnMap.set(newColumn.columnName, newColumn);

            return createMetadataEditorState({
                tileConfigUpdatePending: true,
                hasDefinedMetadata: state.hasDefinedMetadata,
                columnMap: newColumnMap
            });
        }
        case 'removeColumn': {
            const column = state.columnMap.get(action.columnName);

            if (column == null || column.isPattern || !columnCanBeRemoved(state, column.columnName)) {
                return state;
            }

            const newColumnMap = new Map(state.columnMap);

            newColumnMap.delete(column.columnName);

            return createMetadataEditorState({
                tileConfigUpdatePending: true,
                hasDefinedMetadata: state.hasDefinedMetadata,
                columnMap: newColumnMap
            });
        }
        case 'saveTileConfig':
            return { ...state, tileConfigUpdatePending: false };
        case 'reset': {
            return action.state;
        }
        default:
            return state;
    }
};
