import { useState } from 'react';

export const useFullscreen = ( isFullscreenEnabled: boolean ) => {
    const [isOpen, setIsOpen] = useState(false);
    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);


    if (!isFullscreenEnabled) {
        return {};
    }

    return {
        isFullscreenOpen: isOpen,
        openFullscreen: onOpen,
        closeFullscreen: onClose
    };
};