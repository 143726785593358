import { DataStreamSortingConfig, StreamDataColumn } from '@squaredup/data-streams';
import { orderBy } from 'lodash';
import { DeepPartial } from 'react-hook-form';

type SortingDirection = 'asc' | 'desc';

export interface SortingValues {
    by?: {
        column?: string | null;
        direction: SortingDirection;
    }[];
    top?: string;
    enableTop: boolean;
}

export const directionOptions = [
    {
        value: 'asc',
        label: 'Ascending'
    },
    {
        value: 'desc',
        label: 'Descending'
    }
];

export const getSortingFormDefaultValues = (
    sort: DataStreamSortingConfig['sort'] | undefined,
    columnOptions: { value: string; label: string }[]
): SortingValues => {
    const sortBy = (sort?.by ?? [null, null]).map((by) => {
        const [columnName, directionString] = by ?? [];
        const column = columnOptions.find((v) => v.value === columnName)?.value ?? undefined;
        const direction = (directionOptions.find((v) => v.value === directionString)?.value ??
            directionOptions[0].value ??
            'asc') as SortingDirection;
        return { column, direction };
    });

    return sort
        ? {
              by: sortBy,
              top: sort?.top?.toString() ?? undefined,
              enableTop: Boolean(sort?.top) && sortBy[0].column != null
          }
        : { by: [{ direction: 'asc' }], enableTop: false };
};

export const getSortingColumns = (columns: StreamDataColumn[]) =>
    orderBy(
        columns.map((c) => ({
            value: c.name,
            label: c.displayName,
            column: c
        })),
        'label'
    );

export const getValidSort = (data: DeepPartial<SortingValues>): DataStreamSortingConfig['sort'] => {
    if (data.enableTop && data.top && !isNaN(parseInt(data.top))) {
        return {
            by: data.by?.map((by) => [by?.column, by?.direction]) ?? [],
            top: parseInt(data.top)
        };
    }

    if (data.by?.length === 0) {
        return {};
    }

    return {
        by: data.by?.map((by) => [by?.column, by?.direction]) ?? []
    };
};
