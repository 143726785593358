import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { sortBy } from 'lodash';
import { fiveMinutes } from 'queries/constants';
import { useScopes } from 'queries/hooks/useScopes';
import { getParsedScopeQueryDetail } from 'utilities/getParsedScopeUtilities';

type ParsedQuickScope = {
    types: {
        displayName: string;
    }[];
    plugin?: {
        displayName: string;
    };
};

/**
 * @returns data sources (plugins) for the current workspace
 */
export const useTileEditorScopes = () => {
    const { workspace, isGlobal } = useDataStreamWorkspaceContext();

    return useScopes(workspace, {
        retry: 0,
        enabled: !isGlobal && Boolean(workspace),
        select(unsortedScopes) {
            const mappedScopes = unsortedScopes.map((scope: any) => {
                const quickScope: ParsedQuickScope = scope.data.quickScope && getParsedScopeQueryDetail(scope);
                const types =
                    quickScope &&
                    quickScope.types.length > 0 &&
                    quickScope.types.map((type) => type.displayName).join(', ');

                const displayName = quickScope
                    ? `${quickScope.plugin?.displayName}${types ? ' - ' : ''}${types || ''}`
                    : scope.displayName!;

                return {
                    ...scope,
                    displayName
                };
            });

            return sortBy(mappedScopes, (scope) => scope.displayName?.toLowerCase());
        },
        cacheTime: fiveMinutes * 2,
        staleTime: fiveMinutes
    });
};
