import { IconDefinition, IconName } from '@fortawesome/pro-solid-svg-icons';

export const donut: IconDefinition = {
    iconName: 'donut' as IconName,
    prefix: 'fas', // solid icon
    icon: [
        512, // viewBox width
        512, // viewBox height
        [], // ligatures
        'f269', // unicode codepoint - private use area
        'M512 279.5c-5.69 61.16-32.83 116.09-73.82 157.08l-48.28-58.26c24.78-26.82 41.52-60.94 46.46-98.82h75.64ZM512 231.22h-75.64c-10.73-80.79-74.89-144.84-155.68-155.57V0c122.42 11.37 219.95 108.79 231.32 231.22ZM401.06 467.37c-41.09 28.22-90.88 44.63-144.52 44.63C114.8 512 0 397.09 0 255.36 0 121.88 102.04 12.12 232.4 0v75.64c-88.73 11.7-157.29 87.87-157.29 179.71 0 100.1 81.33 181.54 181.43 181.54 35.41 0 68.35-10.19 96.24-27.68l48.28 58.15Z' // path
    ]
};
