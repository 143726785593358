import type { OrganisationHomeConfig, UserTenantProperties } from 'dynamo-wrapper';
import { useTenantProperties } from 'queries/hooks/useTenantProperties';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { useMutation, useQueryClient } from 'react-query';
import { SetUserForTenantProperty } from 'services/UserService';

export const defaultGlobalViewConfig: OrganisationHomeConfig = {
    filter: [],
    sort: {
        value: 'state',
        direction: 'desc'
    },
    type: 'space',
    tags: [],
    kpiTypeIds: [],
    workspaceTypes: [],
    viewSettings: {
        avatar: true,
        type: true,
        tags: true,
        sublabel: true,
        dataSources: true,
        kpis: true,
        state: true,
        columnCount: 3,
        downstream: false
    }
};

const convertOldViewOptions = (config: OrganisationHomeConfig) => {
    // Converts the old views object to the new viewSettings object
    // This is done to maintain backwards compatibility
    // At some point in the future we can probably remove this code

    if (!config.views || Boolean(config.viewSettings)) {
        return;
    }

    const viewSettings: Record<string, boolean | number> = {};
    for (const view of config.views['tile']['space']) {
        if (view.type === 'separator') {
            continue;
        }
        viewSettings[view.name] = view.value;
    }

    config.viewSettings = viewSettings as OrganisationHomeConfig['viewSettings'];
};

export const useStatusConfig = () =>
    useTenantProperties({
        select: (data) => {
            const config = data?.organisationHomeConfig ?? defaultGlobalViewConfig;

            if (config?.views) {
                convertOldViewOptions(config);
                delete config.views;
            }
            return {
                ...defaultGlobalViewConfig,
                ...config,
                viewSettings: { ...defaultGlobalViewConfig.viewSettings, ...config.viewSettings }
            };
        },
        placeholderData: { organisationHomeConfig: defaultGlobalViewConfig }
    });

export const useSaveStatusConfig = () => {
    const queryClient = useQueryClient();
    return useMutation((config: OrganisationHomeConfig) => SetUserForTenantProperty('organisationHomeConfig', config), {
        async onMutate(config) {
            await queryClient.cancelQueries(tenantQueryKeys.tenantProperties);

            const previousProperties = queryClient.getQueryData<UserTenantProperties>(tenantQueryKeys.tenantProperties);

            queryClient.setQueryData<UserTenantProperties>(tenantQueryKeys.tenantProperties, {
                ...previousProperties,
                organisationHomeConfig: config
            });

            return { previousProperties };
        },
        onError(_err, _config, context) {
            queryClient.setQueryData<UserTenantProperties | undefined>(
                tenantQueryKeys.tenantProperties,
                context?.previousProperties
            );
            queryClient.invalidateQueries(tenantQueryKeys.tenantProperties);
        }
    });
};
