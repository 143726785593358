const urlParams = new URLSearchParams(window.location.search);

const systemThemeDataAttribute = 'data-theme';
const themeLocalStorageRef = 'SqUpTheme';
const URLTheme = urlParams.get('theme') || '';
const forcedTheme = ['dark', 'light'].includes(URLTheme) ? URLTheme : undefined;
const currentTheme = forcedTheme ?? localStorage.getItem(themeLocalStorageRef) ?? 'system';

if (!window.location.pathname.startsWith('/openaccess/')) {
    if (currentTheme !== 'system') {
        document.body.setAttribute(systemThemeDataAttribute, currentTheme);
    }

    if (currentTheme === 'system') {
        const systemThemeWatcher = window.matchMedia('(prefers-color-scheme: light)');

        // We only want to override the theme attribute if there is no active local storage value (it is null when we're using the system theme)
        document.body.setAttribute(systemThemeDataAttribute, systemThemeWatcher.matches ? 'light' : 'dark');
    }
} else {
    document.body.setAttribute(systemThemeDataAttribute, forcedTheme || 'dark');
}

