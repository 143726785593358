// Exclude certain paths from loading the statuspage embed
const excludedPaths = [
    /openaccess\/[a-zA-Z0-9]*\/tile\/[a-zA-Z0-9]*/giu // Embedded tiles, we don't want the overlay showing on every single tile
];

const path = document.location.pathname;

if (excludedPaths.every((p) => !path.match(p))) {
    const script = document.createElement('script');

    let statusPageEmbedUrl = 'https://squaredup.statuspage.io/embed/script.js';
    if (window.SQUPRELEASEVARS?.Environment === 'ProductionEU') {
        statusPageEmbedUrl = 'https://squaredup-eu.statuspage.io/embed/script.js';
    } else if (window.SQUPRELEASEVARS?.Environment === 'ProductionUK') {
        statusPageEmbedUrl = 'https://squaredup-uk.statuspage.io/embed/script.js';
    }

    script.src = statusPageEmbedUrl;
    script.defer = true;
    document.body.appendChild(script);
}
