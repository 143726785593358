import type { SimpleTileConfig } from '@squaredup/dashboards';
import { SimpleTile } from 'dashboard-engine/basetiles/types';
import Image from '../visualisations/Image/Image';
import { ImageConfig } from '../visualisations/Image/Config';
import noOp from '../datasources/misc/noOp/noOp';

export type ImageTileConfig = SimpleTileConfig & {
    _type: 'tile/image';
    visualisation: {
        config: ImageConfig;
    };
};

const image: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: noOp
    },
    transforms: [],
    visualisation: {
        type: Image,
        config: {
            src: '',
            title: '',
            uploaded: 0,
            showHealthState: false
        } as ImageConfig
    }
};

export default image;
