import { Switch } from '@/components/Switch';
import type { UserTenantProperties } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { themeSwitchOptions } from 'lib/themeSwitchOptions';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { SetUserForTenantProperty } from 'services/UserService';
import { ThemeOptions } from 'ui/hooks/useTheme';

export const ThemeChooser: React.FC = () => {
    const [params] = useSearchParams();
    const queryClient = useQueryClient();
    const forcedTheme = ['dark', 'light'].includes(params.get('theme') || '');

    const [theme, setTheme] = useState<ThemeOptions>(
        () => queryClient.getQueryData<UserTenantProperties>(tenantQueryKeys.tenantProperties)?.theme as ThemeOptions
    );

    const { mutate: saveTheme } = useMutation((newTheme: ThemeOptions) => SetUserForTenantProperty('theme', newTheme), {
        async onMutate(newTheme) {
            // Cancel previous queries and set the theme in the cache
            // Note: I'm not returning the old theme for error handling as I don't want the UI to jump around.
            queryClient.cancelQueries(tenantQueryKeys.tenantProperties);
            queryClient.setQueryData<UserTenantProperties>(tenantQueryKeys.tenantProperties, (d) => ({
                ...d,
                theme: newTheme
            }));

            setTheme(newTheme);

            trackEvent('Theme Changed', { theme: newTheme });
        }
    });

    return (
        <Switch
            value={theme}
            options={themeSwitchOptions}
            disabled={forcedTheme}
            onValueChange={(selectedTheme) => {
                if (selectedTheme) {
                    saveTheme(selectedTheme as ThemeOptions);
                }
            }}
        />
    );
};
