import { Button } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { cn } from '@/lib/cn';
import {
    faClone,
    faCode,
    faEllipsisVertical,
    faFileExport,
    faImage,
    faShare,
    faTrash
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DashboardType } from '@squaredup/dashboards';
import { DashboardIdFolder } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import { useModal } from 'components/Modal';
import { Presence } from 'components/Presence';
import { useAppContext } from 'contexts/AppContext';
import { downloadCurrentDashboardAsImage } from 'dashboard-engine/util/dashboardImageSnapshots';
import { isOpenAccess } from 'lib/openAccessUtil';
import { DashboardCopyToModal } from 'pages/dashboard/DashboardCopyToModal';
import { DashboardDeleteModal } from 'pages/dashboard/DashboardDeleteModal';
import { ShareDashboardModal } from 'pages/dashboard/SharingExperience/ShareDashboardModal';
import { useUpdateWorkspaceDashboardOrder } from 'queries/hooks/useUpdateWorkspaceDashboardOrder';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { perspectiveQueryKeys } from 'queries/queryKeys/perspectiveKeys';
import { FC, ReactElement, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Clone } from 'services/DashboardService';
import ClipboardToast, { toastIds } from 'ui/notifications/ClipboardToast';
import { RefetchDropdownOption } from './RefetchDropdownOption';

interface DashboardOptionsProps {
    dashboard?: DashboardType;
    workspaceId?: string;
    editing?: boolean;
    isDashboardReadOnly?: boolean;
    isDashboardOpenAccess?: boolean;
    isNarrowLayout?: boolean;
    isTriggerRefetchEnabled?: boolean;
    disabled?: boolean;
    toggleJsonEditor?: () => void;
    setEditing?: (editing: boolean) => void;
}

interface DashboardOption {
    name: string;
    className?: string;
    showOnOpenAccess?: boolean;
    showOnReadOnly?: boolean;
    icon?: ReactElement<typeof FontAwesomeIcon>;
    onClick: () => void;
    show?: () => boolean;
}

export const DashboardOptions: FC<DashboardOptionsProps> = ({
    dashboard,
    workspaceId,
    editing = false,
    isDashboardReadOnly = false,
    isDashboardOpenAccess = false,
    disabled,
    isNarrowLayout,
    isTriggerRefetchEnabled,
    toggleJsonEditor = () => undefined,
    setEditing = () => undefined
}) => {
    const { currentWorkspaceID } = useAppContext();
    const { data: workspace } = useWorkspace(currentWorkspaceID);
    const dashboardWorkspaceId = workspaceId ?? currentWorkspaceID;

    const [copying, setCopying] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const shareModal = useModal();

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { mutateDirectAsync: updateWorkspaceOrder } = useUpdateWorkspaceDashboardOrder();

    const cloneDashboard = useMutation<string, unknown, string, undefined>(Clone, {
        onSuccess: async (cloneID, originalId) => {
            ClipboardToast('Dashboard cloned successfully', false, toastIds.SUCCESS);

            const findAndInsert = (data: (DashboardIdFolder | string)[]): (DashboardIdFolder | string)[] =>
                data.reduce(
                    (prev, curr) =>
                        typeof curr === 'string'
                            ? [...prev, curr, ...(curr === originalId ? [cloneID] : [])]
                            : [...prev, { ...curr, dashboardIdOrder: findAndInsert(curr.dashboardIdOrder) }],
                    [] as (DashboardIdFolder | string)[]
                );

            const newOrder = findAndInsert(workspace?.data.properties?.dashboardIdOrder || []);

            updateWorkspaceOrder(newOrder);

            queryClient.invalidateQueries(dashboardQueryKeys.list);
            queryClient.invalidateQueries(perspectiveQueryKeys.all);

            navigate(`/dashboard/${cloneID}?force_open_folder=true`);
        }
    });

    const handleJsonEditorToggle = () => {
        toggleJsonEditor();
        setEditing(true);
    };

    const onDownloadAsImage = async () => {
        setIsWorking(true);
        try {
            if (dashboard) {
                await downloadCurrentDashboardAsImage(dashboard);
            }
        } finally {
            setIsWorking(false);
        }
    };

    const dashboardOptionGroups: (DashboardOption | ReactElement)[][] = [
        [
            {
                name: 'Clone',
                showOnOpenAccess: false,
                showOnReadOnly: false,
                icon: <FontAwesomeIcon icon={faClone} fixedWidth />,
                onClick: () => {
                    setIsWorking(true);
                    cloneDashboard.mutate(dashboard!.id);
                }
            },
            {
                name: 'Copy to',
                showOnOpenAccess: false,
                showOnReadOnly: true,
                icon: <FontAwesomeIcon icon={faFileExport} fixedWidth />,
                onClick: () => setCopying(true)
            },
            {
                name: 'Edit JSON',
                showOnOpenAccess: false,
                showOnReadOnly: false,
                icon: <FontAwesomeIcon icon={faCode} fixedWidth />,
                onClick: handleJsonEditorToggle
            },
            {
                name: 'Export image',
                showOnOpenAccess: true,
                showOnReadOnly: true,
                icon: <FontAwesomeIcon icon={faImage} fixedWidth />,
                show: () => !editing,
                onClick: onDownloadAsImage
            },
            ...(!isOpenAccess() && isTriggerRefetchEnabled ? [<RefetchDropdownOption dashboard={dashboard} key='refetch' />] : [])
        ],
        [
            {
                name: 'Delete',
                className: 'text-statusErrorPrimary',
                showOnOpenAccess: false,
                showOnReadOnly: false,
                icon: <FontAwesomeIcon icon={faTrash} className='text-statusErrorPrimary' fixedWidth />,
                onClick: () => setDeleting(true)
            }
        ]
    ];

    const shareOption = {
        name: 'Share',
        showOnOpenAccess: true,
        showOnReadOnly: true,
        icon: <FontAwesomeIcon icon={faShare} fixedWidth />,
        onClick: shareModal.open
    };

    const optionGroups = isNarrowLayout
        ? [[...dashboardOptionGroups[0], shareOption], ...dashboardOptionGroups.slice(1)]
        : dashboardOptionGroups;

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger disabled={isWorking || disabled} data-testid='dashboardOptionsButton'>
                <Button
                    className='focus:bg-secondaryButtonBackground focus:ring-secondaryButtonOutline'
                    variant='secondary'
                    icon={
                        isWorking ? (
                            <LoadingSpinner size={18} />
                        ) : (
                            <FontAwesomeIcon icon={faEllipsisVertical} className='flex items-center text-xl' />
                        )
                    }
                />
            </DropdownMenu.Trigger>

            <DropdownMenu.Menu align='end' className='divide-y divide-dividerPrimary'>
                {optionGroups.map((optionGroup, index) => (
                    <DropdownMenu.Group key={index}>
                        {optionGroup
                            .filter(
                                (option) =>
                                    (!isDashboardReadOnly && !isDashboardOpenAccess && true) ||
                                    (isDashboardOpenAccess &&
                                        'showOnOpenAccess' in option &&
                                        option.showOnOpenAccess) ||
                                    (isDashboardReadOnly && 'showOnReadOnly' in option && option.showOnReadOnly)
                            )
                            .filter((option) => !('show' in option) || option.show?.() !== false)
                            .map((option) =>
                                'name' in option ? (
                                    <DropdownMenu.Item
                                        key={option.name}
                                        onSelect={option.onClick}
                                        className={cn(option.name === 'Delete' && 'text-textDestructive font-semibold')}
                                        icon={option.icon}
                                    >
                                        {option.name}
                                    </DropdownMenu.Item>
                                ) : (
                                    option
                                )
                            )}
                    </DropdownMenu.Group>
                ))}
            </DropdownMenu.Menu>

            {copying && (
                <DashboardCopyToModal
                    aria-label='Move dashboard'
                    dashboard={dashboard}
                    workspace={dashboardWorkspaceId || ''}
                    close={() => setCopying(false)}
                />
            )}

            {deleting && (
                <DashboardDeleteModal
                    aria-label='Delete dashboard'
                    dashboard={dashboard}
                    workspace={dashboardWorkspaceId || ''}
                    close={() => setDeleting(false)}
                />
            )}

            <Presence isPresent={shareModal.isOpen}>
                <ShareDashboardModal close={shareModal.close} />
            </Presence>

            {!editing && onDownloadAsImage && (
                <div id='dashboard-download' className='hidden' onClick={onDownloadAsImage} />
            )}
        </DropdownMenu>
    );
};
