import Text from '@/components/Text';
import { faRightFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { groupBy } from 'lodash';
import { useImportStatus } from 'pages/settings/plugins/useImportStatus';
import { useTenant } from 'queries/hooks/useTenant';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { lastNonSettingsPageKey } from 'routes/AppRoutes';
import { IsTenantAdmin } from 'services/AccessControlService';
import Auth from 'services/Auth';
import { ExpandableNavSection } from './ui/ExpandableNavSection';

const navGroups = {
    CORE: 'Core',
    ADVANCED: 'Advanced'
};

const hideRedDot = '/plugins';

export const settingsLinks = [
    {
        href: hideRedDot, //Hide red dot when on the plugins page.
        label: 'Data Sources',
        adminOnly: false,
        group: navGroups.CORE,
        index: 0
    },
    {
        href: '/organization',
        label: 'Organization',
        adminOnly: false,
        group: navGroups.CORE,
        index: 5
    },
    {
        href: '/kpis',
        label: 'KPI',
        adminOnly: false,
        group: navGroups.CORE,
        index: 6
    },
    {
        href: '/notifications',
        label: 'Notifications',
        adminOnly: false,
        group: navGroups.CORE,
        index: 3
    },
    {
        href: '/users',
        label: 'Users & Groups',
        adminOnly: true,
        group: navGroups.CORE,
        index: 1
    },
    {
        href: '/workspaces',
        label: 'Workspaces',
        adminOnly: false,
        group: navGroups.CORE,
        index: 4
    },
    {
        href: '/datastreams',
        label: 'Data Streams',
        adminOnly: false,
        group: navGroups.ADVANCED,
        index: 2
    },
    {
        href: '/types',
        label: 'Types',
        adminOnly: false,
        group: navGroups.ADVANCED,
        index: 5
    },
    {
        href: '/apiKeys',
        label: 'API',
        adminOnly: true,
        group: navGroups.ADVANCED,
        index: 0
    },
    {
        href: '/correlations',
        label: 'Correlations',
        adminOnly: true,
        group: navGroups.ADVANCED,
        index: 1
    },
    {
        href: '/props',
        label: 'Tags',
        adminOnly: true,
        group: navGroups.ADVANCED,
        index: 4
    },
    {
        href: '/scripts',
        label: 'Scripts',
        adminOnly: false,
        group: navGroups.ADVANCED,
        index: 3
    },
    {
        href: '/relay',
        label: 'Relay Agents',
        adminOnly: true,
        group: navGroups.CORE,
        index: 7
    },
    {
        href: '/openaccess',
        label: 'Sharing',
        adminOnly: false,
        group: navGroups.CORE,
        index: 2
    }
];

export const SettingsNavigation: React.FC = () => {
    const { data: tenant, isLoading: isTenantLoading } = useTenant();
    const { hasFailedImports } = useImportStatus();

    const isAdmin = IsTenantAdmin(tenant);
    const isSqUpAdmin = Auth.isSqupAdmin();

    const visibleSettingsLinks = useMemo(() => {
        const links = [...settingsLinks];

        if (isSqUpAdmin) {
            links.push({
                href: '/tenants',
                label: 'Tenants',
                adminOnly: true,
                group: 'Core',
                index: 8
            });
        }

        const ungroupedLinks = isAdmin ? links : links.filter((link) => !link.adminOnly);

        return groupBy(ungroupedLinks, 'group');
    }, [isAdmin, isSqUpAdmin]);

    if (isTenantLoading) {
        return (
            <div className='px-6'>
                <LoadingSpinner />
            </div>
        );
    }

    const lastNonSettingsPage = sessionStorage.getItem(lastNonSettingsPageKey);

    return (
        <div className='flex pb-4 flex-col flex-1 min-h-0 mt-2 text-textSecondary' data-testid='settingsNav'>
            <div className='flex items-center justify-between flex-shrink-0 px-4 mb-8'>
                <Text.H3 className='mr-4 text-textPrimary'>Settings</Text.H3>
                <NavLink
                    to={lastNonSettingsPage || '/status'}
                    className='inline-flex items-center text-base cursor-pointer text-textSecondary hover:text-textPrimary'
                >
                    <FontAwesomeIcon icon={faRightFromBracket} className='mr-2' />
                    Back to App
                </NavLink>
            </div>

            <div className='flex-1 min-h-0 space-y-6 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                {Object.entries(visibleSettingsLinks)
                    .filter(([group]) => group === navGroups.CORE)
                    .map(([group, links]) => (
                        <div key={group}>
                            {links
                                .sort((a, b) => a.index - b.index)
                                .map(({ href, label }) => {
                                    return (
                                        <NavLink
                                            key={href}
                                            to={`settings${href}`}
                                            className={({ isActive }) =>
                                                `flex items-center py-1 pr-6 pl-[44px] hover:text-textPrimary ${
                                                    isActive ? 'text-primaryButtonText font-semibold' : ''
                                                }`
                                            }
                                        >
                                            {label}
                                            {href === hideRedDot && hasFailedImports && (
                                                <StateIndicator
                                                    hideTooltip={true}
                                                    state={'Data Source import failed'}
                                                    className={'ml-[10px] text-statusErrorPrimary'}
                                                />
                                            )}
                                        </NavLink>
                                    );
                                })}
                        </div>
                    ))}

                {Object.entries(visibleSettingsLinks)
                    .filter(([group]) => group === navGroups.ADVANCED)
                    .map(([group, links]) => (
                        <ExpandableNavSection
                            key={`settingsNavGroup-${group}`}
                            title={group}
                            links={links.sort((a, b) => a.index - b.index)}
                            initiallyExpanded={false}
                        />
                    ))}
            </div>
        </div>
    );
};
