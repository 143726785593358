import { Button } from '@/components/Button';
import Text from '@/components/Text';
import { useLinkedPluginConfigs } from 'components/hooks/useLinkedPluginConfigs';
import { type ImportStatus } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { LoadingAnimation } from 'pages/settings/plugins/PluginLoadingModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type LoadStatus = 'loading' | 'success' | 'error' | 'warning';

type Message = {
    status: LoadStatus;
    heading: string;
    description?: string;
};

const messages: Message[] = [
    {
        status: 'loading',
        heading: 'Data source indexing in progress'
    },
    {
        status: 'success',
        heading: 'Data source setup complete'
    },
    {
        status: 'error',
        heading: 'Data source setup failed',
        description: 'We can’t display this dashboard right now, check the data source for more information.'
    }
];

/**
 * Convert status from importStatus to LoadStatus
 */
function getImportStatus(importStatus?: ImportStatus['status']): LoadStatus {
    switch (importStatus) {
        case 'running':
            return 'loading';
        case 'succeeded':
            return 'success';
        case 'failed':
            return 'error';
        default:
            return 'loading';
    }
}

/**
 * Gets the import status of the plugin associated with an OOB dashboard
 */
export const useOobDashboardImportStatus = ({
    configId,
    workspaceId
}: {
    configId?: string;
    workspaceId: string | null;
}) => {
    // if no configId, this is not an out-of-box dashboard.
    const [isImportConfirmed, setIsImportConfirmed] = useState<boolean>(!configId);
    const [hasPreviousData, setHasPreviousData] = useState<boolean>(!configId);

    const { data: linkedPlugins = [], isLoading } = useLinkedPluginConfigs(workspaceId, {
        enabled: Boolean(configId && workspaceId && !isImportConfirmed),
        // need initial data to stop initial fetch when enabled is false
        initialData: [],
        refetchInterval: 5_000
    });

    const linkedPlugin = linkedPlugins.find((plugin) => plugin.id === configId);
    const hasPreviousImport = Boolean(linkedPlugin?.importStatus?.lastSuccessful);
    const importStatus = getImportStatus(linkedPlugin?.importStatus?.status);
    const isImported = !configId || !linkedPlugin || importStatus === 'success' || hasPreviousImport;

    if (configId && !isLoading && !hasPreviousData) {
        // make sure we only run this on the first request/response from useQuery
        setHasPreviousData(true);

        /**
         * If plugin has already been imported when we first fetch data, we skip requiring a confirmation from user.
         * Skipping need to show import status modal.
         * Only do first time fetching data, otherwise success message won't show after plugin finishes importing.
         */
        if (isImported && !isImportConfirmed) {
            setIsImportConfirmed(true);
        } else if (linkedPlugin) {
            trackEvent('OOB Dashboard Import Status Modal Shown', {
                status: importStatus,
                plugin: linkedPlugin.plugin?.displayName!,
                configId
            });
        }
    }

    return {
        isLoading,
        isImported,
        isImportConfirmed,
        setIsImportConfirmed,
        plugin: linkedPlugin
            ? {
                  id: linkedPlugin.id,
                  name: linkedPlugin.plugin?.displayName!
              }
            : undefined,
        importStatus
    };
};

/**
 * Modal to show import status of OOB dashboard plugin
 */
export const OobDashboardImportStatusModal = ({
    importStatus,
    plugin,
    isImportConfirmed,
    setIsImportConfirmed
}: {
    plugin: {
        id: string;
        name: string;
    };
    importStatus: LoadStatus;
    isImportConfirmed: boolean;
    setIsImportConfirmed: (isImported: boolean) => void;
}) => {
    const navigate = useNavigate();
    const message = messages.find((m) => m.status === importStatus);

    if (!message || isImportConfirmed) {
        return <></>;
    }

    const isSuccess = message.status === 'success';

    return (
        <div className='absolute -inset-5 backdrop-blur-[8px] z-[15] min-h-[90vh]'>
            <div
                className='sticky w-full max-w-lg pt-5 overflow-auto text-center -translate-x-1/2 -translate-y-1/2 border-2 shadow-lg left-1/2 bg-backgroundSecondary px-9 pb-7 border-modalOutline scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
                style={{
                    top: 'max(50vh, 250px)'
                }}
            >
                <div className='overflow-hidden'>
                    <div className='flex -mx-16'>
                        <LoadingAnimation pluginName={plugin.name} status={message.status} />
                    </div>
                </div>
                <Text.H3 className='mt-4'>{message.heading}</Text.H3>
                {message.description && <Text.Body className='mt-4 text-balance'>{message.description}</Text.Body>}
                <Button
                    onClick={() => {
                        if (isSuccess) {
                            setIsImportConfirmed(true);
                            return;
                        }

                        navigate(`/datasource/${plugin.id}`);
                    }}
                    className='mt-6'
                    data-testid={
                        isSuccess
                            ? 'oob-dashboard-import-status-contine'
                            : 'oob-dashboard-import-status-view-datasource'
                    }
                >
                    {isSuccess ? 'Continue' : 'View data source'}
                </Button>
            </div>
        </div>
    );
};
