import { SimpleTile } from 'dashboard-engine/basetiles/types';
import graphNode from '../datasources/graph/graphNode/graphNode';

const tile: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: graphNode
    },
    transforms: [
        {
            type: 'wrap'
        },
        {
            type: 'map',
            config: {
                id: 'id',
                name: 'name.0',
                state: 'state'
            }
        }
    ],
    visualisation: {
        type: 'blocks',
        config: {
            full: true
        }
    }
};

export default tile;
