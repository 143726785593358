import { TooltipButton } from '@/components/Button';
import { Skeleton } from '@/components/Skeleton';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DashboardVariable } from '@squaredup/dashboards';
import { useDashboardContext } from 'contexts/DashboardContext';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DashboardVariableCreateEditModal } from './DashboardVariableCreateEditModal';
import { DashboardVariableDropdown } from './DashboardVariableDropdown';
import { useSetCurrentVariable, useVariableObjects } from './utils/variableDropdown';
interface DashboardVariablesControlProps {
    canEdit: boolean;
    disabled?: boolean;
    shouldLoadVariableObjects?: boolean;
}

interface SingleObjectSelectLayerProps {
    variable: DashboardVariable;
    shouldLoadVariableObjects?: boolean;
}

const SingleObjectSelectLayer: React.FC<SingleObjectSelectLayerProps> = ({
    variable,
    shouldLoadVariableObjects,
    children
}) => {
    const objects = useVariableObjects(variable.scopeId, '', shouldLoadVariableObjects);
    const handleSetVariable = useSetCurrentVariable(variable.id);
    const [validated, setValidated] = useState(false);

    // If there is only a single variable object, autoselect it
    useEffect(() => {
        if (
            !objects.isLoadingObjects &&
            !variable.selectedAll &&
            isEmpty(variable.selectedObjects) &&
            objects.count === 1
        ) {
            handleSetVariable({
                ...variable,
                selectedObjects: [objects.objects[0]]
            });
        }

        if (!objects.isLoadingObjects) {
            setValidated(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variable.id, objects.isLoadingObjects, objects.count, shouldLoadVariableObjects]);

    if (!validated) {
        return <Skeleton className='w-[145px] h-button' />;
    }

    return <>{children}</>;
};

export const DashboardVariablesControl: React.FC<DashboardVariablesControlProps> = ({
    canEdit,
    disabled,
    shouldLoadVariableObjects = true
}) => {
    const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
    const { editing, variables = [] } = useDashboardContext();

    const hasVariables = variables.length > 0;

    return (
        <div className='flex items-center text-base'>
            {(hasVariables || editing) && <div className='h-full py-3 mx-6 border-l border-dividerPrimary'></div>}

            {hasVariables ? (
                variables.map((variable) => (
                    <SingleObjectSelectLayer
                        key={variable.id}
                        variable={variable}
                        shouldLoadVariableObjects={shouldLoadVariableObjects}
                    >
                        <DashboardVariableDropdown variable={variable} canEdit={canEdit} disabled={disabled} />
                    </SingleObjectSelectLayer>
                ))
            ) : (
                <>
                    {editing && (
                        <TooltipButton
                            variant='secondary'
                            title='Add dashboard variable'
                            className='px-4 text-base'
                            icon={<FontAwesomeIcon icon={faBarsFilter} />}
                            onClick={() => setIsCreateEditModalOpen(true)}
                            data-testid='dashboardVariableAdd'
                        />
                    )}
                </>
            )}

            {isCreateEditModalOpen && (
                <DashboardVariableCreateEditModal onClose={() => setIsCreateEditModalOpen(false)} />
            )}
        </div>
    );
};
