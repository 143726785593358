import graphNodeToTableData from 'dashboard-engine/util/graphNodeToTableData';
import graphNode from '../datasources/graph/graphNode/graphNode';
import { SimpleTile } from 'dashboard-engine/basetiles/types';
import { TableConfig } from '../visualisations/Table/Config';
import Table from '../visualisations/Table/Table';

const tile: SimpleTile = {
    baseTile: 'simple-base-tile',
    datasource: {
        type: graphNode
    },
    transforms: [
        {
            type: 'custom',
            config: (data: any) => graphNodeToTableData(data)
        }
    ],
    visualisation: {
        type: Table,
        config: {
            columns: [
                {
                    Header: 'Property',
                    accessor: 'prop'
                },
                {
                    Header: 'Value',
                    accessor: 'value'
                }
            ]
        } as TableConfig
    }
};

export default tile;
