import { TooltipButton } from '@/components/Button';
import { Divider } from '@/components/Divider';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCircleXmark, faEnvelope, faInfoCircle, faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button';
import React, { ReactNode } from 'react';

interface TileErrorProps {
    heading: ReactNode;
    detail?: ReactNode;
    showDetail: boolean;
    /** Show error detail inline instead of in tooltip */
    showDetailInline?: boolean;
    reset?: () => void;
    supportCode?: string;
    /** Used to link to the documentation */
    articleAnchor?: string;
}

export const TileError: React.FC<TileErrorProps> = ({
    heading,
    detail,
    showDetail,
    showDetailInline,
    reset,
    articleAnchor,
    supportCode
}) => {
    return (
        <div className='flex flex-col items-center max-h-full py-2 overflow-auto text-textSecondary scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
            <div
                className={cn(
                    'relative flex flex-col items-center max-w-lg p-5 text-center border-2 rounded-md border-tileEditorComponent',
                    showDetailInline && 'max-w-3xl'
                )}
            >
                <div className='flex flex-wrap items-center justify-center gap-[8px] px-6 font-semibold text-center'>
                    <FontAwesomeIcon icon={faCircleXmark} className='text-base text-statusErrorSecondary' />
                    <Text.H5>{heading}</Text.H5>
                </div>

                {(showDetail || reset) && !showDetailInline && (
                    <>
                        <Divider className='mt-4' />
                        <div className='flex flex-wrap items-center justify-center px-6 mt-4 gap-x-9 gap-y-4'>
                            {reset && (
                                <Button
                                    onClick={reset}
                                    className='w-auto font-semibold text-textSecondary'
                                    variant='tertiary'
                                    icon={<FontAwesomeIcon icon={faRefresh} />}
                                >
                                    Try again
                                </Button>
                            )}

                            {showDetail && (
                                <TooltipButton
                                    variant='tertiary'
                                    icon={<FontAwesomeIcon icon={faInfoCircle} />}
                                    className='text-textSecondary'
                                    title={
                                        <div>
                                            <p>{detail}</p>
                                            <div className='flex flex-wrap mt-4 text-sm gap-x-6 gap-y-3 text-textLink'>
                                                <a
                                                    href={`https://squaredup.com/cloud/TroubleshootingTiles${
                                                        articleAnchor ? `#${articleAnchor}` : ''
                                                    }`}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    Learn more
                                                </a>
                                                <a
                                                    href={`https://docs.squaredup.com/submit-ticket${
                                                        supportCode ? `?supportCode=${supportCode}` : ''
                                                    }`}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    Contact support
                                                </a>
                                            </div>
                                        </div>
                                    }
                                >
                                    More info
                                </TooltipButton>
                            )}
                        </div>
                    </>
                )}
                {(showDetail || reset) && showDetailInline && (
                    <div>
                        <pre className='w-full p-4 mt-4 text-sm text-left whitespace-normal rounded-md bg-tileEditorComponent'>
                            {detail}
                        </pre>
                        <div className='px-16'>
                            <Divider className='mx-auto mt-4' />
                        </div>
                        <div className='flex flex-wrap justify-center mt-4 text-sm gap-x-6 gap-y-3 text-textSecondary'>
                            {reset && (
                                <Button
                                    onClick={reset}
                                    className='text-textSecondary'
                                    variant='tertiary'
                                    icon={<FontAwesomeIcon icon={faRefresh} />}
                                >
                                    Try again
                                </Button>
                            )}
                            <Button
                                href={`https://squaredup.com/cloud/TroubleshootingTiles${
                                    articleAnchor ? `#${articleAnchor}` : ''
                                }`}
                                rel='noreferrer'
                                className='text-textSecondary'
                                variant='tertiary'
                                icon={<FontAwesomeIcon icon={faInfoCircle} />}
                            >
                                Learn more
                            </Button>
                            <Button
                                href={`https://docs.squaredup.com/submit-ticket${
                                    supportCode ? `?supportCode=${supportCode}` : ''
                                }`}
                                rel='noreferrer'
                                variant='tertiary'
                                className='text-textSecondary'
                                icon={<FontAwesomeIcon icon={faEnvelope} />}
                            >
                                Contact support
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
