import clsx from 'clsx';
import { useTenant } from 'queries/hooks/useTenant';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { IsTenantAdmin } from 'services/AccessControlService';
import { PAID_TENANT_RENEWAL_PERIOD } from 'services/TenantService';

const millisecondsPerDay = 24 * 3600 * 1000;

export default function UpgradeControl() {
    const tenant = useTenant();
    const [daysLeft, setDaysLeft] = useState(0);
    const navigate = useNavigate();

    const isAdmin = IsTenantAdmin(tenant.data);

    useEffect(() => {
        const rawEndDate = tenant.data?.licenceData?.licenceExpiry;
        if (rawEndDate) {
            if (tenant.status === 'success' && tenant.data?.licenceData) {
                const endDate = new Date(rawEndDate).getTime();
                const now = Date.now();
                const dateDifference = (endDate - now) / millisecondsPerDay;
                setDaysLeft(Math.ceil(dateDifference));
            }
        }
    }, [tenant.data, tenant.status, navigate]);

    // Stops the bar from appearing and then disappearing when the licence loads
    // Also  if Paid tier with more than 7 days to go then show nothing
    if (tenant.status === 'loading' || daysLeft > PAID_TENANT_RENEWAL_PERIOD) {
        return <></>;
    }

    let upgradeText = '';

    // Free tier users never have an expiry date
    // So they always see an "Upgrade now" banner.
    if (!tenant.data?.licenceData?.licenceExpiry) {
        upgradeText = 'Current Plan: Free';
    } else if (daysLeft < 1) {
        // If less than 1 day left show expiry
        upgradeText = 'License has expired';
    } else {
        // Otherwise (full users with less than 7 days and legacy mode triallist) show days remaining/upgrade
        upgradeText = `${daysLeft} ${daysLeft === 1 ? 'day' : 'days'} left until renewal`;
    }

    return (
        <div
            className={clsx(
                'flex items-center justify-center text-sm h-16 border-dividerPrimary text-textSecondary border-t-2'
            )}
        >
            {isAdmin ? <NavLink to='/usage'>{upgradeText}</NavLink> : upgradeText}
        </div>
    );
}
