import { Scope } from 'pages/scope/ScopesPage';
import { UseQueryOptions } from 'react-query';
import { ListScopes } from 'services/WorkspaceService';
import { useScopes } from './useScopes';

type QueryReturnType = Awaited<ReturnType<typeof ListScopes>>;

/**
 * useQuery returning the saved scopes for a specific scope
 * @param scopeId The scopeId you'd like
 * @param workspaceId The workspaceId for the scope
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of saved scopes
 */
export const useScope = <T = QueryReturnType>(
    workspaceId: string | null | undefined,
    scopeId?: string,
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) =>
    useScopes(workspaceId, {
        enabled: Boolean(scopeId),
        select: (scopes) => scopes.find(({ id }: Scope) => id === scopeId),
        ...options
    });
