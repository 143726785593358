import { TooltipButton } from '@/components/Button';
import { cn } from '@/lib/cn';
import { faCircleNotch, faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { useForceRefetch } from 'dashboard-engine/hooks/useForceRefetch';

export const RefetchButton = () => {
    const { editing } = useDashboardContext();
    const { config } = useTileContext();

    const { forceRefetch, lastRefetched, isRefetching, isEnabled } = useForceRefetch(config);

    if (!isEnabled) {
        return null;
    }

    return (
        <TooltipButton
            variant='tertiary'
            icon={<FontAwesomeIcon icon={isRefetching ? faCircleNotch : faRefresh} spin={isRefetching} fixedWidth />}
            title={lastRefetched}
            className={cn(!editing && 'hidden group-hover/tile:inline-flex')}
            onClick={() => forceRefetch()}
        />
    );
};
