import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { getDataStreamType, type DataStreamType } from 'dashboard-engine/util/getDataStreamType';
import { useDataStreamDefinition } from 'queries/hooks/useDataStreamDefinition';

const getDataStreamTypeFromConfig = (tileConfig: DataStreamBaseTileConfig): DataStreamType => {
    return {
        supportsDatasets: tileConfig?.dataStream?.id === 'datastream-sql',
        isConfigurable: false,
        requiresScope: false,
        supportsScope: false
    };
};

/**
 * Determine the type of datasteam used in the given config
 * @param config Tile configuration
 * @returns Type of datastream, e.g. configurable, matches none etc.
 */
export const useDataStreamType = (config: DataStreamBaseTileConfig) => {
    const { data, isLoading } = useDataStreamDefinition(config?.dataStream?.id);

    const dataStreamType = data != null ? getDataStreamType(data) : getDataStreamTypeFromConfig(config);

    return { dataStreamType, isLoading };
};
