import _ from 'lodash';
import React from 'react';
import { TileError } from 'ui/tile/TileError';
import { warningMessages } from 'ui/tile/TileWarnings';

interface DataStreamErrorsProps {
    error: any;
    showDetailedErrors?: boolean;
    /** Show error detail inline instead of in tooltip */
    showDetailInline?: boolean;
}

export const DataStreamErrors: React.FC<DataStreamErrorsProps> = ({
    error,
    showDetailedErrors = true,
    showDetailInline
}) => {
    const errors = error?.response?.data?.errors || (error.message ? [error.message] : null) || [_.toString(error)];

    const reason = errors?.[0];
    const errorMessage = reason ? warningMessages.find((w) => reason.startsWith(w.message)) : null;

    return (
        <TileError
            heading={showDetailedErrors && errorMessage?.heading ? errorMessage?.heading : 'Something went wrong'}
            articleAnchor={errorMessage?.heading?.toLowerCase().replace(/ /gu, '-')}
            supportCode={error?.response?.headers?.['apigw-requestid']}
            detail={errorMessage?.message || reason}
            showDetail={showDetailedErrors}
            showDetailInline={showDetailInline}
        />
    );
};
