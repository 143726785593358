import { Button } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { cn } from '@/lib/cn';
import { faCircleNotch, faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { useDataStreamFromTileConfig } from 'dashboard-engine/hooks/useDataStreamFromTileConfig';
import { Clone } from './Clone';
import { CopyData } from './CopyData';
import { CopyTo } from './CopyTo';
import { DeleteTile } from './DeleteTile';
import { Edit } from './Edit';
import { Explore } from './Explore';
import { ExportImage } from './ExportImage';
import { ForceRefetch } from './ForceRefetch';
import { Fullscreen } from './Fullscreen';

export interface TileDropdownMenuActionsProps {
    isEditor: boolean;
    isMobile: boolean;
    isOA: boolean;
    isForceRefetchEnabled: boolean;
    onOpenFullscreen?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const TileDropdownMenuActions: React.FC<TileDropdownMenuActionsProps> = ({
    isEditor,
    isMobile,
    isOA,
    isForceRefetchEnabled,
    onOpenFullscreen
}) => {
    const { config } = useTileContext();
    const { editing: isEditing } = useDashboardContext();
    const { isExporting } = useTileContext();

    const { isConfigured: isDataStreamConfigured } = useDataStreamFromTileConfig(config as DataStreamBaseTileConfig);
    const isDataStream = config._type === TileTypes.dataStream;
    const isConfigured = !isDataStream || (isDataStream && isDataStreamConfigured);

    const dropdownMenuActions = getDropdownMenuActions(
        isEditor,
        isEditing,
        isMobile,
        isOA,
        isForceRefetchEnabled,
        isConfigured
    );

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger
                className={cn(!isEditing && 'hidden group-hover/tile:inline-flex data-[state=open]:!inline-flex')}
                disabled={isExporting}
            >
                <Button
                    variant='tertiary'
                    icon={
                        <FontAwesomeIcon
                            icon={isExporting ? faCircleNotch : faEllipsisV}
                            fixedWidth
                            {...(isExporting && { spin: true })}
                        />
                    }
                />
            </DropdownMenu.Trigger>

            <DropdownMenu.Menu menuId='tileMenu' className='divide-y divide-outlinePrimary'>
                {dropdownMenuActions.map((option, index) => (
                    <DropdownMenu.Group key={index}>
                        {option.map((NestedOption, optionIndex) => (
                            <NestedOption key={`${index}-${optionIndex}`} onOpenFullscreen={onOpenFullscreen} />
                        ))}
                    </DropdownMenu.Group>
                ))}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};

/**
 * Build a menu based on the provided flags
 * @param isEditor If the user has editor permissions
 * @param isMobile If the mobile layout is active
 * @param isOA If the session is currently in Open Access
 * @returns Array of menu components
 */
export const getDropdownMenuActions = (
    isEditor: boolean,
    isEditing: boolean,
    isMobile: boolean,
    isOA: boolean,
    isForceRefetchEnabled: boolean,
    isConfigured: boolean
) => {
    const fullscreen = insertIf(Fullscreen, isConfigured);
    const exportImage = insertIf(ExportImage, isConfigured);
    const forceRefetch = insertIf(ForceRefetch, isConfigured && isForceRefetchEnabled && !isOA);

    // Mobile / Open Access
    if (isMobile || isOA) {
        return [[...fullscreen, ...exportImage, CopyData, ...forceRefetch]];
    }

    // Editors
    if (isEditor) {
        return [
            [Edit, ...insertIf(Clone, isEditing)],
            [...fullscreen, Explore, CopyTo],
            [...exportImage, CopyData, ...forceRefetch],
            insertIf(DeleteTile, isEditing)
        ].filter((a) => a.length > 0);
    }

    // All other users
    return [
        [...fullscreen, Explore, CopyTo],
        [...exportImage, CopyData, ...forceRefetch]
    ];
};

/**
 * Helper for optionally adding an element to an array based on a condition
 * @param element Element to add
 * @param condition Boolean condition
 * @returns
 */
const insertIf = <T,>(element: T, condition: boolean) => (condition ? [element] : []);
