import { cn } from '@/lib/cn';
import { forwardRef, type HTMLProps } from 'react';

export type DataStreamListItemProps = HTMLProps<HTMLDivElement> & {
    isSelected: boolean;
};

export const DataStreamListItem = forwardRef<HTMLDivElement, DataStreamListItemProps>(
    ({ className, children, isSelected, ...rest }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    'h-[32px] mt-1 px-2 rounded-input select-none cursor-pointer',
                    {
                        'bg-tileEditorComponent': !isSelected,
                        'bg-tileEditorComponentActive text-textPrimary': isSelected
                    },
                    className
                )}
                {...rest}
                role='option'
                aria-selected={isSelected}
            >
                {children}
            </div>
        );
    }
);
