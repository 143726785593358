import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import { useAppContext } from 'contexts/AppContext';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { isOpenAccess } from 'lib/openAccessUtil';
import { useLocation } from 'react-router';
import { TileDropdownMenuActions } from 'ui/tile/actions/TileDropdownMenuActions';

interface TileMenuButtonProps {
    onOpenFullscreen?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const TileMenu: React.FC<TileMenuButtonProps> = ({ onOpenFullscreen }) => {
    const { pathname } = useLocation();

    const { config } = useTileContext();
    const { isNarrowLayout, currentWorkspaceID } = useAppContext();
    const isDashboard = pathname.startsWith('/dashboard/');

    const { canWrite } = useWorkspacePermissions();
    const canWriteToWorkspace = canWrite({ id: currentWorkspaceID || '' });
    const isEditor = isDashboard && canWriteToWorkspace;
    const isDataStreamTile = config._type === TileTypes.dataStream;

    const isOA = isOpenAccess();

    return (
        <TileDropdownMenuActions
            isEditor={isEditor ?? false}
            isMobile={isNarrowLayout ?? false}
            isOA={isOA}
            isForceRefetchEnabled={isDataStreamTile}
            onOpenFullscreen={onOpenFullscreen}
        />
    );
};
