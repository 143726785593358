import type { DashboardType } from '@squaredup/dashboards';
import { useAppContext } from 'contexts/AppContext';
import type { UserTenantProperties } from 'dynamo-wrapper';
import { useTenantProperties } from 'queries/hooks/useTenantProperties';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { type DashboardFolder, isDashboard } from 'queries/utils/dashboardSorted';
import { useCallback, useMemo } from 'react';
import { type UseQueryOptions, useMutation, useQueryClient } from 'react-query';
import { SetUserForTenantProperty } from 'services/UserService';

const SAVE_FOLDER_OPEN = 'SAVE_FOLDER_OPEN';

export const useFoldersOpen = (
    options?: Omit<
        UseQueryOptions<
            UserTenantProperties,
            unknown,
            UserTenantProperties['workspacesDashbaordFoldersOpenState'] | undefined,
            string[]
        >,
        'select'
    >
) => {
    const queryClient = useQueryClient();

    const folderIsSaving = queryClient.isMutating({ mutationKey: [SAVE_FOLDER_OPEN] });

    return useTenantProperties({
        enabled: !folderIsSaving,
        ...options,
        select: (data) => data?.workspacesDashbaordFoldersOpenState
    });
};

export const useIsFolderOpen = (item: DashboardFolder | DashboardType) => {
    const { data } = useFoldersOpen();
    const { currentWorkspaceID } = useAppContext();

    return useMemo(() => {
        if (isDashboard(item)) {
            return undefined;
        }
        return data?.[currentWorkspaceID!]?.[item.id];
    }, [currentWorkspaceID, data, item]);
};

export const useSetFolderOpen = () => {
    const queryClient = useQueryClient();

    const { currentWorkspaceID } = useAppContext();

    const { data: currentState = {} } = useFoldersOpen();

    const update = useMutation(
        (states: { itemId: string; open: boolean }[]) =>
            SetUserForTenantProperty('workspacesDashbaordFoldersOpenState', {
                ...currentState,
                [currentWorkspaceID!]: {
                    ...currentState[currentWorkspaceID!],
                    ...states.reduce(
                        (prev, { itemId, open }) => {
                            prev[itemId] = open;
                            return prev;
                        },
                        {} as Record<string, boolean>
                    )
                }
            }),
        {
            mutationKey: [SAVE_FOLDER_OPEN],
            async onMutate(states) {
                await queryClient.cancelQueries([tenantQueryKeys.tenantProperties]);

                const previousState = queryClient.getQueryData<UserTenantProperties>([
                    tenantQueryKeys.tenantProperties
                ]);

                queryClient.setQueryData<UserTenantProperties>(
                    tenantQueryKeys.tenantProperties,
                    (preMutationState) => ({
                        ...preMutationState,
                        workspacesDashbaordFoldersOpenState: {
                            ...preMutationState?.workspacesDashbaordFoldersOpenState,
                            [currentWorkspaceID!]: {
                                ...preMutationState?.workspacesDashbaordFoldersOpenState?.[currentWorkspaceID!],
                                ...states.reduce(
                                    (prev, { itemId, open }) => {
                                        prev[itemId] = open;
                                        return prev;
                                    },
                                    {} as Record<string, boolean>
                                )
                            }
                        }
                    })
                );

                return { previousState };
            },
            onError(_err, _config, context) {
                queryClient.setQueryData<UserTenantProperties | undefined>(
                    tenantQueryKeys.tenantProperties,
                    context?.previousState
                );
                queryClient.invalidateQueries(tenantQueryKeys.tenantProperties);
            }
        }
    );

    return useCallback(
        (states: { itemId: string; open: boolean }[]) => {
            const currState = queryClient.getQueryData<UserTenantProperties>([tenantQueryKeys.tenantProperties])
                ?.workspacesDashbaordFoldersOpenState;

            if (states.some(({ itemId, open }) => currState?.[currentWorkspaceID!]?.[itemId] !== open)) {
                update.mutate(states);
            }
        },
        [update, currentWorkspaceID, queryClient]
    );
};
