import { getSharingEnvironment } from '@squaredup/open-access';
import type { AcePermission } from 'dynamo-wrapper';
import { useTenant } from 'queries/hooks/useTenant';
import { useQuery } from 'react-query';
import {
    AccessControlQueryKeys,
    GetEntityPermissions,
    IsTenantAdmin,
    useWorkspaceCanWrite
} from 'services/AccessControlService';

interface WorkspaceId {
    id: string;
}

export const useWorkspaceDetailedPermissions = (workspace?: WorkspaceId) => {
    const isNewWorkspace = !workspace;
    const { data: canWriteToWorkspace } = useWorkspaceCanWrite(workspace?.id);
    const { data: entityPermissions } = useQuery<AcePermission[]>(
        AccessControlQueryKeys.CallerPermissionsOnEntity(workspace?.id),
        () => (workspace?.id ? GetEntityPermissions(workspace.id) : ([] as AcePermission[]))
    );

    const { data: tenant } = useTenant();

    const { isSharingEnabled: openAccessEnabledForTenant } = getSharingEnvironment(tenant);

    const isTenantAdmin = tenant && IsTenantAdmin(tenant);
    const canEditLinks = isNewWorkspace || isTenantAdmin || canWriteToWorkspace;
    const canConfigureAccessControl = isNewWorkspace || isTenantAdmin || entityPermissions?.includes('AD');
    const canConfigureOpenAccess = canConfigureAccessControl && openAccessEnabledForTenant;

    return {
        canEditLinks,
        canConfigureAccessControl,
        canConfigureOpenAccess
    };
};
