import { BarExtendedDatum, Value } from '@nivo/bar';
import { Theme } from '@nivo/core';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { estimateTickSize } from './BarChartCustomBottomAxis';
import {
    BarChartBars,
    barGraphCharacterWidth,
    barGraphLabelAlignTopMinHeight,
    barGraphLabelHorizontalOffset,
    barGraphLabelVerticalOffset
} from './Config';

type Formatter = (category: string | number | Date) => string;

interface BarChartTotalsProps {
    bars: BarChartBars[];
    hasSeriesColumn?: boolean;
    theme: Theme;
    layout: string;
    data: BarExtendedDatum[];
    labelSkipHeight?: number;
    labelSkipWidth?: number;
    labelRightMargin: number;
    setTotalsLabelRightMargin: Dispatch<SetStateAction<number>>;
    indexBy: string;
    format: Formatter;
}

/**
 * Get the total values for each bar group in the bar chart, along with the label positions
 */
function getBarTotals(bars: BarChartBars[], isHorizontal: boolean, format: Formatter) {
    const labelMargin = isHorizontal ? barGraphLabelHorizontalOffset : barGraphLabelVerticalOffset;
    let groups: {
        id: Value;
        totalValue: number;
        x: number;
        y: number;
        width: number;
        height: number;
    }[] = [];

    // build an array of groups with the total value of each group
    bars.forEach((bar) => {
        if (!bar.data.value) {
            return;
        }

        const group = groups.find((g) => g.id === bar.data.indexValue);

        if (group) {
            group.totalValue += bar.data.value;

            // in horizontal mode, need the width/x of the bar furthest to the right.
            if (isHorizontal && bar.x > group.x) {
                group.width = bar.width;
                group.x = bar.x;
            }

            // the total label should always placed at the top (lowest y of the group).
            group.y = Math.min(group.y, bar.y!);
        } else {
            groups.push({
                id: bar.data.indexValue,
                totalValue: bar.data.value,
                x: bar.x,
                y: bar.y!,
                width: bar.width,
                height: bar.height
            });
        }
    });

    let barTotals: {
        id: Value;
        label: string;
        y: number;
        x: number;
    }[] = [];

    groups.forEach(({ id, totalValue, height, width, x, y }) => {
        // Position the label at the end of the last bar in the group
        const labelX = isHorizontal ? x + width + labelMargin : x + width / 2;
        const labelY = isHorizontal ? (y ?? 0) + height / 2 : (y ?? 0) - labelMargin;

        const label = format(totalValue);

        // if the label is too long to fit in the bar, hide it
        const isHidden =
            label.length <= 0 ||
            (!isHorizontal && width < label.length * barGraphCharacterWidth) ||
            (isHorizontal && height < barGraphLabelAlignTopMinHeight);

        if (!isHidden) {
            barTotals.push({
                x: labelX,
                y: labelY,
                label,
                id
            });
        }
    });

    return barTotals;
}

const calculateMargin = (labels: string[]) => {
    // Get the longest label, and estimate it's pixel size
    const longestLabelSize = Math.max(...labels.map(estimateTickSize));

    // Add some additional space so it's not right against the label
    const margin = longestLabelSize + 10;

    // Apply a minimum margin in the case of small labels
    return Math.max(40, margin);
};

/**
 * Shows total values for each bar in a bar chart
 */
export function BarChartTotals(props: BarChartTotalsProps) {
    const { bars, theme, layout, format, labelRightMargin, setTotalsLabelRightMargin } = props;

    const isHorizontal = layout === 'horizontal';

    const totals = useMemo(() => getBarTotals(bars, isHorizontal, format), [bars, isHorizontal, format]);

    // Calculate dynamic margin based on these values
    const rightMargin = calculateMargin(totals.map((t) => t.label));

    // Ideally we wouldn't do this but this is the only place we have access to
    // the current state of the bars via Nivo; useEffect ensures render safety as a result
    useEffect(() => {
        if (labelRightMargin !== rightMargin) {
            setTotalsLabelRightMargin(rightMargin);
        }
    }, [rightMargin, labelRightMargin, setTotalsLabelRightMargin]);

    return (
        <g className='bar-totals'>
            {totals.map(({ label, x, y, id }) => {
                return (
                    <text
                        x={x}
                        y={y}
                        key={id}
                        textAnchor={isHorizontal ? 'start' : 'middle'}
                        alignmentBaseline='central'
                        style={{
                            ...theme.labels?.text,
                            pointerEvents: 'none',
                            fill: 'currentColor'
                        }}
                    >
                        {label}
                    </text>
                );
            })}
        </g>
    );
}
