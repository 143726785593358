import RenderDynamic from 'dashboard-engine/render/RenderDynamic';

function Column({ config, isLoading = false }) {
    return (
        <div className='flex mb-4 -mx-2'>
            {config.columns.map((column, columnRowIndex) => (
                <div className='h-full mx-2' style={{ width: `${column.width}%` }} key={`column-row-${columnRowIndex}`}>
                    {column.contents.map((content, columnIndex) => (
                        <div className='h-full' key={`column-${columnIndex}`}>
                            <RenderDynamic config={content} isLoading={isLoading} />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default Column;
